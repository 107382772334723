
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import { useEffect } from "react";


export const UserInfoForm = ({isRegister, preData, submitHandler}) => {
    //const [birthday, setBirthday] = useState("");

    const [newPwd, setNewPwd] = useState("");
    const location = useLocation();


    //async function handleSubmit(e) {
    //    e.preventDefault();
    //    // const { name, email, password, birthday,
    //}

    function ifStringEmptyThenNullify(string) {
        if (string === "") {
            return null;
        }
        return string;
    }

    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
                <h5 className="mb-4">{ isRegister ? ("帳號註冊 (新增使用者)") : ("個人資料變更") }</h5>
                <Form onSubmit={e => {
                    e.preventDefault();
                    let submittedData = {
                        password: ifStringEmptyThenNullify(e.target.newPwd.value),
                        lastName: ifStringEmptyThenNullify(e.target.lastName.value),
                        firstName: ifStringEmptyThenNullify(e.target.firstName.value),
                        username: ifStringEmptyThenNullify(e.target.username.value)
                    };
                    submitHandler(e, submittedData);
                }}>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="firstName">
                                <Form.Label>名字</Form.Label>
                                <Form.Control required={isRegister} name="firstName" type="text" placeholder={isRegister ? "例如: 捷儀" : preData?.firstName ?? ''}/>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group id="lastName">
                                <Form.Label>姓氏</Form.Label>
                                <Form.Control required={isRegister} name="lastName" type="text" placeholder={isRegister ? "例如: 黃" : preData?.lastName ?? ''} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        {/*<Col md={6} className="mb-3">*/}
                        {/*  <Form.Group id="birthday">*/}
                        {/*    <Form.Label>Birthday</Form.Label>*/}
                        {/*    <Datetime*/}
                        {/*      timeFormat={false}*/}
                        {/*      onChange={setBirthday}*/}
                        {/*      renderInput={(props, openCalendar) => (*/}
                        {/*        <InputGroup>*/}
                        {/*          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>*/}
                        {/*          <Form.Control*/}
                        {/*            required*/}
                        {/*            type="text"*/}
                        {/*            value={birthday ? moment(birthday).format("MM/DD/YYYY") : ""}*/}
                        {/*            placeholder="mm/dd/yyyy"*/}
                        {/*            onFocus={openCalendar}*/}
                        {/*            onChange={() => { }} />*/}
                        {/*        </InputGroup>*/}
                        {/*      )} />*/}
                        {/*  </Form.Group>*/}
                        {/*</Col>*/}
                        {/*<Col md={6} className="mb-3">*/}
                        {/*  <Form.Group id="gender">*/}
                        {/*    <Form.Label>Gender</Form.Label>*/}
                        {/*    <Form.Select defaultValue="0">*/}
                        {/*      <option value="0">Gender</option>*/}
                        {/*      <option value="1">Female</option>*/}
                        {/*      <option value="2">Male</option>*/}
                        {/*    </Form.Select>*/}
                        {/*  </Form.Group>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row>
                        <Col className="mb-3">
                            <Form.Group>
                                <Form.Label>帳號</Form.Label>
                                <Form.Control name="username" required={isRegister} type="email" placeholder={isRegister ? "account@gmail.com" : preData?.username ?? ''} />
                            </Form.Group>
                            <Form.Text muted>
                                帳號就是您的 Email。
                            </Form.Text>
                        </Col>
                        {/*<Col md={6} className="mb-3">*/}
                        {/*  <Form.Group id="phone">*/}
                        {/*    <Form.Label>Phone</Form.Label>*/}
                        {/*    <Form.Control required type="number" placeholder="+12-345 678 910" />*/}
                        {/*  </Form.Group>*/}
                        {/*</Col>*/}
                        
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-3" autoComplete="false">
                            <Form.Label>新密碼</Form.Label>
                            <Form.Control
                                required={isRegister}
                                onChange={e => setNewPwd(e.target.value)}
                                type="password" placeholder={!isRegister ? "若不變更請留空。" : ""}
                                name="newPwd"
                                />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" style={{ visibility: newPwd === '' ? 'hidden' : 'visible' }}>
                            <Form.Label>新密碼確認</Form.Label>
                            <Form.Control required={isRegister || (newPwd !== '')} type="password" placeholder="請再輸入一次新設密碼"
                                />
                        </Form.Group>
                        {!isRegister && (
                            <Form.Text muted style={{ visibility: newPwd === '' ? 'hidden' : 'visible' }}>
                                若不想變更密碼，請清除新密碼欄位。
                            </Form.Text>
                        )}
                    </Row>
                    {/*<Row>*/}
                    {/*    {isRegister && (*/}
                    {/*        <>*/}
                    {/*            註冊模式*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*    {!isRegister && (*/}
                    {/*        <>*/}
                    {/*            編輯模式*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</Row>*/}


                    {/*<h5 className="my-4">Address</h5>*/}
                    {/*<Row>*/}
                    {/*    <Col sm={9} className="mb-3">*/}
                    {/*        <Form.Group id="address">*/}
                    {/*            <Form.Label>Address</Form.Label>*/}
                    {/*            <Form.Control required type="text" placeholder="Enter your home address" />*/}
                    {/*        </Form.Group>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm={3} className="mb-3">*/}
                    {/*        <Form.Group id="addressNumber">*/}
                    {/*            <Form.Label>Number</Form.Label>*/}
                    {/*            <Form.Control required type="number" placeholder="No." />*/}
                    {/*        </Form.Group>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row>*/}
                    {/*    <Col sm={4} className="mb-3">*/}
                    {/*        <Form.Group id="city">*/}
                    {/*            <Form.Label>City</Form.Label>*/}
                    {/*            <Form.Control required type="text" placeholder="City" />*/}
                    {/*        </Form.Group>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm={4} className="mb-3">*/}
                    {/*        <Form.Group className="mb-2">*/}
                    {/*            <Form.Label>Select state</Form.Label>*/}
                    {/*            <Form.Select id="state" defaultValue="0">*/}
                    {/*                <option value="0">State</option>*/}
                    {/*                <option value="AL">Alabama</option>*/}
                    {/*                <option value="AK">Alaska</option>*/}
                    {/*                <option value="AZ">Arizona</option>*/}
                    {/*                <option value="AR">Arkansas</option>*/}
                    {/*                <option value="CA">California</option>*/}
                    {/*                <option value="CO">Colorado</option>*/}
                    {/*                <option value="CT">Connecticut</option>*/}
                    {/*                <option value="DE">Delaware</option>*/}
                    {/*                <option value="DC">District Of Columbia</option>*/}
                    {/*                <option value="FL">Florida</option>*/}
                    {/*                <option value="GA">Georgia</option>*/}
                    {/*                <option value="HI">Hawaii</option>*/}
                    {/*                <option value="ID">Idaho</option>*/}
                    {/*                <option value="IL">Illinois</option>*/}
                    {/*                <option value="IN">Indiana</option>*/}
                    {/*                <option value="IA">Iowa</option>*/}
                    {/*                <option value="KS">Kansas</option>*/}
                    {/*                <option value="KY">Kentucky</option>*/}
                    {/*                <option value="LA">Louisiana</option>*/}
                    {/*                <option value="ME">Maine</option>*/}
                    {/*                <option value="MD">Maryland</option>*/}
                    {/*                <option value="MA">Massachusetts</option>*/}
                    {/*                <option value="MI">Michigan</option>*/}
                    {/*                <option value="MN">Minnesota</option>*/}
                    {/*                <option value="MS">Mississippi</option>*/}
                    {/*                <option value="MO">Missouri</option>*/}
                    {/*                <option value="MT">Montana</option>*/}
                    {/*                <option value="NE">Nebraska</option>*/}
                    {/*                <option value="NV">Nevada</option>*/}
                    {/*                <option value="NH">New Hampshire</option>*/}
                    {/*                <option value="NJ">New Jersey</option>*/}
                    {/*                <option value="NM">New Mexico</option>*/}
                    {/*                <option value="NY">New York</option>*/}
                    {/*                <option value="NC">North Carolina</option>*/}
                    {/*                <option value="ND">North Dakota</option>*/}
                    {/*                <option value="OH">Ohio</option>*/}
                    {/*                <option value="OK">Oklahoma</option>*/}
                    {/*                <option value="OR">Oregon</option>*/}
                    {/*                <option value="PA">Pennsylvania</option>*/}
                    {/*                <option value="RI">Rhode Island</option>*/}
                    {/*                <option value="SC">South Carolina</option>*/}
                    {/*                <option value="SD">South Dakota</option>*/}
                    {/*                <option value="TN">Tennessee</option>*/}
                    {/*                <option value="TX">Texas</option>*/}
                    {/*                <option value="UT">Utah</option>*/}
                    {/*                <option value="VT">Vermont</option>*/}
                    {/*                <option value="VA">Virginia</option>*/}
                    {/*                <option value="WA">Washington</option>*/}
                    {/*                <option value="WV">West Virginia</option>*/}
                    {/*                <option value="WI">Wisconsin</option>*/}
                    {/*                <option value="WY">Wyoming</option>*/}
                    {/*            </Form.Select>*/}
                    {/*        </Form.Group>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm={4}>*/}
                    {/*        <Form.Group id="zip">*/}
                    {/*            <Form.Label>ZIP</Form.Label>*/}
                    {/*            <Form.Control required type="tel" placeholder="ZIP" />*/}
                    {/*        </Form.Group>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <div className="mt-3">
                        <Button variant="primary" type="submit">
                            {isRegister ? "註冊送出" : "更改送出"}
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};
