
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
//import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup, Image, Carousel } from '@themesberg/react-bootstrap';

//import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
//import { PageVisitsTable } from "../../components/Tables";
//import { trafficShares, totalOrders } from "../../data/charts";

import geoNow from '../../utils/geonow';
import { useSelector } from 'react-redux';
import { subscribe } from 'redux-subscriber';
import { GoogleMap, InfoWindow, LoadScript, Marker, KmlLayer } from '@react-google-maps/api';
import moment from "moment-timezone";
import 'moment/locale/zh-tw';
import WhiteRiverPicture from '../../assets/img/whiteriverReservoirPanoramio.jpg';
import MarkerIconGreen from '../../assets/img/gmap-markers/marker_green.png';
import MarkerIconYellow from '../../assets/img/gmap-markers/marker_yellow.png';
import MarkerIconRed from '../../assets/img/gmap-markers/marker_red.png';

import Swal from 'sweetalert2';
import queryString from 'query-string';

export default () => {

    const rdx_project = useSelector(state => state.project);
    let rdx_user = useSelector(state => state.user);
    let token = rdx_user.token;

    const [query, setQuery] = useState(null);
    let qs = queryString.parse(window.location.search);
    let qoProjectId = qs && qs.iniProjectId;
    let qoToken = qs && qs.token;
    let qoHideDashoTitle = qs && qs.hideDashoTitle;
    let qoHideDashoDesc = qs && qs.hideDashoDesc;
    let qoHideDashoImg = qs && qs.hideDashoImg;
    let qoPrependStatusGreenText = qs && qs.prependStatusGreenText;
    let qoPrependStatusYellowText = qs && qs.prependStatusYellowText;
    let qoPrependStatusRedText = qs && qs.prependStatusRedText;
    let qoOnlyShowPrependStatusText = qs && qs.onlyShowPrependStatusText;
    let qoDashoGmapZoom = qs && qs.dashoGMapZoom;
    let qoDashoGmapCenter = qs && qs.dashoGMapCenter;
    
    useEffect(() => {
        let qs = queryString.parse(window.location.search);
        let qoProjectId = qs && qs.iniProjectId;
        let qoToken = qs && qs.token;
        let qoHideDashoTitle = qs && qs.hideDashoTitle;
        let qoHideDashoDesc = qs && qs.hideDashoDesc;
        let qoHideDashoImg = qs && qs.hideDashoImg;
        let qoPrependStatusGreenText = qs && qs.prependStatusGreenText;
        let qoPrependStatusYellowText = qs && qs.prependStatusYellowText;
        let qoPrependStatusRedText = qs && qs.prependStatusRedText;
        let qoOnlyShowPrependStatusText = qs && qs.onlyShowPrependStatusText;
        let qoDashoGmapZoom = qs && qs.dashoGMapZoom;
        let qoDashoGmapCenter = qs && qs.dashoGMapCenter;
        let oQs = {
            projectId: qoProjectId,
            token: qoToken,
            hideDashoTitle: qoHideDashoTitle,
            hideDashoDesc: qoHideDashoDesc,
            hideDashoImg: qoHideDashoImg,
            prependStatusGreenText: qoPrependStatusGreenText,
            prependStatusYellowText: qoPrependStatusYellowText,
            prependStatusRedText: qoPrependStatusRedText,
            onlyShowPrependStatusText: qoOnlyShowPrependStatusText,
            dashoGMapZoom: qoDashoGmapZoom,
            dashoGMapCenter: qoDashoGmapCenter
        };
        
        setQuery(oQs);
    }, []);

    const [selectedProject, setSelectedProject] = React.useState(
        geoNow.stateHelper.project.getJoinedProjectItemByProjectId(rdx_project.currentProjectId, rdx_project.joinedProjects)
    );

    if (qoProjectId) {
        rdx_project.currentProjectId = qoProjectId;
        //let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(qoProjectId, rdx_project.joinedProjects);
        //setSelectedProject(proj);

    }
    if (qoToken) {
        token = qoToken;
    }
    
    const [descriptionImageUrl, setDescriptionImageUrl] = React.useState(null);

    const [statusShowingIdOfProjects, setStatusShowingIdOfProjects] = React.useState([]);

    const unsubscribe_currentProjectId = subscribe('project.currentProjectId', state => {
        
        let id = state.project.currentProjectId;
        let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(id, state.project.joinedProjects);
        setSelectedProject(proj);
    });

    const [loadingProp, setLoadingProp] = useState({
        isLoading: false,
        closeModal: false
    });
    

    React.useEffect(() => {
        if (loadingProp.isLoading) {
            Swal.fire({
                title: "讀取中...",
                showConfirmButton: true,
                allowOutsideClick: true,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
        } else if (loadingProp.closeModal) {
            Swal.close();
            loadingProp.closeModal = false;
        }
    }, [loadingProp]);

    const [hcItems, setHcItems] = React.useState([]);
    const [insExCoordItems, setInsExCoordItems] = React.useState([]);
    const [dupCoordItems, setDupCoordItems] = React.useState([]);

    const [statusMeaTime, setStatusMeaTime] = React.useState(null);
    //const ref_gmap = React.useRef(null);
    const [gmap, setGmap] = useState(null);

    const [projectKmls, setProjectKmls] = React.useState(null);

    const history = useHistory();

    const [activeMarker, setActiveMarker] = useState(null);
    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const handleOnLoad = (map) => {
        setGmap(map);
    };

    //React.useEffect(() => {
    //    // console.log(selectedProject);
    //}, []);

    React.useEffect(() => {
        //const bounds = new google.maps.LatLngBounds();
        //insExCoordItems.forEach(({ position }) => bounds.extend(position));
        //map.fitBounds(bounds);
        if (gmap) {
            // Use fitBounds if query has no dashoGMapZoom and dashoGMapCenter
            if (query && query.dashoGMapCenter && query.dashoGMapZoom) {
                if (query && query.dashoGMapZoom) {
                    let zoom = parseInt(query.dashoGMapZoom);
                    console.log("DashoGmapZoom=" + zoom);
                    // if not a number, set to default
                    if (zoom !== NaN) {
                        if (zoom < 0) {
                            zoom = 0;
                        } else if (zoom > 24) {
                            zoom = 24;
                        }
                        gmap.setZoom(zoom);
                    }
                }
                // set center if query string has value

                if (query && query.dashoGMapCenter) {
                    let center = query.dashoGMapCenter.split(",");
                    if (center.length === 2) {
                        let lat = parseFloat(center[0]);
                        let lng = parseFloat(center[1]);
                        console.log("DashoGmapCenter=" + lat + "," + lng);
                        if (lat !== NaN && lng !== NaN) {
                            gmap.setCenter({ lat, lng });
                        }
                    }
                }
            } else {
                if (query && (query.dashoGMapCenter || query.dashoGMapZoom)) {
                    console.log("[GMap] Need to have both of \"dashoGMapCenter\" and \"dashoGMapZoom\" provided in order to work. ");
                }
                const bounds = new window.google.maps.LatLngBounds();
                insExCoordItems.map(insExCoordItem => {

                    bounds.extend({
                        lat: insExCoordItem.position.lat,
                        lng: insExCoordItem.position.lng,
                    });
                });
                gmap.fitBounds(bounds);
            }
        }
    }, [gmap, insExCoordItems, projectKmls, query]);

    React.useEffect(() => {
        
        if (!selectedProject) {
            console.log("===============================");
            console.log("[Error] DashboardOverview.js - useEffect - project / user");
            console.log("selectedProject is null");
            console.log("here is some info...");
            console.log("rdx_user:")
            console.log(rdx_user);
            console.log("rdx_project:")
            console.log(rdx_project);
            console.log("token:")
            console.log(token);
            console.log("===============================");
            return;
        }

        setLoadingProp({
            isLoading: true,
            closeModal: false
        });
        /**@type Promise[] */
        let promises = [];
        //const promiseHcItems = geoNow.api.homeCarousels.getListByProjectId(token, selectedProject.projectId, err => {
        //    alert("getHomeCarousels" + JSON.stringify(err));
        //});
        //promises.push(promiseHcItems);

        const promiseInsExItems = geoNow.api.instrumentExtends.listByProjectId(token, selectedProject.projectId, null, err => {
            // alert("InssExItems: listByProjectId" + JSON.stringify(err));
            console.log("===============================");
            console.log("[Error] DashboardOverview.js");
            console.log("InssExItems: listByProjectId");
            console.error(err);
            console.log("===============================");
        });
        promises.push(promiseInsExItems);


        const promiseStatusData = geoNow.api.siteData.getLatestDataWithAlert(token, selectedProject.projectId);
        promises.push(promiseStatusData);

        const promiseProjectKmls = geoNow.api.projectKmls.listByProjectId(token, selectedProject.projectId, err => {
            // alert("projectKml: listByProjectId" + JSON.stringify(err));
            console.log("===============================");
            console.log("[Error] DashboardOverview.js");
            console.log("projectKml: listByProjectId");
            console.error(err);
            console.log("===============================");
        });
        promises.push(promiseProjectKmls);

        const promiseProjectDescriptionImageUrl = geoNow.api.project.getDescriptionImageUrl(token, selectedProject.projectId, err => {
            // alert("projectDescriptionImage" + JSON.stringify(err));
            console.log("===============================");
            console.log("[Error] DashboardOverview.js");
            console.log("projectDescriptionImage");
            console.error(err);
            console.log("===============================");
        });
        promises.push(promiseProjectDescriptionImageUrl);

        Promise.all(promises).then(([/*resHcItems, */resInsExItems, resStatusData, resProjectKmls, resProjectDescriptionImageUrl]) => {
            //debugger;
            //if (resHcItems.status === 1) {
            //    let hcItems = resHcItems.message;
            //    setHcItems(hcItems);
            //}
            if (resInsExItems.status === 1) {
                let items = resInsExItems.message;

                //setInsExItems(items);
                //submitPayload.insExItems = items;
            }
            if (resStatusData !== null) {
                setStatusMeaTime(resStatusData.meaTime);
            }

            if (resStatusData && resStatusData.entries && resStatusData.entries.length > 0 && resInsExItems && resInsExItems.status === 1) {
                /* @type {Array<{pointIdx: number, pointNo: string, position: {lng: number, lat: number}, value: number, exceedWarning: number, note: string}>}
                */
                let coordInsExItems = resInsExItems.message.filter(item => item.longitude !== 0 && item.latitude !== 0);

                // Backend ExceedWarning (Num): 0: None (Normal / Green), 1: Alert, 2: Alarm1, 3: Alarm2, 4: Action
                // 0.5: Yellow (Define in this JSX)

                //let fSD = resStatusData.entries.filter(item => coordInsExItems.some(ieItem => ieItem.pointIdx === item.pointIdx));
                let fSD_withCoord = [];
                let hashmapUniqueCoordItems = {};
                for (let i = 0; i < coordInsExItems.length; i++) {
                    let iItem = coordInsExItems[i];
                    let coordItem = resStatusData.entries.find(ieItem => ieItem.pointIdx === iItem.pointIdx);
                    if (coordItem !== null) {
                        coordItem.position = {};
                        coordItem.position.lng = iItem.longtitude;
                        coordItem.position.lat = iItem.latitude;

                        let warn_lv = standardizeWarnScore(iItem.value, iItem.exceedWarning);
                        coordItem.jsxMasterExceedWarning = warn_lv;
                    }

                    let coordKey = `${iItem.longtitude},${iItem.latitude}`;
                    if (!hashmapUniqueCoordItems[coordKey]) {
                        hashmapUniqueCoordItems[coordKey] = {
                            firstFlag: true,
                            children: []
                        };
                        //Only push unique point. 首次出現，當作父親。
                        fSD_withCoord.push(coordItem);
                    } else if (hashmapUniqueCoordItems[coordKey].firstFlag === true) {
                        // 將坐落於同一經緯度的東西，歸納到同一點上，記錄起來。
                        hashmapUniqueCoordItems[coordKey].children.push(coordItem);
                    }


                    //if (coordInsExItems.some(ieItem => ieItem.pointIdx === iItem.pointIdx)) {
                    //    iItem.longitude = 
                    //    fSD_withCoord.push(iItem);
                    //}
                }
                // 如果任何一個有異常，則該點就有異常。那就要覆蓋 fSD_withCoord 其中該父親項目的狀態。
                for (let i = 0; i < fSD_withCoord.length; i++) {
                    let fSD_item = fSD_withCoord[i];
                    let coordKey = `${fSD_item.position.lng},${fSD_item.position.lat}`;
                    if (hashmapUniqueCoordItems[coordKey].children.length > 0) {
                        let warn_lv = standardizeWarnScore(fSD_item.value, fSD_item.exceedWarning);
                        for (let j = 0; j < hashmapUniqueCoordItems[coordKey].children.length; j++) {
                            let child = hashmapUniqueCoordItems[coordKey].children[j];
                            let child_warn_lv = standardizeWarnScore(child.value, child.exceedWarning);
                            if (child_warn_lv > warn_lv) {
                                warn_lv = child_warn_lv;
                            }
                        }
                        fSD_item.jsxMasterExceedWarning = warn_lv;
                    }
                }

                setInsExCoordItems(fSD_withCoord);
                setDupCoordItems(hashmapUniqueCoordItems);
            }

            if (resProjectKmls !== null && resProjectKmls.status === 1) {
                setProjectKmls(resProjectKmls.message);
            }

            setDescriptionImageUrl(resProjectDescriptionImageUrl);

            setLoadingProp({
                isLoading: false,
                closeModal: true
            });
        });
        console.log(selectedProject);
    }, [selectedProject, rdx_user, token]);

    //React.useEffect(() => {
    //    //alert(JSON.stringify(hcItems));
    //}, [hcItems]);

    function renderStatusMarkerInfoWindowContent(pointIdx, pointNo, position, value, exceedWarning, note, isFirstElement = false) {
        const divClassName = isFirstElement ? "pt-1" : "border-top border-info mt-1 pt-2";
        return (

            /*<div className="border-bottom border-info mb-1">*/
            <div className={divClassName} style={{ overflowX: 'hidden' }}>
                <Row>
                    <Col><h5><span>{pointNo}</span></h5></Col>
                    <Col xs="auto"><Button variant="info" size="sm" onClick={e => {
                        let tempDtRight = moment(statusMeaTime.meaTime).add(30, "minutes");
                        let tempDtLeft = moment(statusMeaTime.meaTime).add(-1, "days");
                        history.push({
                            pathname: Routes.TimeHistory.path,
                            state: {
                                dtLeft: tempDtLeft,
                                dtRight: tempDtRight,
                                pointIndices: Array.of(pointIdx)
                            }
                        });
                    }}><FontAwesomeIcon icon={faChartLine} /></Button></Col>
                </Row>
                <Row className="pb-1">
                    <Col>{value === null ? '沒有訊號' : `物理量: ${value}`}<span className="text-danger">{!note || note === '' || note === null ? "" : `：${note}`}</span></Col>
                </Row>
            </div>
        );
    }

    // 僅用於 JS 簡易判斷紅黃綠燈，改以單一數字表示，以此便能利用簡單比大小找最嚴重燈號。0.5代表黃燈。
    function standardizeWarnScore(value, exceedLv) {
        let isValueNull = value === null || value === -99999;
        if (isValueNull) {
            return 0.5;
        }
        return exceedLv;
    }

    function createLabelSvgUrl(text) {
        const defaultTextSize = 12;
        const textSize = parseInt(selectedProject.projectMapPointTextSize) ?? defaultTextSize;
        const textColor = selectedProject.projectMapPointTextColor ?? "black";
        const textStrokeColor = selectedProject.projectMapPointTextBorderColor ?? "white";
        const textLength = text.length * textSize;
        return `data:image/svg+xml;utf-8,` +
            `<svg xmlns="http://www.w3.org/2000/svg" width="${textLength + 4}" height="${20 * textSize / defaultTextSize}">` +
            `<text x="0" y="${11 * textSize / defaultTextSize}" font-size="${textSize}" stroke="${textStrokeColor}" stroke-width="3">${text}</text>` +
            `<text x="0" y="${11 * textSize / defaultTextSize}" font-size="${textSize}" fill="${textColor}">${text}</text>` +
            `</svg>`;
    }

    function simplifyLabels(labels) {
        const grouped = {};
        const ungrouped = [];

        labels.forEach(label => {
            const mainPart = label.match(/^[^-]+/)[0];
            if (!grouped[mainPart]) {
                grouped[mainPart] = [];
            }
            grouped[mainPart].push(label);
        });

        const simplified = [];
        for (const [mainPart, fullLabels] of Object.entries(grouped)) {
            const suffixes = fullLabels.map(label => label.slice(mainPart.length));
            if (suffixes.every(suffix => ["-X", "-Y", "-Z"].includes(suffix.toUpperCase()))) {
                simplified.push(mainPart);
            } else {
                ungrouped.push(...fullLabels);
            }
        }

        return { simplified, ungrouped };
    }

    function getMarkerLabelText(pointIdx, pointNo, position, value, exceedWarning, note, jsxMasterExceedWarning, dupCoordItems) {
        let lbl = getDupText(pointNo, position, dupCoordItems);
        let prependText = "";
        if (jsxMasterExceedWarning === 0.5) {
            if (qoPrependStatusYellowText) {
                prependText = qoPrependStatusYellowText;
            }
        } else if (jsxMasterExceedWarning >= 1) {
            if (qoPrependStatusRedText) {
                prependText = qoPrependStatusRedText;
            }
        } else {
            if (qoPrependStatusGreenText) {
                prependText = qoPrependStatusGreenText;
            }
        }

        if (qoOnlyShowPrependStatusText) {
            // 只顯示狀態文字
            lbl = prependText;
        } else {
            // 顯示狀態文字 + Marker 文字
            lbl = `${prependText} ${lbl}`;
        }

        return lbl;
    }

    function getDupText(pointNo, position, dupCoordItems) {
        const key = `${position.lng},${position.lat}`;
        if (dupCoordItems[key]) {
            const childPoints = dupCoordItems[key].children.map(d => d.pointNo);
            const { simplified, ungrouped } = simplifyLabels([pointNo, ...childPoints]);

            let result = simplified.join(", ");
            if (ungrouped.length > 0) {
                if (childPoints.length > 3) {
                    result += `, ${ungrouped.slice(0, 2).join(", ")},...${ungrouped.length}`;
                } else {
                    result += `, ${ungrouped.join(", ")}`;
                }
            }

            // trim ", " from the beginning
            if (result.startsWith(", ")) {
                result = result.slice(2);
            }

            return result;
        }
        return pointNo;
    }



    if (!selectedProject) {
        return (
            <div>
                <h1>未指定專案</h1>
            </div>
        );
    }
    return (
        <>
            {(!qoHideDashoTitle || !qoHideDashoDesc) &&
                <Row>
                    <Col>
                        {!qoHideDashoTitle &&
                            <h1>{selectedProject.projectName}</h1>
                        }
                        {
                            !qoHideDashoDesc &&
                            <h4>{selectedProject.projectDescription}</h4>
                        }
                    </Col>
                </Row>
            }
            { !qoHideDashoImg && selectedProject.projectDescriptionImage && selectedProject.projectDescriptionImage !== "" &&
                <Row>
                    <Col>
                        <img src={descriptionImageUrl} />
                    </Col>
                </Row>
            }
            {false &&
                <Row>
                    <Carousel>
                        {
                            hcItems.map((item, index) => {

                                if (item.disabled) {
                                    return ("");
                                }

                                if (item.mediaType === 0) {
                                    return (
                                        <Carousel.Item key={index}>
                                            <img
                                                className="d-block w-100"
                                                src={item.imageUrl}
                                                alt={item.imageAlt}
                                            />
                                            <Carousel.Caption>
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    );
                                } else if (item.mediaType === 1) {
                                    // video
                                    return (
                                        <Carousel.Item key={index}>
                                            <video
                                                className="d-block w-100"
                                                src={item.videoUrl}
                                                controls
                                            />
                                            <Carousel.Caption>
                                                <h3>{item.title}</h3>
                                                <p>{item.description}</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    );
                                }
                            })
                        }
                        <Carousel.Item interval={1000}>
                            <img
                                className="d-block w-100"
                                src="holder.js/800x400?text=First slide&bg=373940"
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={500}>
                            <img
                                className="d-block w-100"
                                src="holder.js/800x400?text=Second slide&bg=282c34"
                                alt="Second slide"
                            />
                            <Carousel.Caption>
                                <h3>Second slide label</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="holder.js/800x400?text=Third slide&bg=20232a"
                                alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h3>Third slide label</h3>
                                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Row>
            }
            {false && <Row>
                <Col>
                    <Image src={WhiteRiverPicture} fluid />
                </Col>
            </Row>
            }
            <Row>
                <Col data-kmls-count={projectKmls === null ? 0 : projectKmls.length}>

                    <LoadScript googleMapsApiKey="AIzaSyApF0vxBJzGfApnvdR0V9NA6QfVFGV2FLY">
                        {insExCoordItems !== null && insExCoordItems.length > 0 &&
                            <GoogleMap
                                zoom={1}
                                onLoad={handleOnLoad}
                                onClick={() => setActiveMarker(null)}
                                mapContainerStyle={{ width: "100%"/*"100vw"*/, height: "95vh" }}

                            >
                                {insExCoordItems.map(({ pointIdx, pointNo, position, value, exceedWarning, note, jsxMasterExceedWarning }) => (
                                    <React.Fragment key={pointIdx}>
                                        <Marker
                                            key={pointIdx}
                                            position={position}
                                            label={{
                                                text: " ",
                                                className: 'markerLabelPointNo'
                                            }}
                                            onClick={() => handleActiveMarker(pointIdx)}
                                            icon={{
                                                url: (() => {
                                                    ////註解掉因為重構至 Promise 取得資料後，避免將邏輯寫在這裡 (避免重複計算)
                                                    //let warn_lv = standardizeWarnScore(value, exceedWarning);
                                                    //let dupParent = dupCoordItems[`${position.lng},${position.lat}`];
                                                    //if (dupCoordItems && dupParent && dupParent.children && dupParent.children.length > 0) {
                                                    //    //作為群集，必須要所有都正常才綠燈，任何一個有異常，都要以最嚴格狀態看待。
                                                    //    for (let i = 0; i < dupParent.children.length; i++) {
                                                    //        let child_warn_lv = standardizeWarnScore(dupParent.children[i].value, dupParent.children[i].exceedWarning);
                                                    //        if (child_warn_lv > warn_lv) {
                                                    //            warn_lv = child_warn_lv;
                                                    //        }
                                                    //    }
                                                    //}

                                                    let warn_lv = jsxMasterExceedWarning; // 0: None (Normal / Green), 0.5: Alert, 1: Alarm1, 1.5: Alarm2, 2: Action

                                                    if (warn_lv === 0.5) {
                                                        return MarkerIconYellow;
                                                    }
                                                    else if (warn_lv >= 1) {
                                                        return MarkerIconRed;
                                                    } else {
                                                        return MarkerIconGreen;
                                                    }
                                                })(),
                                                labelOrigin: { x: 0, y: 20 },
                                                anchor: { x: selectedProject.projectMapPointTextOffsetX ?? 8, y: selectedProject.projectMapPointTextOffsetY ?? 8 }
                                            }}
                                        >
                                            {activeMarker === pointIdx ? (
                                                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                                    <>
                                                        {
                                                            (renderStatusMarkerInfoWindowContent(pointIdx, pointNo, position, value, exceedWarning, note, true))
                                                        }
                                                        {
                                                            (!dupCoordItems[`${position.lng},${position.lat}`] ? '' :
                                                                dupCoordItems[`${position.lng},${position.lat}`].children.map(x => renderStatusMarkerInfoWindowContent(x.pointIdx, x.pointNo, x.position, x.value, x.exceedWarning)))

                                                        }
                                                    </>
                                                </InfoWindow>
                                            ) : null}
                                        </Marker>
                                        <Marker
                                            onClick={() => handleActiveMarker(pointIdx)}
                                            position={position}
                                            icon={{
                                                /*url: createLabelSvgUrl(getDupText(pointNo, position, dupCoordItems)),*/
                                                url: createLabelSvgUrl(getMarkerLabelText(pointIdx, pointNo, position, value, exceedWarning, note, jsxMasterExceedWarning, dupCoordItems)),
                                                anchor: { x: 0, y: 0 },
                                                labelOrigin: { x: 0, y: 0 }
                                            }}
                                        />
                                    </React.Fragment>
                                ))}
                                {projectKmls === null ? ("") : projectKmls.map(({ filename }) => (
                                    <KmlLayer url={filename} />
                                ))}
                            </GoogleMap>
                        }
                    </LoadScript>
                </Col>
            </Row>
        </>
    );
};
/*center=insExCoordItems[0].position*/
/*
 <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <Dropdown className="btn-toolbar">
                    <Dropdown.Toggle as={Button} variant="primary" size="sm" className="me-2">
                        <FontAwesomeIcon icon={faPlus} className="me-2" />New Task
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
                        <Dropdown.Item className="fw-bold">
                            <FontAwesomeIcon icon={faTasks} className="me-2" /> New Task
                        </Dropdown.Item>
                        <Dropdown.Item className="fw-bold">
                            <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" /> Upload Files
                        </Dropdown.Item>
                        <Dropdown.Item className="fw-bold">
                            <FontAwesomeIcon icon={faUserShield} className="me-2" /> Preview Security
                        </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item className="fw-bold">
                            <FontAwesomeIcon icon={faRocket} className="text-danger me-2" /> Upgrade to Pro
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <ButtonGroup>
                    <Button variant="outline-primary" size="sm">Share</Button>
                    <Button variant="outline-primary" size="sm">Export</Button>
                </ButtonGroup>
            </div>

            <Row className="justify-content-md-center">
                <Col xs={12} className="mb-4 d-none d-sm-block">
                    <SalesValueWidget
                        title="Sales Value"
                        value="10,567"
                        percentage={10.57}
                    />
                </Col>
                <Col xs={12} className="mb-4 d-sm-none">
                    <SalesValueWidgetPhone
                        title="Sales Value"
                        value="10,567"
                        percentage={10.57}
                    />
                </Col>
                <Col xs={12} sm={6} xl={4} className="mb-4">
                    <CounterWidget
                        category="Customers"
                        title="345k"
                        period="Feb 1 - Apr 1"
                        percentage={18.2}
                        icon={faChartLine}
                        iconColor="shape-secondary"
                    />
                </Col>

                <Col xs={12} sm={6} xl={4} className="mb-4">
                    <CounterWidget
                        category="Revenue"
                        title="$43,594"
                        period="Feb 1 - Apr 1"
                        percentage={28.4}
                        icon={faCashRegister}
                        iconColor="shape-tertiary"
                    />
                </Col>

                <Col xs={12} sm={6} xl={4} className="mb-4">
                    <CircleChartWidget
                        title="Traffic Share"
                        data={trafficShares} />
                </Col>
            </Row>

            <Row>
                <Col xs={12} xl={12} className="mb-4">
                    <Row>
                        <Col xs={12} xl={8} className="mb-4">
                            <Row>
                                <Col xs={12} className="mb-4">
                                    <PageVisitsTable />
                                </Col>

                                <Col xs={12} lg={6} className="mb-4">
                                    <TeamMembersWidget />
                                </Col>

                                <Col xs={12} lg={6} className="mb-4">
                                    <ProgressTrackWidget />
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={12} xl={4}>
                            <Row>
                                <Col xs={12} className="mb-4">
                                    <BarChartWidget
                                        title="Total orders"
                                        value={452}
                                        percentage={18.2}
                                        data={totalOrders} />
                                </Col>

                                <Col xs={12} className="px-0 mb-4">
                                    <RankingWidget />
                                </Col>

                                <Col xs={12} className="px-0">
                                    <AcquisitionWidget />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>*/