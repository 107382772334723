import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from 'react-router-dom';
import { renderToString } from 'react-dom/server';
import { Routes } from "../../routes";

//import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table, ProgressBar, Modal, ModalBody, ModalFooter, Container } from '@themesberg/react-bootstrap';
import { HubConnectionBuilder } from '@microsoft/signalr';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faGlobe, faWaveSquare, faHome, faPrint, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as PulseIcon } from '../../assets/img/icons/pulse-line-svgrepo-com.svg';
import * as Luxon from "luxon";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

/*import { TransactionsTable } from "../../components/Tables";*/
import geoNow from '../../utils/geonow';
import { useSelector } from 'react-redux';

import ReactSelect from 'react-select';

import { ReactTabulator, reactFormatter } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.css';

import * as XLSX from 'xlsx';

import {
    Chart as ChartJS,
    registerables
} from 'chart.js';
import { Chart, Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

import moment from "moment-timezone";
import 'moment/locale/zh-tw';

import { subscribe } from 'redux-subscriber';
import { useIntervalWhen } from "rooks";
import { Tabulator } from "react-tabulator/lib/types/TabulatorTypes";
import ReactToPrint from 'react-to-print';


ChartJS.register(
    ...registerables,
    annotationPlugin,
    zoomPlugin
);

export default (props = {}) => {

    const tableRef = useRef(null);
    const tableCwbEqRef = useRef(null);
    const projCombSelectRef = React.useRef(null);
    const [statusShowingIdOfProjects, setStatusShowingIdOfProjects] = React.useState([]);
    const [projectCombineOptions, setProjectCombineOptions] = React.useState([]);

    const history = useHistory();

    const rdx_project = useSelector(state => state.project);
    const rdx_user = useSelector(state => state.user);
    const lastName = rdx_user.lastName;
    const firstName = rdx_user.firstName;
    const token = rdx_user.token;

    const [selectedProject, setSelectedProject] = React.useState(
        geoNow.stateHelper.project.getJoinedProjectItemByProjectId(rdx_project.currentProjectId, rdx_project.joinedProjects)
    );
    const unsubscribe_currentProjectId = subscribe('project.currentProjectId', state => {
        // do something
        let id = state.project.currentProjectId;
        let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(id, state.project.joinedProjects);
        setSelectedProject(proj);
    });

    const [cssPrimaryColor, setCssPrimaryColor] = React.useState();
    const [cssSecondaryColor, setCssSecondaryColor] = React.useState();
    const [cssTertiaryColor, setCssTertiaryColor] = React.useState();
    const [cssQuaternaryColor, setCssQuaternaryColor] = React.useState();
    const [cssGraphLineColor, setCssGraphLineColor] = React.useState("#000000");

    const [cwbeqConnection, setCwbeqConnection] = React.useState(null);
    const [cwbeqRealtimeItems, setCwbeqRealtimeItems] = React.useState([]);

    const [eqWavesData, setEqWavesData] = React.useState();

    const [loadingProp, setLoadingProp] = useState({
        isLoading: false,
        closeModal: false
    });    

    React.useEffect(() => {

        if (!selectedProject) return;

        if (!selectedProject.projectListEQ_Enabled) {
            history.push({
                pathname: Routes.DataOverview.path,
            });
            return;
        }

        //if (tableRef?.current) {
        //    tableRef.current.setData();
        //}

        setStatusShowingIdOfProjects([selectedProject.projectId]);

        let otherProjects = rdx_project.joinedProjects.filter(x => x.projectStatusTable_Enabled && x.projectId !== selectedProject.projectId);
        let projCombOptions = otherProjects.map(item => {
            return {
                label: item.projectName,
                value: item.projectId,
            };
        });
        setProjectCombineOptions(projCombOptions);
    }, [selectedProject]);


    const [autoUpdatePer, setAutoUpdatePer] = React.useState(10000); // miliseconds
    const [countdown, setCountdown] = React.useState(autoUpdatePer);
    const [isAutoUpdate, setIsAutoUpdate] = React.useState(true);
    const [isCountdownPaused, setIsCountdownPaused] = React.useState(false);

    const [cwbeqPage, setCwbebPage] = React.useState(0);
    const [isCwbeqModalShow, setIsCwbeqModalShow] = React.useState(false);

    const [isEqWavesModalShow, setIsEqWavesModalShow] = React.useState(false);

    //function useInterval(callback, delay) {
    //    const savedCallback = useRef();

    //    // Remember the latest callback.
    //    useEffect(() => {
    //        savedCallback.current = callback;
    //    }, [callback]);

    //    // Set up the interval.
    //    useEffect(() => {
    //        function tick() {
    //            savedCallback.current();
    //        }
    //        if (delay !== null) {
    //            let id = setInterval(tick, delay);
    //            return () => clearInterval(id);
    //        }
    //    }, [delay]);
    //}

    const [tabulatorOptions, setTabulatorOptions] = React.useState({
        pagination: true,
        paginationMode: 'remote',
        filterMode: 'remote',
        ajaxURL: geoNow.api.siteData.getUri_getListEQEntries([selectedProject.projectId]),
        ajaxConfig: {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        },
        ajaxURLGenerator: function (url, config, params) {
            //url - the url from the ajaxURL property or setData function
            //config - the request config object from the ajaxConfig property
            //params - the params object from the ajaxParams property, this will also include any pagination, filter and sorting properties based on table setup
            let customUrl = url + "?";
            if (params.page) {
                customUrl = customUrl + "page=" + params.page + "&";
            }
            if (params.size) {
                // 當 pagesize 選擇 All 時，Tabulator 參數就不是數字而是 True，後端只接受 -1。
                if ((params.size !== true))
                    customUrl = customUrl + "size=" + params.size + "&";
                else
                    customUrl = customUrl + "size=-1&";
            }
            if (params.filter && params.filter.length > 0) {
                customUrl = customUrl + "filter=" + encodeURI(JSON.stringify(params.filter)) + "&" + "isFilterCustomUrlEncoded=true";
            }
            return customUrl;
        },
        /*ajaxParams: { token: token },*/
        paginationSize: 10,
        paginationSizeSelector: [10, 50, 100, 250, true],
        responsiveLayout: false
    });

    const eqWaveChartOptions = {
        responsive: true,
        borderColor: cssGraphLineColor,
        //indexAxis: 'y',
        animation: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'millisecond',
                    displayFormats: {
                        'millisecond': 'mm:ss',
                        'second': 'M/D H:m:s',
                        'minute': 'M/D H:m:s',
                        'hour': 'M/D H:m:s',
                        'day': 'M/D H:m:s',
                        'week': 'M/D H:m:s',
                        'month': 'M/D H:m:s',
                        'quarter': 'M/D H:m:s',
                        'year': 'M/D H:m:s',
                        //'millisecond': 'M/D H:m:s',
                        //'second': 'M/D H:m:s',
                        //'minute': 'M/D H:m:s',
                        //'hour': 'M/D H:m:s',
                        //'day': 'M/D H:m:s',
                        //'week': 'M/D H:m:s',
                        //'month': 'M/D H:m:s',
                        //'quarter': 'M/D H:m:s',
                        //'year': 'M/D H:m:s'
                    }
                },
                ticks: {
                    //source: 'labels',
                    //maxTicksLimit: 8200 / 4,
                }
            },
            y: {

            }
        },
        datasets: {
            line: {
                pointRadius: 0, // disable for all `'line'` datasets
                borderWidth: 1,
            }
        },
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false,
                //text: "歷時圖表"
            },
            //zooming
            //zoom: {
            //    pan: {
            //        enabled: true,
            //        mode: 'xy',
            //        modifierKey: 'ctrl',
            //    },
            //    zoom: {
            //        mode: 'xy',
            //        drag: {
            //            enabled: true,
            //            borderColor: 'rgb(54, 162, 235)',
            //            borderWidth: 1,
            //            backgroundColor: 'rgba(54, 162, 235, 0.3)'
            //        }
            //    }
            //}
        }
    };

    class GraphButton extends React.Component {

        constructor(props) {
            super(props);
            this.cell = props.cell;
            this.projectId = props.cell._cell.row.data.pid;
            this.filepath = props.cell._cell.row.data.fileName;


            // Check if props.isV is undefined or null
            if (props.isV == undefined) {
                this.isV = false;
            } else {
                this.isV = props.isV;
            }

            

            this.handleDataLoaded = props.onDataLoaded;
            /**@type {Tabulator.RowComponent} */

            this.tabulatorRow = props.cell._cell.row;

            this.handleButtonClick = (e) => {
                e.preventDefault();
                setLoadingProp({
                    isLoading: true,
                    closeModal: false
                });

                geoNow.api.siteData.getListEQ_XYZGraph(token, this.projectId, this.isV, this.filepath, err => {
                    setLoadingProp({
                        isLoading: false,
                        closeModal: true
                    });                    
                    Swal.fire({
                        title: "錯誤",
                        html: `請求失敗。<br/>${JSON.stringify(err)}`,
                        icon: 'error',
                        showConfirmButton: true,
                    });
                }).then(res => {
                    setLoadingProp({
                        isLoading: false,
                        closeModal: true
                    });
                    if (res.status !== 1) {
                        Swal.fire({
                            title: "錯誤",
                            html: `回傳失敗。<br/>${res.message ?? ""}`,
                            icon: 'error',
                            showConfirmButton: true,
                        });
                        return;
                    }
                    const data = res.message;
                    this.handleDataLoaded(data, this.tabulatorRow, this.isV);
                });

            }
        }

        componentDidMount() {
            console.log("mounted!")
        }

        render() {
            return (<Button size="sm" variant="info" onClick={this.handleButtonClick}><PulseIcon fill="white" width={14} height={20} /></Button>);
        }
    }

    const valueFormatHelperFunction = (value) =>{
        if (value === null) {
            return "?";
        }
        const number = Number(value);
        if (isNaN(number)) {
            return value;
        }
        return number.toFixed(2);
    };
    const valueFormatter = (cell, formatterParams, onRendered) =>{
        const value = cell.getValue();
        const result = valueFormatHelperFunction(value);
        return result;
    };

    const tabulatorColumns = [
        /*{ title: "#", field: "pointIdx", responsive: 1 },*/
        {
            title: "時間", field: "date", responsive: 0, formatter: "datetime", formatterParams: {
                inputFormat: "yyyy-MM-dd'T'HH:mm:ss",
                outputFormat: "yy-MM-dd HH:mm:ss",
                invalidPlaceholder: true,
            },
            sorter: "datetime",
            sorterParams: {
                format: "yyyy-MM-dd'T'HH:mm:ss",
                alignEmptyValues: "bottom"
            }
        },
        { title: "位置", field: "location", responsive: 0, sorter: "string" },
        {
            title: "震度", field: "magnitude", responsive: 0, formatter: (cell, formatterParams, onRendered) => {
                let val = cell.getValue();
                let valNum = Number(val);
                let intP = Math.trunc(val);
                if (val >= 5) {

                    if (val - intP >= 0.5) {
                        return intP + "強";
                    }
                    return intP + "弱";
                }
                return intP;
                //if (val === null)
                //    return "";
                //let color = "";
                //if (val >= 6)
                //    color = "red";
                //else if (val >= 4)
                //    color = "orange";
                //else if (val >= 2)
                //    color = "yellow";
                //else if (val >= 1)
                //    color = "green";
                //else
                //    color = "blue";
                //return (
                //    <span style={{ color: color }}>{val}</span>
                //);
            }
        },
        {
            title: "作圖", responsive: 0, print: false, download: false, formatter: reactFormatter(
                <GraphButton
                    isV={false}
                    onDataLoaded={
                        /**
                         * @param {object[]} data
                         * @param {Tabulator.RowComponent} row
                         */
                        (data, row, isV) => {
                            row.getElement().style.backgroundColor = "#E8630A";
                            setEqWavesData({
                                row: row,
                                data: data,
                                isV: false
                            });
                            setIsCountdownPaused(true);
                            setIsEqWavesModalShow(true);
                        }} />)
        },
        {
            title: "v作圖", responsive: 0, print: false, download: false, formatter: reactFormatter(
                <GraphButton
                    isV={true}
                    onDataLoaded={
                        /**
                         * @param {object[]} data
                         * @param {Tabulator.RowComponent} row
                         */
                        (data, row, isV) => {
                            row.getElement().style.backgroundColor = "#E8630A";
                            setEqWavesData({
                                row: row,
                                data: data,
                                isV: true
                            });
                            setIsCountdownPaused(true);
                            setIsEqWavesModalShow(true);
                        }} />)
        },
        { title: "PGA", field: "pga", responsive: 0, formatter: valueFormatter },
        { title: "加速度X", field: "peakX", responsive: 1, formatter: valueFormatter },
        { title: "加速度Y", field: "peakY", responsive: 1, formatter: valueFormatter },
        { title: "加速度Z", field: "peakZ", responsive: 1, formatter: valueFormatter },

        { title: "速度X", field: "peakXV", responsive: 1, formatter: valueFormatter },
        { title: "速度Y", field: "peakYV", responsive: 1, formatter: valueFormatter },
        { title: "速度Z", field: "peakZV", responsive: 1, formatter: valueFormatter },
        { title: "PGV", field: "pgv", responsive: 0, formatter: valueFormatter },
        {
            title: "專案", field: "db", responsive: 1, formatter: (cell, formatterParams, onRendered) => {


                let dbName = cell.getValue();
                if (dbName === null) return "?";

                if (selectedProject.databaseName === dbName) {
                    return selectedProject.projectName;
                }

                let matchDbNameProjs = rdx_project.joinedProjects.filter(x => x.databaseName === dbName);
                if (!matchDbNameProjs || matchDbNameProjs.length === 0) {
                    console.error("Cannot find project with dbName: " + dbName);
                    return "[" + dbName + "]";
                }

                return matchDbNameProjs[0].projectName;
            }
        },
        {
            title: "X檔", field: "fileName", responsive: 0, download: false, print: false, formatter: (cell, formatterParams, onRendered) => {
                let projId = cell.getData().pid;
                let fileName = cell.getValue();
                let filePath = geoNow.api.siteData.getUri_getListEQFile(projId, false, fileName, "x", token);
                return renderToString(
                    <Button variant="info" href={filePath} target="_blank" size="sm" title={"下載X軸紀錄"}><FontAwesomeIcon icon={faDownload} /></Button>
                );
            }
        },
        {
            title: "Y檔", field: "fileName", responsive: 0, download: false, print: false, formatter: (cell, formatterParams, onRendered) => {
                let projId = cell.getData().pid;
                let fileName = cell.getValue();
                let filePath = geoNow.api.siteData.getUri_getListEQFile(projId, false, fileName, "y", token);
                return renderToString(
                    <Button variant="info" href={filePath} target="_blank" size="sm" title={"下載Y軸紀錄"}><FontAwesomeIcon icon={faDownload} /></Button>
                );
            }
        },
        {
            title: "Z檔", field: "fileName", responsive: 0, download: false, print: false, formatter: (cell, formatterParams, onRendered) => {
                let projId = cell.getData().pid;
                let fileName = cell.getValue();
                let filePath = geoNow.api.siteData.getUri_getListEQFile(projId, false, fileName, "z", token);
                return renderToString(
                    <Button variant="info" href={filePath} target="_blank" size="sm" title={"下載Z軸紀錄"}><FontAwesomeIcon icon={faDownload} /></Button>
                );
            }
        },
        {
            title: "vX檔", field: "fileName", responsive: 0, download: false, print: false, formatter: (cell, formatterParams, onRendered) => {
                let projId = cell.getData().pid;
                let fileName = cell.getValue();
                let filePath = geoNow.api.siteData.getUri_getListEQFile(projId, true, fileName, "x", token);
                return renderToString(
                    <Button variant="info" href={filePath} target="_blank" size="sm" title={"下載X軸速度紀錄"}><FontAwesomeIcon icon={faDownload} /></Button>
                );
            }
        },
        {
            title: "vY檔", field: "fileName", responsive: 0, download: false, print: false, formatter: (cell, formatterParams, onRendered) => {
                let projId = cell.getData().pid;
                let fileName = cell.getValue();
                let filePath = geoNow.api.siteData.getUri_getListEQFile(projId, true, fileName, "y", token);
                return renderToString(
                    <Button variant="info" href={filePath} target="_blank" size="sm" title={"下載Y軸速度紀錄"}><FontAwesomeIcon icon={faDownload} /></Button>
                );
            }
        },
        {
            title: "vZ檔", field: "fileName", responsive: 0, download: false, print: false, formatter: (cell, formatterParams, onRendered) => {
                let projId = cell.getData().pid;
                let fileName = cell.getValue();
                let filePath = geoNow.api.siteData.getUri_getListEQFile(projId, true, fileName, "z", token);
                return renderToString(
                    <Button variant="info" href={filePath} target="_blank" size="sm" title={"下載Z軸速度紀錄"}><FontAwesomeIcon icon={faDownload} /></Button>
                );
            }
        }
    ];

    function handleMagnitudeFilterOnChange(filterValue) {
        if (tableRef?.current) {
            tableRef.current.setFilter("magnitude", ">=", filterValue);
        }
    }

    // Element Function
    const MagnitudeDropdownOptions = React.useCallback(() => {
        let mag = ["顯示所有震度", null, null, null, null, null, null, null]; //7 items
        return mag.map((item, idx) => {
            let isActive = false;
            if (tableRef?.current) {
                let filterValue = item;
                let f = tableRef.current.getFilters();
                if (f && f.length > 0) {
                    //f.field, f.type, f.value
                    isActive = f[0].field === "magnitude" && f[0].value == idx;
                    if (isActive === true) {
                        console.log(item + "] is active!");
                    }
                }
            }

            //return (
            //    <Dropdown.Item key={idx} abc={'123'/*onClick={() => handleMagnitudeFilterOnChange(filterValue)}*/} active={isActive}>
            //        {item ?? "只顯示震度至少" + idx + "的資料"}
            //    </Dropdown.Item>
            //);

            return (
                <option key={idx} value={idx} selected={isActive}>{item ?? "只顯示震度至少" + idx + "的資料"}</option>
            );
        });
    });

    //const CwbeqModal = React.useCallback((props) => {
    //    return (

    //    );
    //});
    class CwbeqModal extends React.Component {

        constructor(props) {
            super(props);
            // this.props = props;
            this.handleShow = props.show;
            this.handleHide = props.onHide;
            this.handleShowPrevPage = props.onPrevPage;
            this.handleShowNextPage = props.onNextPage;
        }
        render() {
            return (
                <Modal show={this.handleShow} onHide={this.handleHide} aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            中央氣象局地震快報
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <Col xs={2}>
                                    時間
                                </Col>
                                <Col xs={4}>
                                    {cwbeqRealtimeItems[cwbeqPage]?.eqInfo_OriginTime ?? 'NULL'}
                                </Col>
                                <Col xs={2}>
                                    震度
                                </Col>
                                <Col xs={4}>
                                    {cwbeqRealtimeItems[cwbeqPage]?.eqInfo_Magnitude ?? 'NULL'}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    說明
                                </Col>
                                <Col xs={10}>
                                    {cwbeqRealtimeItems[cwbeqPage]?.reportContent ?? 'NULL'}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <a href={cwbeqRealtimeItems[cwbeqPage]?.web ?? ''} target="_blank">
                                        <img src={cwbeqRealtimeItems[cwbeqPage]?.reportImageURI ?? ''} alt="中央氣象局地震快報" width="100%" />
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleShowPrevPage}>＜</Button>
                        <Button onClick={this.handleShowNextPage}>＞</Button>
                        <Button onClick={this.handleHide}>關閉</Button>
                    </Modal.Footer>
                </Modal>
            );
        }
    }

    class EqWavesModal extends React.Component {

        constructor(props) {
            super(props);
            this.dataArray = props.dataArray;
            /**@type {Tabulator.RowComponent} */
            this.tabulatorRow = props.tabulatorRow;
            this.rowData = (props.tabulatorRow && props.tabulatorRow !== null) ? props.tabulatorRow.getData() : null;
            this.isV = props.isV;
            this.chartOptions = props.chartOptions;
            this.handleShow = props.show;
            this.handleHide = () => {
                if (this.tabulatorRow?.getElement()?.style !== null) {
                    this.tabulatorRow.getElement().style.backgroundColor = "";
                }                    
                props.onHide();
            }
            this.rowDataProjectName = "";
            if (this.tabulatorRow) {
                const cell = this.tabulatorRow.getCell("db");
                if (cell) {
                    let el = cell.getElement();
                    if (el) {
                        this.rowDataProjectName = el.innerText;
                    }
                }                    
            }
            
            
            //this.selfRef = React.createRef();
        }
        render() {
            return (
                <>
                    <Modal size="lg" show={this.handleShow} onHide={this.handleHide} aria-labelledby="contained-modal-title-vcenter">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                地震波形圖{this.isV ? ": 速度" : ""}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="show-grid">
                            <Container ref={el => (this.componentRef = el)}>
                                <Row className="mt-3">
                                    <Col xs={6} className="fs-6">
                                        <h6 class="mb-0">起始時間</h6>
                                        { this.rowData?.date?.toString() ?? "" }
                                    </Col>
                                    <Col xs={6}>
                                        <h6 class="mb-0">位置</h6>
                                        {this.rowData?.location ?? ""}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <h6 class="mb-0">震度</h6>
                                        {this.rowData?.magnitude ?? ""} 級
                                    </Col>
                                    <Col xs={6}>
                                        <h6 class="mb-0">專案</h6>
                                        {this.rowDataProjectName}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <h6 class="mb-0">PGA</h6>
                                        {valueFormatHelperFunction(this.rowData?.pga)} cm/s<sup>2</sup>
                                    </Col>
                                    <Col xs={6}>
                                        <h6 class="mb-0">PGV</h6>
                                        {valueFormatHelperFunction(this.rowData?.pgv)} cm/s
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <h6 class="mb-0">加速度X</h6>
                                        {valueFormatHelperFunction(this.rowData?.peakX)} cm/s<sup>2</sup>
                                    </Col>
                                    <Col xs={6}>
                                        <h6 class="mb-0">速度X</h6>
                                        {valueFormatHelperFunction(this.rowData?.peakXV)} cm/s
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <h6 class="mb-0">加速度Y</h6>
                                        {valueFormatHelperFunction(this.rowData?.peakY)} cm/s<sup>2</sup>
                                    </Col>
                                    <Col xs={6}>
                                        <h6 class="mb-0">速度Y</h6>
                                        {valueFormatHelperFunction(this.rowData?.peakYV)} cm/s
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6}>
                                        <h6 class="mb-0">加速度Z</h6>
                                        {valueFormatHelperFunction(this.rowData?.peakZ)} cm/s<sup>2</sup>
                                    </Col>
                                    <Col xs={6}>
                                        <h6 class="mb-0">速度Z</h6>
                                        {valueFormatHelperFunction(this.rowData?.peakZV)} cm/s
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        
                                        {this.dataArray !== null && this.dataArray?.length > 0 &&
                                            <>
                                                <Line className="mb-1" options={this.chartOptions} data={this.dataArray[0]} />
                                                <Line className="mb-1" options={this.chartOptions} data={this.dataArray[1]} />
                                                <Line className="mb-1" options={this.chartOptions} data={this.dataArray[2]} />
                                            </>
                                            }
                                        
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactToPrint
                                trigger={() => {
                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                    // to the root node of the returned component as it will be overwritten.
                                    return (<Button variant="secondary">列印</Button>);
                                }}
                                content={() => this.componentRef}
                            />
                            <Button onClick={this.handleHide}>關閉</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            );
        }
    }


    //function CwbeqModal(props) {

    //}

    useIntervalWhen(
        () => {
            setCountdown(countdown - 1000);
            if (countdown === 0) {
                console.log("updated!");
                if (tableRef?.current) {
                    tableRef.current.setData();
                }
                if (tableCwbEqRef?.current) {
                    tableCwbEqRef.current.setData();
                }

                setCountdown(autoUpdatePer);
            }
        },
        1000, // run callback every 1 second
        isAutoUpdate && (!isCountdownPaused), // start the timer when it's true
        false // no need to wait for the first interval
    );



    // RUN ONCE
    React.useEffect(() => {
        //alert(Luxon.DateTime.fromFormat("2022-04-24T23:43:53", "yyyy-MM-dd'T'HH:mm:ss"));
        window.luxon = Luxon; //for tabulator's datetime formatter
        window.XLSX = XLSX; //for tabulator's downloading function

        let s = geoNow.api.signalR.getUri_cwbeq();
        console.log(s);

        const connection = new HubConnectionBuilder()
            .withUrl(s)
            .withAutomaticReconnect()
            //.configureLogging(LogLevel.Information)
            .build();
        setCwbeqConnection(connection);


        const style = getComputedStyle(document.body);
        const primCol = style.getPropertyValue('--primary');
        const secCol = style.getPropertyValue('--secondary');
        const terCol = style.getPropertyValue('--tertiary');
        const quaCol = style.getPropertyValue('--quaternary');
        // $("#mydiv").text("primaryColor: " + primCol);
        setCssPrimaryColor(primCol);
        setCssSecondaryColor(secCol);
        setCssTertiaryColor(terCol);
        setCssQuaternaryColor(quaCol);
        if (secCol && secCol !== null) {
            setCssGraphLineColor(adjustHexColorOpacity(secCol, 0.5));
        }
    }, []);

    React.useEffect(() => {
        async function startCwbeqConnection() {
            try {
                await cwbeqConnection.start();
                console.log("SignalR Connected.");
                cwbeqConnection.on("ReceiveEQEvent", (data) => {
                    console.log(data);
                    if (cwbeqRealtimeItems.some(item => item.earthquakeNo == data.earthquakeNo)) {
                        console.log('ignore this notification, cuz already has it (same earthquakeNo)');
                        return;
                    }
                    console.log("added to cwbeqRealtimeItems!");
                    // setCwbeqRealtimeItems([...cwbeqRealtimeItems, data]);
                    let arr = cwbeqRealtimeItems;
                    arr.push(data);
                    setCwbeqRealtimeItems(arr);

                    setIsCountdownPaused(true);
                    setIsCwbeqModalShow(true);
                });
            } catch (err) {
                console.log(err);
            }
        };
        if (cwbeqConnection) {
            console.log('cwbeqConnection, ready to connect.');
            startCwbeqConnection();
        }
    }, [cwbeqConnection]);


    React.useEffect(() => {
        if (!statusShowingIdOfProjects || statusShowingIdOfProjects.length === 0) {
            return;
        }

        let uri = geoNow.api.siteData.getUri_getListEQEntries(statusShowingIdOfProjects);
        setTabulatorOptions({ ...tabulatorOptions, ajaxURL: uri });


    }, [statusShowingIdOfProjects]);

    React.useEffect(() => {

        if (!tabulatorOptions.ajaxURL) {
            console.log("ajaxURL not ready.");
            return;
        }

        if (tableRef?.current) {
            // tableRef.current.ajaxURL = uri;

            if (!tableRef.current.initialized) {
                tableRef.current.on("tableBuilt", function () {
                    tableRef.current.setData(tabulatorOptions.ajaxURL);
                });
            } else {
                tableRef.current.setData(tabulatorOptions.ajaxURL);
            }
        }
    }, [tabulatorOptions])

    React.useEffect(() => {
        if (loadingProp.isLoading) {
            Swal.fire({
                title: "讀取中...",
                //icon: "info",
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
            //Swal.showLoading();
        } else if (loadingProp.closeModal) {
            Swal.close();
            loadingProp.closeModal = false;
        }
    }, [loadingProp]);

    //React.useEffect(() => {
    //    console.log("isCwbeqModalShow CHANGED! " + isCwbeqModalShow);

    //    if (isCwbeqModalShow) {
    //        if (isAutoUpdate) {
    //            isAutoUpdate = false;
    //            setCountdown(autoUpdatePer);
    //        }
    //    } else {
    //        if (!isAutoUpdate) {
    //            isAutoUpdate = false;
    //            setCountdown(autoUpdatePer);
    //        }
    //    }

    //}, [isCwbeqModalShow]);

    function adjustHexColorOpacity(hexC, opacity) {
        let hex = hexC.trim();
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let r = parseInt(result[1], 16);
        let g = parseInt(result[2], 16);
        let b = parseInt(result[3], 16);
        let final = "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
        return final;
    }

    return (
        <>
            <CwbeqModal
                show={isCwbeqModalShow}
                onHide={() => {
                    setIsCwbeqModalShow(false);
                    if (!isEqWavesModalShow) {
                        setIsCountdownPaused(false);
                    }
                }}
                onNextPage={() => {
                    if (cwbeqPage < cwbeqRealtimeItems.length - 1) {
                        setCwbeqPage(cwbeqPage + 1);
                    }
                }}
                onPrevPage={() => {
                    if (cwbeqPage > 0) {
                        setCwbeqPage(cwbeqPage - 1);
                    }
                }}
            />
            <EqWavesModal
                show={isEqWavesModalShow}
                onHide={() => {
                    setIsEqWavesModalShow(false);
                    if (!isCwbeqModalShow) {
                        setIsCountdownPaused(false);
                    }
                }}
                dataArray={eqWavesData?.data}
                tabulatorRow={eqWavesData?.row}
                isV={eqWavesData?.isV}
                chartOptions={eqWaveChartOptions}
            />

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>GeoNow</Breadcrumb.Item>
                        <Breadcrumb.Item active>地震事件</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>地震事件</h4>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm"
                            onClick={e => {
                                e.preventDefault();
                                if (!(tableRef?.current)) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `列印失敗。<br/>找不到表格控制項。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                tableRef.current.print();
                                // tableRef.current.print();
                            }}
                        ><FontAwesomeIcon icon={faPrint} /> 列印</Button>
                        <Button
                            onClick={e => {
                                e.preventDefault();
                                if (!(tableRef?.current)) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `下載失敗。<br/>找不到表格控制項。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                let csvFilename = "[" + (lastName ?? "") + (firstName ?? "") + "|" + (selectedProject?.name ?? (selectedProject?.databaseName ?? "")) + "] eqEvent.xlsx";
                                tableRef.current.download("xlsx", csvFilename,
                                    {
                                        sheetName: "EQEvent",

                                        documentProcessing:
                                            /**
                                             * 
                                             * @param {XLSX.WorkBook} wb
                                             */
                                            function (wb) {
                                                let firstSht = wb.Sheets[wb.SheetNames[0]];
                                                
                                                let firstColIdx = XLSX.utils.decode_col("A");
                                                //https://github.com/SheetJS/sheetjs/issues/134

                                                let range = XLSX.utils.decode_range(firstSht['!ref']);
                                                
                                                for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                                                    if (rowNum > 0) {
                                                        /**@type XLSX.CellObject */
                                                        let cell = firstSht[`A${rowNum + 1}`];
                                                        if (cell) {
                                                            //cell.v = String(cell.v).replace("T", " ");
                                                            
                                                            let jDate = moment(cell.v, "YYYY-MM-DDTHH:mm:ss").toDate();
                                                            cell.v = jDate;
                                                            cell.t = "d";
                                                            cell.z = "yyyy/m/d h:mm:ss;@";
                                                            //if (jDate) {
                                                            //    //https://stackoverflow.com/questions/57145840/converting-excel-date-to-moment-date-gives-a-wrong-year-output
                                                            //    let excelTime = jDate.valueOf() / 86400000 + 25569 + 0.334270833;
                                                            //    cell.z = "yyyy/m/d h:mm:ss;@";
                                                            //    cell.v = " " + excelTime;
                                                            //}
                                                            //cell.z = "yyyy/m/d h:mm:ss;@";
                                                        }

                                                        // 擷取第一列資料列，當作欄寬調整依據 (算字數)。
                                                        if (rowNum === 1) {
                                                            let arrColumnOpts = [];
                                                            
                                                            for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
                                                                if (colNum === 0) {
                                                                    // 第一個欄位為日期，數值為小數很多位，所以這邊不能數字數，要數格式化字串。
                                                                    // wch 就是字數，半形字元。
                                                                    arrColumnOpts.push({ wch: "YYYY/MM/DD HH:mm:ss".length });
                                                                } else {
                                                                    // 將欄位編號運用 encode_col() 轉換為 A1 Style 的英文字母部分。
                                                                    let cell2 = firstSht[`${XLSX.utils.encode_col(colNum)}${rowNum + 1}`];
                                                                    // 若 Cell 為 undefined，忽略避免錯誤。
                                                                    if (cell2) {
                                                                        let charCount = String(cell2.v).length; //數字數
                                                                        if (charCount < 4) charCount = 4; // 因為標題列至少也有兩個中文字，所以抓 4 個字元為最小寬度。
                                                                        arrColumnOpts.push({ wch: charCount });
                                                                    }
                                                                }
                                                            }
                                                            // 將新的參數放回 欄位屬性設定陣列 !cols。
                                                            firstSht['!cols'] = arrColumnOpts;
                                                        }
                                                    }

                                                    //Auto adjust col width
                                                    //firstSht['!cols'] = fitToColumn(arrayOfArray);
                                                    //function fitToColumn(arrayOfArray) {
                                                    //    // get maximum character of each column
                                                    //    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
                                                    //}


                                                    // firstSht["!cols"] = [{ wch: 20 }]; //Adjust columns' width one by one in order
                                                    


                                                }

                                                //let range = XLSX.utils.decode_range(firstSht['!ref']);
                                                //for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                                                //    row = [];
                                                //    for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
                                                //        var nextCell = sheet[
                                                //            XLSX.utils.encode_cell({ r: rowNum, c: colNum })
                                                //        ];
                                                //        if (typeof nextCell === 'undefined') {
                                                //            row.push(void 0);
                                                //        } else row.push(nextCell.w);
                                                //    }
                                                //    result.push(row);
                                                //}

                                                //firstSht.A2.z = "YYYY-MM-SSTHH:mm:ss"

                                                //XLSX.utils.format_cell()
                                                //firstCol.DBF.type = "Date";
                                                return wb;
                                            }
                                    });
                            }}
                            variant="outline-primary" size="sm"><FontAwesomeIcon icon={faFileExcel} /> 下載</Button>
                    </ButtonGroup>
                </div>
            </div>
            <Form>
                <Row>
                    {/*<div style={{transition: .2 + 's'}}>*/}

                    {/*</div>*/}
                    <ProgressBar className="transition1slinear" animated now={countdown / autoUpdatePer * 100} variant="info" />
                </Row>
                <Row>
                    <Col>
                        <Form.Check type="switch" className="form-check">
                            <Form.Check.Input id="chbAutoUpdate" type="checkbox" checked={isAutoUpdate} onChange={() => {
                                setIsAutoUpdate(!isAutoUpdate);
                                if (isAutoUpdate) {
                                    setCountdown(autoUpdatePer);
                                }
                            }} />
                            <Form.Check.Label htmlFor="chbAutoUpdate" value="123">
                                自動更新 {isAutoUpdate ? 'ON: 剩餘 ' + countdown / 1000 + ' 秒。' : 'OFF'}
                            </Form.Check.Label>
                        </Form.Check>
                    </Col>
                    <Col>
                        <Form.Select onChange={e => handleMagnitudeFilterOnChange(e.target.value)} >
                            <MagnitudeDropdownOptions />
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Form.Group as={Col}>
                        <Form.Label>合併顯示候選</Form.Label>
                        <ReactSelect options={projectCombineOptions}
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedOptions) => {
                                //setStatusShowingIdOfProjects(selectedOptions.map(x => x.value));
                            }}

                            ref={projCombSelectRef}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs="auto">
                        <Form.Label>最終</Form.Label><br />
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={e => {
                                e.preventDefault();
                                if (projCombSelectRef?.current) {
                                    let props = projCombSelectRef.current.props;
                                    let arrOthers = props.value.map(x => x.value);
                                    arrOthers.unshift([selectedProject.projectId]);
                                    //alert(arrOthers);
                                    setStatusShowingIdOfProjects(arrOthers);
                                }
                            }}>合併</Button>
                        <Button variant="light"
                            onClick={e => {
                                e.preventDefault();
                                if (projCombSelectRef?.current) {
                                    // let props = projCombSelectRef.current.props;
                                    projCombSelectRef.current.clearValue();
                                    setStatusShowingIdOfProjects([selectedProject.projectId]);
                                }
                            }}>清除重整</Button>
                    </Form.Group>
                </Row>
            </Form>
            <div className="overflow-auto">
                <div style={{ width: "fit-content" }}>
                    <ReactTabulator
                        className="mt-3 overflow-auto"
                        onRef={(r) => {
                            if (!r) {
                                return;
                            }
                            tableRef.current = r.current;
                        }}
                        options={tabulatorOptions}
                        /*data={filteredStatusDataEntries}*/
                        columns={tabulatorColumns}
                        layout={"fitData"}
                    /*events={{
                        "rowClick": (e, row) => {
                            let rowData = row.getData();
                            console.log(rowData);
    
                        }
                    }}*/
                    />
                </div>
            </div>
            <div className="overflow-auto">
                <div style={{ width: "fit-content" }}>
                    <ReactTabulator
                        className="mt-3"
                        layout={"fitData"}
                        onRef={(r) => {
                            if (!r) {
                                return;
                            }
                            tableCwbEqRef.current = r.current;
                            const uri = geoNow.api.cwb.getUri_getStoredEQItems(true);
                            // tableCwbEqRef.current.setData(uri);
                        }}
                        options={{

                            pagination: true,
                            paginationMode: 'remote',
                            filterMode: 'remote',
                            ajaxURL: geoNow.api.cwb.getUri_getStoredEQItems(true),
                            ajaxConfig: {
                                method: 'GET',
                                headers: {
                                    'Authorization': 'Bearer ' + token
                                }
                            },
                            ajaxURLGenerator: function (url, config, params) {
                                //url - the url from the ajaxURL property or setData function
                                //config - the request config object from the ajaxConfig property
                                //params - the params object from the ajaxParams property, this will also include any pagination, filter and sorting properties based on table setup
                                let customUrl = url + "?";
                                if (params.page) {
                                    customUrl = customUrl + "page=" + params.page + "&";
                                }
                                if (params.size) {
                                    // 當 pagesize 選擇 All 時，Tabulator 參數就不是數字而是 True，後端只接受 -1。
                                    if ((params.size !== true))
                                        customUrl = customUrl + "size=" + params.size + "&";
                                    else
                                        customUrl = customUrl + "size=-1&";
                                }
                                if (params.filter && params.filter.length > 0) {
                                    customUrl = customUrl + "filter=" + encodeURI(JSON.stringify(params.filter)) + "&" + "isFilterCustomUrlEncoded=true";
                                }
                                return customUrl;
                            },
                            ajaxResponse: function (url, params, response) {
                                //url - the URL of the request
                                //params - the parameters passed with the request
                                //response - the JSON object returned in the body of the response.

                                return response.message; //pass the data array into Tabulator
                            },
                            /*ajaxParams: { token: token },*/
                            paginationSize: 10,

                            paginationSizeSelector: [10, 50, 100, 250, true],
                            responsiveLayout: false
                        }}
                        columns={[
                            {
                                title: "時間", field: "eqInfo_OriginTime", responsive: 0, formatter: "datetime", formatterParams: {
                                    inputFormat: "yyyy-MM-dd'T'HH:mm:ss",
                                    outputFormat: "yy-MM-dd HH:mm:ss",
                                    invalidPlaceholder: true,
                                },
                                sorter: "datetime",
                                sorterParams: {
                                    format: "yyyy-MM-dd'T'HH:mm:ss",
                                    alignEmptyValues: "bottom"
                                }
                            },
                            { title: "#", field: "earthquakeNo", width: 70, responsive: 0 },
                            { title: "報告顏色", field: "reportColor", width: 50, responsive: 0 },
                            { title: "震度", field: "eqInfo_Magnitude", width: 50, responsive: 0 },
                            /*{ title: "內容", field: "reportContent", responsive: 0 },*/
                            { title: "深度", field: "eqInfo_Depth", width: 50, responsive: 0 },
                            {
                                title: "連結", field: "fileName", responsive: 0, formatter: (cell, formatterParams, onRendered) => {
                                    let web = cell.getData().web;
                                    return renderToString(
                                        <Button variant="info" href={web} target="_blank" size="sm" title={"外連中央氣象局報告"}><FontAwesomeIcon icon={faGlobe} /></Button>
                                    );
                                }
                            },
                            { title: "經度", field: "eqInfo_EpiLat", responsive: 0 },
                            { title: "緯度", field: "eqInfo_EpiLon", responsive: 0 },
                            { title: "位置", field: "eqInfo_EpiLocation", responsive: 0 },

                            //{ title: "加速度Y", field: "peakY", responsive: 1 },
                            //{ title: "加速度Z", field: "peakZ", responsive: 1 },

                            //{ title: "速度X", field: "peakXV", responsive: 1 },
                            //{ title: "速度Y", field: "peakYV", responsive: 1 },
                            //{ title: "速度Z", field: "peakZV", responsive: 1 },
                        ]}
                        events={{
                            "dataLoading": (data) => {
                                // alert(JSON.stringify(data));
                            },
                            "dataLoadError": function (error) {
                                //error - the returned error object
                                alert(JSON.stringify(error));
                            },
                            "rowClick": (e, row) => {
                                let rowData = row.getData();
                                Swal.fire({
                                    title: "地震報告內容",
                                    text: rowData.reportContent,
                                    icon: 'info',
                                    showConfirmButton: true,
                                });
                            }
                        }}
                    />
                </div>
            </div>
            
            
            
        </>
    );
};