
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faSignInAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup, Button } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import { Link } from 'react-router-dom';

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";

import { useSelector, useDispatch } from 'react-redux';
import { doSetCurrentProjectId } from './../actions';
import geoNow from '../utils/geonow';
import { Route } from "react-router-dom";



function renderAuth(user, project, isLoggedIn) {
    if (isLoggedIn) {
        return (
            <>
                <Dropdown as={Nav.Link}>
                    <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                        <div className="media d-flex align-items-center">
                            <Image src={user.avatarUrlSmall} className="user-avatar md-avatar rounded-circle" />
                            <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                                <span className="mb-0 font-small fw-bold">{user.firstName}</span>
                                <span className="mb-0 font-small fw-bold">{user.lastName}</span>
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                        {/*<Dropdown.Item className="fw-bold">*/}
                        {/*    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile*/}
                        {/*</Dropdown.Item>*/}
                        <Dropdown.Item as={Link} to={Routes.Settings.path} className="fw-bold">
                            <FontAwesomeIcon icon={faCog} className="me-2" /> 設定
                        </Dropdown.Item>
                        {/*<Dropdown.Item className="fw-bold">*/}
                        {/*    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages*/}
                        {/*</Dropdown.Item>*/}
                        {/*<Dropdown.Item className="fw-bold">*/}
                        {/*    <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support*/}
                        {/*</Dropdown.Item>*/}

                        <Dropdown.Divider />

                        <Dropdown.Item as={Link} to={Routes.Signout.path} className="fw-bold">

                            <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> 登出

                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>

            </>
        );
    }
    else {
        return (
            <>
                <Button href={"#" + Routes.Signin.path} variant="primary" className="mb-3 w-100"><FontAwesomeIcon icon={faSignInAlt} className="me-1" /> 登入</Button>
            </>
        );
    }

    //let token = GeoNow.getToken();
    //if (token) {
    //    //var uMe = await GeoNow.getUserMe(token);

    //}
    return (
        <>
            { /* }<b>{(user === null || user.token === null) ? 'No token!' : user.token}</b>  {*/}

        </>
    );
}

export default (props) => {
    const dispatch = useDispatch();

    const rdx_user = useSelector(state => state.user);
    const isLoggedIn = rdx_user.id && rdx_user.username;
    const rdx_project = useSelector(state => state.project);

    let defaultSelectedProj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(rdx_project.currentProjectId, rdx_project.joinedProjects);
    const [selectedProject, setSelectedProject] = useState(defaultSelectedProj);


    const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
    const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

    const markNotificationsAsRead = () => {
        setTimeout(() => {
            setNotifications(notifications.map(n => ({ ...n, read: true })));
        }, 300);
    };

    function handleSelectedProjectOnChange(projectId) {
        // let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(projectId, rdx_project.joinedProjects);
        dispatch(doSetCurrentProjectId(projectId));
        //alert(proj.projectName);
    }

    const Notification = (props) => {
        const { link, sender, image, time, message, read = false } = props;
        const readClassName = read ? "" : "text-danger";

        return (
            <ListGroup.Item action href={link} className="border-bottom border-light">
                <Row className="align-items-center">
                    <Col className="col-auto">
                        <Image src={image} className="user-avatar lg-avatar rounded-circle" />
                    </Col>
                    <Col className="ps-0 ms--2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <h4 className="h6 mb-0 text-small">{sender}</h4>
                            </div>
                            <div className="text-end">
                                <small className={readClassName}>{time}</small>
                            </div>
                        </div>
                        <p className="font-small mt-1 mb-0">{message}</p>
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    };

    return (
        <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
            <Container fluid className="px-0">
                <div className="d-flex justify-content-between w-100">
                    <div className="d-flex align-items-center">
                        <Form className="navbar-search">
                            {/*className="mb-3"*/}
                            <Form.Group id="topbarSearch">
                                {/*<Form.Label>Projects</Form.Label>*/}
                                <InputGroup className="input-group-merge search-bar">
                                    <Form.Select onChange={e => handleSelectedProjectOnChange(e.target.value) }>
                                        {rdx_project.joinedProjects.map(item => {
                                            let setSelected = false;
                                            if (defaultSelectedProj !== null) {
                                                setSelected = defaultSelectedProj.projectId === item.projectId;
                                            } else {
                                                setSelected = item.isProjectDefault;
                                            }
                                            return (<option key={item.projectId} value={item.projectId} selected={setSelected}>{item.projectName}</option>);
                                        })}
                                    </Form.Select>
                                </InputGroup>
                            </Form.Group>
                        </Form>
                        {/*<Form className="navbar-search">*/}
                        {/*    <Form.Group id="topbarSearch">*/}
                        {/*        <InputGroup className="input-group-merge search-bar">*/}
                        {/*            <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>*/}
                        {/*            <Form.Control type="text" placeholder="Search" />*/}
                        {/*        </InputGroup>*/}
                        {/*    </Form.Group>*/}
                        {/*</Form>*/}
                    </div>
                    <Nav className="align-items-center">
                        {/*<Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >*/}
                        {/*    <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">*/}
                        {/*        <span className="icon icon-sm">*/}
                        {/*            <FontAwesomeIcon icon={faBell} className="bell-shake" />*/}
                        {/*            {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}*/}
                        {/*        </span>*/}
                        {/*    </Dropdown.Toggle>*/}
                        {/*    <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">*/}
                        {/*        <ListGroup className="list-group-flush">*/}
                        {/*            <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">*/}
                        {/*                Notifications*/}
                        {/*            </Nav.Link>*/}

                        {/*            {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}*/}

                        {/*            <Dropdown.Item className="text-center text-primary fw-bold py-3">*/}
                        {/*                View all*/}
                        {/*            </Dropdown.Item>*/}
                        {/*        </ListGroup>*/}
                        {/*    </Dropdown.Menu>*/}
                        {/*</Dropdown>*/}
                        {/*{renderNavAuth()}*/}
                        {/*<a href={'#' + Routes.Signin.path} className="fw-bold"> {` Login `} </a>*/}


                        {renderAuth(rdx_user, rdx_project, isLoggedIn)}

                    </Nav>
                </div>
            </Container>
        </Navbar>
    );
};
