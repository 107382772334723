import React, { useState, useEffect, useRef, createRef, useC } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { faChartLine, faTable, faCheck, faCog, faHome, faSearch, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, ToggleButton, Dropdown, Table, Tabs, Tab, Nav, Alert } from '@themesberg/react-bootstrap';

/*import { TransactionsTable } from "../../components/Tables";*/
import geoNow from '../../utils/geonow';
import { Routes } from "../../routes";
import { useSelector } from 'react-redux';

import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.css';

//import { moment } from 'moment';
import Datetime from "react-datetime";
import moment from "moment-timezone";
import 'moment/locale/zh-tw';
import * as Luxon from "luxon";

//import swal from 'sweetalert';
import Swal from 'sweetalert2';
// import SweetAlert2 from "react-sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Chart as ChartJS,
    //CategoryScale,
    //LinearScale,
    //PointElement,
    //LineElement,
    //Title,
    //Tooltip,
    //Legend,
    //TimeScale
    registerables
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

import { subscribe } from 'redux-subscriber';
import { Tabulator } from "react-tabulator/lib/types/TabulatorTypes";
import { read, utils, writeFileXLSX } from 'xlsx';
import * as XLSX from 'xlsx';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

ChartJS.register(
    //CategoryScale,
    //LinearScale,
    //PointElement,
    //LineElement,
    //Title,
    //Tooltip,
    //Legend,
    //TimeScale,
    ...registerables,
    annotationPlugin,
    zoomPlugin
);

export default () => {

    const location = useLocation();
    const chartContainer = React.useRef(null);

    const rdx_project = useSelector(state => state.project);
    const rdx_user = useSelector(state => state.user);
    const lastName = rdx_user.lastName;
    const firstName = rdx_user.firstName;
    const token = rdx_user.token;

    const [selectedProject, setSelectedProject] = useState(
        geoNow.stateHelper.project.getJoinedProjectItemByProjectId(rdx_project.currentProjectId, rdx_project.joinedProjects)
    );

    let tableRef = useRef(null);
    let chartCloneImgRef = useRef(null);
    const handleChartPrint = useReactToPrint({
        content: () => chartCloneImgRef,
    });

    let selectedDatabaseName = selectedProject.databaseName;

    const [isLoading, setIsLoading] = useState(false);
    const [loadingProp, setLoadingProp] = useState({
        isLoading: false,
        closeModal: false
    });
    // const [swalProps, setSwalProps] = useState({});

    const [tabKey, setTabKey] = React.useState("chart");

    const [thData, setTHData] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        //animation: {
        //    onComplete: function () {
        //        if (!(chartContainer?.current)) {
        //            console.error("找不到 chart 控制項，列印圖表將無法使用。");
        //            return;
        //        }
        //        const cht = chartContainer.current;
        //        const uri = cht.toBase64Image();
        //        if (!uri || uri === null) {
        //            return;
        //        }
        //        chartCloneImgRef.src = uri;
        //    }
        //},
        //indexAxis: 'y',
        scales: {
            x: {
                type: 'time',
                time: {
                    displayFormats: {
                        'millisecond': 'M/D H:m:s',
                        'second': 'M/D H:m:s',
                        'minute': 'M/D H:m:s',
                        'hour': 'M/D H:m:s',
                        'day': 'M/D H:m:s',
                        'week': 'M/D H:m:s',
                        'month': 'M/D H:m:s',
                        'quarter': 'M/D H:m:s',
                        'year': 'M/D H:m:s'
                    }
                }
            },
            y: {

            }
        },
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: "歷時圖表"
            },
            autocolors: false,
            //zooming
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy',
                    modifierKey: 'ctrl',
                },
                zoom: {
                    mode: 'xy',
                    drag: {
                        enabled: true,
                        borderColor: 'rgb(54, 162, 235)',
                        borderWidth: 1,
                        backgroundColor: 'rgba(54, 162, 235, 0.3)'
                    }
                }
            }
        }
    });

    const [pb, setPb] = useState(null);
    const [filteredPb, setFilteredPb] = useState(null);
    const [gageDefinitionsMap, setGageDefinitionsMap] = useState(null);
    const [areaList, setAreaList] = useState(null);

    const [insExItems, setInsExItems] = useState(null);

    // 儀器過濾 (種類)
    const [filterOptions, setFilterOptions] = useState(null);

    const [dtLeft, setDtLeft] = React.useState(location?.state?.dtLeft);
    const [dtRight, setDtRight] = React.useState(location?.state?.dtRight);

    const [instruments, setInstruments] = React.useState(location?.state?.pointIndices);
    const [area, setArea] = React.useState("");

    
    const nthOptions = [null, 50, 20, 10, 1];
    const nthDefaultIndex = 1;
    const [nth, setNth] = React.useState(nthOptions[nthDefaultIndex]);

    const [instrumentMode, setInstrumentMode] = React.useState('1');
    const radiosInstrumentMode = [
        { name: '單一', value: '1' },
        { name: '群組', value: '2' }
    ];

    const unsubscribe_currentProjectId = subscribe('project.currentProjectId', state => {
        // do something
        let id = state.project.currentProjectId;
        let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(id, state.project.joinedProjects);
        location.state = null;
        setInstruments(null);
        setArea(null);
        setSelectedProject(proj);
    });

    const tabulatorOptions = {
        pagination: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 50, 100, 250, true],
        responsiveLayout: false,
    };

    const [tabulatorColumns, setTabulatorColumns] = useState([]);
    const [tabulatorData, setTabulatorData] = useState([]);
    const updateTabulatorDataAndColumns = (data, localPbMap = null, localGageDef = null) => {
        if (data === null) {
            return;
        }
        if (data.length === 0) {
            setTabulatorColumns([]);
            setTabulatorData([]);
            return;
        }
        let k = Object.keys(data[0]).filter(k => k !== 'm' && k !== 'd' && k !== 'rownum');

        if (localPbMap === null) {
            localPbMap = pb;
        }

        if (localGageDef === null) {
            localGageDef = gageDefinitionsMap;
        }
        

        let aaa = k.map(key => {
            const pointInfo = localPbMap.get(Number(key));
            let headerTitle = pointInfo.pointNo;
            const keyGageTypeCode = Number(pointInfo.gageTypeCode);
            let gageDef = localGageDef.get(keyGageTypeCode);
            if (gageDef && gageDef.unitValue) {
                headerTitle += ` (${gageDef.unitValue})`;
            }

            return {
                title: headerTitle, //表格 header 儀器名稱
                field: key,
                formatter: (cell, formatterParams, onRendered) => {
                    const value = cell.getValue();
                    const valueNumber = Number(value);
                    if (isNaN(valueNumber)) {
                        return value;
                    }
                    return valueNumber.toFixed(2);
                }
            };
        });

        function datetimeToTimeOnlyStringConverter(value, data, type, component) {
            let f = value.toString();
            let s = f.substr(11, 8);
            return s;
            //return "fac";
        }

        function datetimeToDateOnlyStringConverter(value, data, type, component) {
            //return value.;
            let f = value.toString();
            let s = f.substr(0, 10);
            //return s;
            return "2025/1/1";
        }

        aaa.unshift({
            title: "時間", field: "dTime",
            mutator: function (value, data, type, params, component) {
                //value - original value of the cell
                //data - the data for the row
                //type - the type of mutation occurring  (data|edit)
                //params - the mutatorParams object from the column definition
                //component - when the "type" argument is "edit", this contains the cell component for the edited cell, otherwise it is the column component for the column
                if (!data.d) return "";
                let v = data.d.toString().substr(11,8);
                return v;
            },
            visible: false,
            download: true
        }); //prepend

        aaa.unshift({
            title: "日期", field: "dDate",
            mutator: function (value, data, type, params, component) {
                //value - original value of the cell
                //data - the data for the row
                //type - the type of mutation occurring  (data|edit)
                //params - the mutatorParams object from the column definition
                //component - when the "type" argument is "edit", this contains the cell component for the edited cell, otherwise it is the column component for the column
                if (!data.d) return "";
                let v = data.d.toString().substr(0, 10);
                return v;
            },
            visible: false,
            download: true
        }); //prepend
        
        aaa.unshift({
            title: "時間", field: "d", formatter: "datetime", formatterParams: {
                inputFormat: "yyyy-MM-dd'T'HH:mm:ss",
                outputFormat: "yy-MM-dd HH:mm:ss",
                invalidPlaceholder: true,
            },
            download: false
        }); //prepend
        aaa.unshift({ title: "MeaNo", field: "m", visible: false }); //prepend, but hide MeaNo from public
        setTabulatorColumns(aaa);
        setTabulatorData(data);
    };

    const data = [
        {
            id: 1,
            name: "Oli Bob",
            age: "12",
            color: "red",
            dob: "01/01/1980",
            rating: 5,
            passed: true,
            pets: ["cat", "dog"]
        },
        {
            id: 2,
            name: "Mary May",
            age: "1",
            color: "green",
            dob: "12/05/1989",
            rating: 4,
            passed: true,
            pets: ["cat"]
        },
        {
            id: 5,
            name: "Margret Marmajuke",
            age: "16",
            color: "yellow",
            dob: "07/01/1999",
            rating: 4,
            passed: false
        },
        {
            id: 6,
            name: "Van Ng",
            age: "37",
            color: "green",
            dob: "06/10/1982",
            rating: 4,
            passed: true,
            pets: ["dog", "fish"]
        },
        {
            id: 7,
            name: "Duc Ng",
            age: "37",
            color: "yellow",
            dob: "10/10/1982",
            rating: 4,
            passed: true,
            pets: ["dog"]
        }
    ];

    // RUN ONCE
    React.useEffect(() => {
        window.luxon = Luxon; //for tabulator
        window.XLSX = XLSX;
    }, []);

    React.useEffect(() => {
        //async function tryFillingTHData(arrPtIndice, dateLeft, dateRight) {
        //    let resTHData = await geoNow.api.project.getTimeHistory(token, selectedDatabaseName, arrPtIndice, dateLeft, dateRight);
        //    setTHData(resTHData);
        //    setChartOptions({
        //        responsive: true,
        //        //indexAxis: 'y',
        //        plugins: {
        //            legend: {
        //                position: 'top'
        //            },
        //            title: {
        //                display: true,
        //                text: "chartJs Test"
        //            },
        //            autocolors: false,
        //            annotation: {
        //                annotations: resTHData !== null && resTHData.chartJS !== null && resTHData.chartJS.annotations !== null ? resTHData.chartJS.annotations : null
        //            }
        //        }
        //    });
        //}

        /**@type Promise[] */
        let promises = [];
        const promisePbItems = geoNow.api.siteData.getParabase(token, selectedProject.projectId, err => {
            alert("getParabase" + JSON.stringify(err));
        });
        promises.push(promisePbItems);
        const promiseGageDefs = geoNow.api.siteData.getGageDefinition(token, [selectedProject.projectId], true, "zh-tw", err => {
            alert("getGageDefinition" + JSON.stringify(err));
        });
        promises.push(promiseGageDefs);
        const promiseAreas = geoNow.api.siteData.getAreas(token, selectedProject.projectId, err => {
            alert("getAreas" + JSON.stringify(err));
        });
        promises.push(promiseAreas);
        const promiseInsExItems = geoNow.api.instrumentExtends.listByProjectId(token, selectedProject.projectId, null, err => {
            alert("listByProjectId" + JSON.stringify(err));
        });
        promises.push(promiseInsExItems);
        Promise.all(promises).then(([resPb, resGageDef, resAreaList, resInsExItems]) => {

            let submitPayload = {
                gageDefinitionsMap: null,
                areaList: null,
                insExItems: null,
                pbMap: null
            };


            if (resPb.status === 1) {
                let items = resPb.message;
                // let map = new Map(items.map(item => [item.pointIdx, item]));

                let pbMap = new Map(items.map(item => [item.pointIdx, item]));
                setPb(pbMap);
                submitPayload.pbMap = pbMap;
            }
            if (resGageDef) {
                const mapGafeDef = new Map(resGageDef.items[0].map(el => [el.code, el]));
                setGageDefinitionsMap(mapGafeDef);
                submitPayload.gageDefinitionsMap = mapGafeDef;
            }
            if (resAreaList) {
                setAreaList(resAreaList);
                submitPayload.areaList = resAreaList;
            }
            if (resInsExItems.status === 1) {
                let items = resInsExItems.message;
                setInsExItems(items);
                submitPayload.insExItems = items;
            }


            // 接收從其他頁面跳轉過來的 State
            if (location.state && location.state.pointIndices && location.state.dtLeft && location.state.dtRight) {
                //setDtLeft(location.state.dtLeft);
                //setDtRight(location.state.dtRight);
                //setInstruments(location.state.pointIndice);
                handleSubmit(null, submitPayload);

            }
        });

        // tryFillingTHData();

        //if (rdx_project.timeHistoryQueryOptions) {
        //    if (rdx_project.timeHistoryQueryOptions.dtLeft)
        //        setDtLeft(rdx_project.timeHistoryQueryOptions.dtLeft);

        //    if (rdx_project.timeHistoryQueryOptions.dtRight)
        //        setDtRight(rdx_project.timeHistoryQueryOptions.dtRight);

        //    if (rdx_project.timeHistoryQueryOptions.instrumentMode)
        //        setInstrumentMode(rdx_project.timeHistoryQueryOptions.instrumentMode);

        //    if (rdx_project.timeHistoryQueryOptions.area)
        //        setArea(rdx_project.timeHistoryQueryOptions.area);

        //    if (rdx_project.timeHistoryQueryOptions.instruments)
        //        setInstruments(rdx_project.timeHistoryQueryOptions.instruments);

        //    handleSubmit(null);
        //}

        //tryFillingParabase();
        //tryFillingGageDefinition();
        //tryFillingAreaList();


    }, [selectedProject]);

    // Do it every time the queryOptions,  changes
    //React.useEffect(() => {

    //}, [thData, chartOptions]);

    React.useEffect(() => {
        if (loadingProp.isLoading) {
            Swal.fire({
                title: "讀取中...",
                //icon: "info",
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
            //Swal.showLoading();
        } else if (loadingProp.closeModal) {
            Swal.close();
            loadingProp.closeModal = false;
        }
    }, [loadingProp]);

    React.useEffect(() => {
        if (filterOptions === null) {
            let arrPb = [];
            if (pb !== null) {
                arrPb = Array.from(pb.values());
            }
            setFilteredPb(arrPb);
            return;
        }
        let tempPb = Array.from(pb.values());

        let g = filterOptions.gageFilter;
        if (g !== null && g !== "null") {
            tempPb = tempPb.filter(item => {
                let is = item.gageTypeCode == g;
                // console.log(item);
                return is;
            });
        }

        setFilteredPb(tempPb);
    }, [pb, filterOptions]);

    React.useEffect(() => {
        // alert(JSON.stringify(instruments));
    }, [instruments]);

    ///////////////////////////// Views Form Handler


    function handleGageFilterOnChange(filterValue) {
        setFilterOptions({
            ...filterOptions,
            gageFilter: filterValue
        })
    }

    function handleInstrumentsOnChange(e) {
        let instruments_string = e.target.value;
        if (instrumentMode === '2') {
            let index = e.nativeEvent.target.selectedIndex;
            let areaText = e.nativeEvent.target[index].text;
            setArea(areaText);
        }

        setInstruments(instruments_string.split(","));
    }

    const handleSubmit = async (e, payload = null) => {
        if (e) {
            e.preventDefault();
        }

        if (instrumentMode === "1" && (!instruments || instruments.length === 0)) {
            Swal.fire({
                title: "操作錯誤",
                text: "請選擇儀器。",
                icon: "error",
                showConfirmButton: true,
            });
            return;
        } else if (instrumentMode === "2" && !area) {
            Swal.fire({
                title: "操作錯誤",
                text: "請選擇區域。",
                icon: "error",
                showConfirmButton: true,
            });
            return;
        }

        if (!dtLeft || !dtRight) {
            Swal.fire({
                title: "操作錯誤",
                text: "請輸入日期區間。",
                icon: "error",
                showConfirmButton: true,
            });
            return;
        }

        // setIsLoading(true);
        setLoadingProp({
            isLoading: true,
            closeModal: false
        });

        let dtLeft_str = moment(dtLeft).format("YYYY-MM-DDTHH:mm:ss");
        let dtRight_str = moment(dtRight).format("YYYY-MM-DDTHH:mm:ss");

        let resTHData = await geoNow.api.siteData.getTimeHistory(token, selectedProject.projectId,
            instruments, dtLeft_str, dtRight_str, nth);

        if (!resTHData || resTHData == null) {
            setLoadingProp({
                isLoading: false,
                closeModal: false
            });
            Swal.fire({
                title: "失敗。",
                text: "無法取得回傳資料，連線失敗。",
                icon: "error",
                showConfirmButton: true,
            });
            return;
        }

        if (resTHData.status == -1) {
            setLoadingProp({
                isLoading: false,
                closeModal: false
            });
            Swal.fire({
                title: "失敗。",
                text: resTHData.message,
                icon: "error",
                showConfirmButton: true,
            });
        } else if (resTHData.status == 1) {
            //Ready to draw line (Auto Binding)

            setTHData(resTHData);
            setDtLeft(resTHData.dateTimeLeft);
            setDtRight(resTHData.dateTimeRight);

            if (resTHData.table) {
                updateTabulatorDataAndColumns(resTHData.table, payload?.pbMap, payload?.gageDefinitionsMap);
            }

            let titleText = "";
            if (instrumentMode === "1") {
                if (pb) {
                    /**@type Map<number, Object> */
                    let p = pb;
                    titleText = p.get(Number(instruments[0])).pointNo; //pb.find(x => x.pointIdx == instruments[0]).pointNo;
                }

            } else if (instrumentMode === "2") {
                titleText = area;
            }

            let newChartOptions = {
                ...chartOptions,
                plugins: {
                    ...chartOptions.plugins,
                    title: {
                        ...chartOptions.plugins.title,
                        text: titleText
                    },
                    annotation: {
                        annotations: resTHData !== null && resTHData.chartJS !== null && resTHData.chartJS.annotations !== null ? resTHData.chartJS.annotations : null
                    }
                }
            };

            let localInsExItems = insExItems;
            if (payload !== null) {
                localInsExItems = payload.insExItems;
            }


            let max = null;
            let min = null;
            if (localInsExItems !== null && localInsExItems.length > 0) {
                // Find MAX and MIN of insExItems[?].upperScale and insExItems[?].lowerScale
                for (let i = 0; i < localInsExItems.length; i++) {
                    let item = localInsExItems[i];

                    // let charPtIdx = isNaN(Number(item.pointIdx)) ? item.pointIdx : item.pointIdx.toString();
                    if (instruments.includes(item.pointIdx) || instruments.includes(item.pointIdx.toString())) {
                        if (item.upperScale !== null && item.upperScale !== undefined && item.upperScale !== "") {
                            if (max === null || max < insExItems[i].upperScale) {
                                max = localInsExItems[i].upperScale;
                            }
                        }

                        if (item.lowerScale !== null && item.lowerScale !== undefined && item.lowerScale !== "") {
                            if (min === null || min > insExItems[i].lowerScale) {
                                min = localInsExItems[i].lowerScale;
                            }
                        }
                    }
                }
            }
            if (max !== null || min !== null) {
                newChartOptions.scales.y = {
                    min: min,
                    max: max
                }
                toast.info(`管理員已設 Scale: ${min ?? '?'}~${max ?? "?"}。`, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                newChartOptions.scales.y = {};
            }

            setChartOptions(newChartOptions);
            setLoadingProp({
                isLoading: false,
                closeModal: true
            });
        }



    };

    ///////////////////////////// Views Rendering Functions

    function renderGageDropdownOptions() {
        if (gageDefinitionsMap) {
            let entries = Array.from(gageDefinitionsMap, ([k, v]) => (v));
            if (entries) {
                return entries.map(item => {
                    return (<option key={item.code} value={item.code}>{item.gageDescription}</option>);
                });
            }
        }
    }

    function renderInstrumentDropdownOptions() {
        if (filteredPb) {
            return filteredPb.map(item => {
                return (<option key={item.pointIdx} value={item.pointIdx}>{item.pointNo}</option>);
            });
        }
    }

    function renderAreaDropdownOptions() {
        if (areaList && areaList.items) {
            return areaList.items.map(item => {
                let pointIndice = [];

                let arrPb = Array.from(pb.values());
                pointIndice = arrPb.filter(x => x.area === item).map(x => x.pointIdx);
                let text = pointIndice.join(",");
                return (<option key={item} value={text}>{item}</option>);
            });
        }
    }

    class TestTable extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                selectedName: "",
                columns: []
            };
            this.ref = null;
        }

        //state = {
        //    data: [],
        //    selectedName: "",
        //    columns: []
        //};
        //ref = null;



        rowClick = (e, row) => {
            // console.log("ref table: ", this.ref.current); // this is the Tabulator table instance
            // console.log("rowClick id: ${row.getData().id}", row, e);
            this.setState({ selectedName: row.getData().name });
        };

        setData = () => {
            let tempCols = [];
            //let aaa = data.map(item => Object.keys(item).map(key => {
            //    return {
            //        title: key,
            //        field: key,
            //    };
            //}));
            //let vs = Array.from(data);
            let k = Object.keys(data[0]);
            let aaa = k.map(key => {
                return {
                    title: key,
                    field: key,
                };
            });

            this.setState({
                ...this.state, columns: aaa, data
            });
        };

        clearData = () => {
            this.setState({ data: [] });
        };

        render() {
            const options = {
                height: 150,
                movableRows: true,
                movableColumns: true
            };
            return (
                <div>
                    <ReactTabulator
                        onRef={(ref) => (this.ref = ref)}
                        columns={this.state.columns}
                        data={data}
                        events={{
                            rowClick: this.rowClick
                        }}
                        options={options}
                        data-custom-attr="test-custom-attribute"
                        className="custom-css-class"
                    />
                    <i>
                        Selected Name: <strong>{this.state.selectedName}</strong>
                    </i>

                    <h3>
                        Asynchronous data: (e.g. fetch) -{" "}
                        <button onClick={this.setData}>Set Data</button>
                        <button onClick={this.clearData}>Clear</button>
                    </h3>
                    <ReactTabulator columns={this.state.columns} autoColumns={true} data={this.state.data} />
                </div>
            );
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>GeoNow</Breadcrumb.Item>
                        <Breadcrumb.Item active>歷時資料</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>歷時資料</h4>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        {/*<ReactToPrint*/}
                        {/*    trigger={() => {*/}
                        {/*        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop*/}
                        {/*        // to the root node of the returned component as it will be overwritten.*/}
                        {/*        return (<Button variant="outline-primary" size="sm"*/}
                        {/*            disabled={thData === null}*/}
                        {/*        >列印圖表</Button>);*/}
                        {/*    }}*/}
                        {/*    content={() => chartCloneImgRef}*/}
                        {/*/>*/}
                        <Button variant="outline-primary" size="sm"
                            disabled={thData === null}
                            onClick={e => {
                                e.preventDefault();

                                const cht = chartContainer?.current;
                                if (cht === null) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `下載失敗。<br/>找不到圖表控制項。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }

                                const uri = cht.toBase64Image();
                                if (!uri || uri === null) {
                                    return;
                                }
                                chartCloneImgRef.src = uri;
                                handleChartPrint();
                            }}
                            >列印圖表</Button>
                        <Button
                            disabled={thData === null}
                            onClick={e => {
                                e.preventDefault();
                                if (!(chartContainer?.current)) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `下載失敗。<br/>找不到圖表控制項。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                const cht = chartContainer.current;
                                const uri = cht.toBase64Image();
                                let link = document.createElement("a");
                                link.download = "chart.png";
                                link.href = uri;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                //delete link;
                                
                            }}
                            variant="outline-primary" size="sm">下載圖表</Button>
                        <Button variant="outline-primary" size="sm"
                            disabled={thData === null || tabKey !== "table"}
                            onClick={e => {
                                e.preventDefault();
                                if (!(tableRef?.current)) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `列印失敗。<br/>找不到表格控制項。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                tableRef.current.print();
                            }}
                        >列印表格</Button>
                        <Button
                            disabled={thData === null || tabKey !== "table"}
                            onClick={e => {
                                e.preventDefault();
                                if (!(tableRef?.current)) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `下載失敗。<br/>找不到表格控制項。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                let csvFilename = "[" + (firstName ?? "") + (lastName ?? "") + "|" + (selectedProject?.projectName ?? (selectedProject?.databaseName ?? "")) + "] 歷時資料.xlsx";
                                //tableRef.current.download("xlsx", csvFilename, { delimiter: "," });
                                tableRef.current.download("xlsx", csvFilename, { sheetName: "MyData" });
                            }}
                            variant="outline-primary" size="sm">下載Excel</Button>
                    </ButtonGroup>
                </div>
            </div>
            <Form>
                <Row>
                    <Form.Group as={Col} xs="auto">
                        <Form.Label>選擇模式</Form.Label><br />
                        <ButtonGroup>
                            {radiosInstrumentMode.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    variant="secondary"
                                    name="radio"
                                    value={radio.value}
                                    checked={instrumentMode === radio.value}
                                    onChange={(e) => setInstrumentMode(e.currentTarget.value)}
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </Form.Group>
                    {instrumentMode == '1' && (
                        <>
                            <Form.Group as={Col}>
                                <Form.Label>儀器種類過濾</Form.Label>
                                <Form.Select onChange={e => handleGageFilterOnChange(e.target.value)}>
                                    <option key="null" value="null" selected="true">顯示所有儀器</option>
                                    {renderGageDropdownOptions()}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>儀器選擇</Form.Label>
                                <Form.Select onChange={e => handleInstrumentsOnChange(e)} value={instruments?.[0]}>
                                    <option>--</option>
                                    {renderInstrumentDropdownOptions()}
                                </Form.Select>
                            </Form.Group>
                        </>
                    )}
                    {instrumentMode == '2' && (
                        <Form.Group as={Col}>
                            <Form.Label>區域選擇</Form.Label>
                            <Form.Select onChange={e => handleInstrumentsOnChange(e)} value={instruments?.join(",")}>
                                <option>--</option>
                                {renderAreaDropdownOptions()}
                            </Form.Select>
                        </Form.Group>
                    )}

                </Row>
                <Row className="mt-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>起始時間</Form.Label>
                            <Datetime
                                locale={"zh-tw"}
                                timeFormat="YYYY-MM-DD HH:mm:ss"
                                closeOnSelect={false}
                                onChange={setDtLeft}
                                renderInput={(props, openCalendar) => (
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                        <Form.Control
                                            required
                                            type="text"
                                            value={dtLeft ? moment(dtLeft).format("YYYY-MM-DD HH:mm:ss") : ""}
                                            placeholder=""
                                            onFocus={openCalendar}
                                            onChange={() => { }} />
                                    </InputGroup>
                                )} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>結束時間</Form.Label>
                            <Datetime
                                locale={"zh-tw"}
                                timeFormat="YYYY-MM-DD HH:mm:ss"
                                closeOnSelect={false}
                                onChange={setDtRight}
                                renderInput={(props, openCalendar) => (
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                        <Form.Control
                                            required
                                            type="text"
                                            value={dtRight ? moment(dtRight).format("YYYY-MM-DD HH:mm:ss") : ""}
                                            placeholder=""
                                            onFocus={openCalendar}
                                            onChange={() => { }} />
                                    </InputGroup>
                                )} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>資料濃縮</Form.Label>
                            <Form.Select
                                onChange={e => {
                                    let v = e.target.value;
                                    if (!v || v == -1) {
                                        Swal.fire({
                                            title: "提醒你",
                                            text: "由於資料量龐大，若超過5分鐘沒反應將逾時中止。",
                                            icon: "warning",
                                            showConfirmButton: true,
                                        });
                                        setNth(null);
                                    } else {
                                        setNth(v);
                                    }
                                }}>
                                {nthOptions.map((item, idx) => {
                                    if (item === null || item === -1) {
                                        return (<option value={-1} selected={idx === nthDefaultIndex}>不濃縮</option>);
                                    }
                                    return (<option value={item} selected={idx===nthDefaultIndex}>{item}%</option>);
                                })}
                                {/*<option value={-1}>不濃縮</option>*/}
                                {/*<option value={50}>50%</option>*/}
                                {/*<option value={10} selected>10%</option>*/}
                                {/*<option value={1}>1%</option>*/}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs="auto">
                        <Form.Group>
                            <Form.Label>最終</Form.Label><br />
                            <Button variant="primary" onClick={e => {
                                handleSubmit(e, null);
                            }}>查詢</Button>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Tab.Container activeKey={tabKey} onSelect={k => setTabKey(k)}>
                            <Nav fill variant="pills" className="flex-column flex-sm-row">
                                <Nav.Item>
                                    <Nav.Link eventKey="chart" className="mb-sm-3 mb-md-0">
                                        <FontAwesomeIcon icon={faChartLine} /> 圖形
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="table" className="mb-sm-3 mb-md-0">
                                        <FontAwesomeIcon icon={faTable} /> 表格
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="chart">
                                    {thData !== null && (
                                        <>
                                            <Row>
                                                <Col>共計 {thData.originalRowCount} 筆資料{nth === null ? "。" : `，經 ${nth}% 壓縮後剩下 ${thData.table.length} 筆。`}<br/>
                                                查詢耗時 {Number(thData.sqlElapsedSeconds).toFixed(2)} 秒。</Col>
                                                <Col className="text-right">
                                                    <Button variant="light" onClick={() => {
                                                        if (chartContainer?.current) {
                                                            chartContainer.current.resetZoom();
                                                            return false;
                                                        }
                                                    }}>重設縮放</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Line options={chartOptions} data={thData.chartJS} ref={chartContainer} />

                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Tab.Pane>
                                <Tab.Pane eventKey="table">
                                    {thData !== null && (
                                        <Row>
                                            <Col>共計 {thData.originalRowCount} 筆資料{nth === null ? "。" : `，經 ${nth}% 壓縮後剩下 ${thData.table.length} 筆。`}<br/>
                                                查詢耗時 {Number(thData.sqlElapsedSeconds).toFixed(2)} 秒。</Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col>
                                            <div>
                                                <ReactTabulator
                                                    className="mt-3"
                                                    onRef={(r) => {
                                                        if (!r) {
                                                            return;
                                                        }
                                                        //tableRef = r;
                                                        tableRef.current = r.current;
                                                    }}
                                                    data={tabulatorData}
                                                    options={tabulatorOptions}
                                                    columns={tabulatorColumns}
                                                    layout={"fitData"}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
                {
                    thData === null && (
                        <Alert variant="info">
                            在<Link to={Routes.DataOverview.path} className="fw-bold">
                                {`【資料一覽】`}
                            </Link>頁面點選表格橫列，可以將資料帶入歷時資料，省去時間指定。
                        </Alert>
                    )
                }
                {/*
                 * Workaround: ChartJS 本身不支援列印，所以這邊放了一張隱藏圖片，
                 * 每當使用者點「列印圖表」時，就會產生 dataUrl (base64) 的 img，
                 * 然後利用 react-to-print 去列印指定元件。
                 * */}
                <div className="d-none d-print-block">
                    <img ref={el => (chartCloneImgRef = el)} />
                </div>


            </Form>


        </>
    );


}