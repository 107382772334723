import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { faCheck, faCog, faChartLine, faTable, faHome, faSearch, faCalendarAlt, faFileExcel, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Alert, AlertProps, Breadcrumb, Tab, Nav, InputGroup, ToggleButton, Dropdown, Table } from '@themesberg/react-bootstrap';

/*import { TransactionsTable } from "../../components/Tables";*/
import geoNow from '../../utils/geonow';
import { useSelector } from 'react-redux';

import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.css';

//import { moment } from 'moment';
import Datetime from "react-datetime";
import moment from "moment-timezone";
import 'moment/locale/zh-tw';

import {
    Chart,
    Chart as ChartJS,
    registerables
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

import ReactSelect from 'react-select';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { subscribe } from 'redux-subscriber';
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";

import * as XLSX from 'xlsx';
import { Tabulator } from "react-tabulator/lib/types/TabulatorTypes";

ChartJS.register(
    ...registerables,
    annotationPlugin,
    zoomPlugin
);

export default () => {

    const location = useLocation();
    const chartContainer = React.useRef(null);
    const tableRef = useRef(null);

    const rdx_project = useSelector(state => state.project);
    const rdx_user = useSelector(state => state.user);
    const lastName = rdx_user.lastName;
    const firstName = rdx_user.firstName;
    const token = rdx_user.token;

    //let selectedProject = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(rdx_project.currentProjectId, rdx_project.joinedProjects);

    const [selectedProject, setSelectedProject] = useState(
        geoNow.stateHelper.project.getJoinedProjectItemByProjectId(rdx_project.currentProjectId, rdx_project.joinedProjects)
    );
    // let selectedDatabaseName = selectedProject.databaseName;

    const unsubscribe_currentProjectId = subscribe('project.currentProjectId', state => {
        // do something
        let id = state.project.currentProjectId;
        let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(id, state.project.joinedProjects);

        setSelectedProject(proj);
    });

    const [tabKey, setTabKey] = useState('chart');
    
    const tabulatorOptions = {
        pagination: true,
        paginationSize: 30,
        paginationSizeSelector: [30, 50, 100, 250, true],
        responsiveLayout: false,
    };

    const [tabulatorColumns, setTabulatorColumns] = useState([]);
    const [tabulatorData, setTabulatorData] = useState([]);
    const updateTabulatorDataAndColumns = (data) => {
        if (data?.chartJs?.datasets == null) {
            return;
        }
        if (data.chartJs.datasets.length === 0) {
            setTabulatorColumns([]);
            setTabulatorData([]);
            return;
        }
        /**@type Object[] */
        const ds = data.chartJs.datasets;
        let k = ds.map(x => x.label); //.filter(k => );
        
        let aaa = k.map((key, idx) => {
            /**@type string */
            let titleText = key;
            const idxOfLastAt = titleText.lastIndexOf("@");
            const seriesName = titleText.substring(0, idxOfLastAt);
            const meaNo = titleText.substring(idxOfLastAt + 1); //Right
            // titleText = `${seriesName}&${meaNo}`;
            const timeItem = timeList.filter(x => x.meaNo == meaNo)[0];
            if (timeItem) {
                const dtStr = moment(timeItem.date).format('YYYYMMDD HH:mm:ss');
                titleText = `${seriesName}@${dtStr}`;
            }

            //console.log(JSON.stringify(timeList));

            return {
                title: titleText,
                field: "v" + idx,
                formatter: (cell, formatterParams, onRendered) => {
                    const value = cell.getValue();
                    const valueNumber = Number(value);
                    if (isNaN(valueNumber)) {
                        return value;
                    }
                    return valueNumber.toFixed(2);
                }
            };
        });
        aaa.unshift({ title: "深度", field: "depth"}); //prepend
        
        setTabulatorColumns(aaa);

        let finalTabulatorData = [];
        
        for (let i = 0; i < data.chartJs.labels.length; i++) {
            let dd = {};
            dd["depth"] = data.chartJs.labels[i];
            for (let j = 0; j < ds.length; j++) {
                dd["v" + j] = ds[j].data[i];
            }
            finalTabulatorData.push(dd);
        }

        setTabulatorData(finalTabulatorData);
    };

    /**
     * @typedef {Map<string, Object>} seriesDefinition — 
     * @description doc
     */
    /**
     * @typedef {Function} setSeriesDefinition — 
     */
    /**
     * @type {[seriesDefinition, setSeriesDefinition]} Loading
     */
    const [seriesDefinition, setSeriesDefinition] = React.useState(null);
    const [timeList, setTimeList] = React.useState([]);
    const [availableSeriesOptions, setAvailableSeriesOptions] = React.useState([]);

    // const [filteredTimeList, filteredTimeList] = React.useState([]);
    // const [meaNo, setMeaNo] = React.useState(null);

    const meaNoSelectRef = React.useRef(null);
    const seriesSelectRef = React.useRef(null);


    

    const getSelectedMeaNoList = () => {
        if (!meaNoSelectRef?.current) {
            return [];
        }
        return meaNoSelectRef.current.getValue().map(x => x.value);
    }
    const getSelectedMeaNoToLabelMap = () => {
        if (!meaNoSelectRef?.current) {
            return [];
        }
        return new Map(meaNoSelectRef.current.getValue().map(x => [x.value, x.label]));
    }
    const getSelectedSeriesId = () => {
        if (!seriesSelectRef?.current) {
            return [];
        }
        let v = seriesSelectRef.current.getValue();
        if (!v || !v[0]) {
            return [];
        }
        return v[0].value;
    }


    const [hiddenAlerts, setHiddenAlerts] = React.useState([]);

    const onAlertClose = (alertId) => {
        const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
        setHiddenAlerts(hiddenAlertsUpdated);
    };

    const shouldShowAlert = (alertId) => (
        hiddenAlerts.indexOf(alertId) === -1
    );
    const tips = [
        `操作電腦時，圖表上按住 Ctrl + 滑鼠左鍵可以平移。`,
        `對圖表點滑鼠右鍵，或是觸控長按呼叫選單，可以另存圖片。`,
        `在本頁面只能設定暫時性的 Scale，若要儲存設定，請進入設定頁面調整。`
    ];
    const [viewingTipIdx, setViewingTipIdx] = React.useState(Math.floor(Math.random() * tips.length));

    const [loadingProp, setLoadingProp] = useState({
        isLoading: false,
        closeModal: false
    });
    React.useEffect(() => {
        if (loadingProp.isLoading) {
            Swal.fire({
                title: "讀取中...",
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
        } else if (loadingProp.closeModal) {
            Swal.close();
            loadingProp.closeModal = false;
        }
    }, [loadingProp]);

    const [dsData, setDsData] = React.useState(null);
    // const [seriesId, setSeriesId] = React.useState(null);

    const tbUpperScaleRef = React.useRef();
    const tbLowerScaleRef = React.useRef();

    const [chartOptions, setChartOptions] = React.useState({
        responsive: true,
        indexAxis: 'y',
        scales: {
            x: {
                position: "top",
                //max: Math.abs(Math.max(dsData.chartJs.datasets)),
                //min: Math.abs(Math.max(dsData.chartJs.datasets)) * -1
                title: {
                    display: true,
                    text: "累積變位量 (mm)"
                }
            },
            y: {
                title: {
                    display: true,
                    text: "深度 (m)"
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    generateLabels: (chart) => {
                        //let ds = chart.data.datasets[0];
                        // let color = ds.backgroundColor[ds.backgroundColor.length - 1];
                        //return [{
                        //    datasetIndex: 0,
                        //    text: ds.label,
                        //    //fillStyle: color,
                        //    //strokeStyle: color
                        //}];

                        let labels = chart.data.datasets;

                        for (let key in labels) {
                            /**@type {string} */
                            const oriText = labels[key].label;
                            let newText = oriText;

                            const idxOfAt = oriText.lastIndexOf("@");

                            if (idxOfAt > 0) {
                                const selectedMeaNoToLabelMap = getSelectedMeaNoToLabelMap();
                                if (selectedMeaNoToLabelMap.size > 0) {
                                    const meaNo = Number(oriText.substring(idxOfAt + 1));
                                    const meaNo_Label = selectedMeaNoToLabelMap.get(meaNo);

                                    const textBeforeAt = oriText.substring(0, idxOfAt).trimEnd();
                                    newText = `${textBeforeAt} @${meaNo_Label}`;
                                }
                            }

                            labels[key].text = newText;
                            labels[key].fillStyle = labels[key].backgroundColor;
                            labels[key].strokeStyle = labels[key].borderColor;
                            //labels[key].fillStyle = labels[key].fillStyle;//"rgba(133, 4, 12, 0.7)";

                        }
                        return labels;
                    }
                }
            },
            annotation: {
                annotations: {
                    zeroLine: {
                        type: 'line',
                        xMin: 0,
                        xMax: 0,
                        borderColor: '#444',
                        borderWidth: 2
                    }
                }
            },
            //tooltip: {
            //    callbacks: {
            //        label: item => {
            //            return `${item.dataset.label}: ${item.formattedValue}`;
            //        }
            //    }
            //},

            zoom: {
                pan: {
                    enabled: true,
                    mode: 'xy',
                    modifierKey: 'ctrl',
                },
                zoom: {
                    mode: 'xy',
                    drag: {
                        enabled: true,
                        borderColor: 'rgb(54, 162, 235)',
                        borderWidth: 1,
                        backgroundColor: 'rgba(54, 162, 235, 0.3)'
                    }
                }
            }
        }
    });

    async function fetchAllData() {
        var res = await geoNow.api.siteData.listDscParaBaseExItemsByProjectId(token, selectedProject.projectId);
        if (res?.status == 1) {

            const mapSeriesDef = new Map(res.message.map(el => [el.seriesInfo.id, el]));
            setSeriesDefinition(mapSeriesDef);
        }
    }
    async function fetchAvailableTime() {
        var res = await geoNow.api.siteData.listAvailableTimeListDataByProjectId(token, selectedProject.projectId);
        if (res?.status == 1) {
            setTimeList(res.message);
        }
    }

    // Run once    
    React.useEffect(() => {
        window.XLSX = XLSX;
    }, []);

    React.useEffect(() => {

        if (meaNoSelectRef.current) {
            meaNoSelectRef.current.clearValue();
        }
        if (seriesSelectRef.current) {
            seriesSelectRef.current.clearValue();
        }

        /**@type Promise[] */
        let promises = [];
        let promiseDscPbExItems = geoNow.api.siteData.listDscParaBaseExItemsByProjectId(token, selectedProject.projectId, err => {
            console.log("!");
        });
        promises.push(promiseDscPbExItems);
        let promiseTimeList = geoNow.api.siteData.listAvailableTimeListDataByProjectId(token, selectedProject.projectId, err => {
            console.log("!");
        });
        promises.push(promiseTimeList);
        setLoadingProp({
            isLoading: true,
            closeModal: false
        });
        Promise.all(promises).then(([resDscPbExItems, resTimeList]) => {

            if (resDscPbExItems.status === 1) {
                const mapSeriesDef = new Map(resDscPbExItems.message.map(el => [el.seriesInfo.id, el]));
                setSeriesDefinition(mapSeriesDef);
            }
            if (resTimeList.status === 1) {
                setTimeList(resTimeList.message);
            }

            setLoadingProp({
                isLoading: false,
                closeModal: true
            });
        });

        setDsData(null);
        setTabulatorData(null);
        //fetchAllData();
        //fetchAvailableTime();
    }, [selectedProject]);

    React.useEffect(() => {
        console.log(seriesDefinition);
        if (seriesDefinition) {
            // let arrYears;
            let entries = Array.from(seriesDefinition, ([k, v]) => (v));
            if (entries) {
                const opts = entries.filter(x => x.points && x.points.length > 0).map(item => {
                    return {
                        label: item.seriesInfo.name,
                        value: item.seriesInfo.id,
                    };
                });
                setAvailableSeriesOptions(opts);
            }
        }
    }, [seriesDefinition]);

    //React.useEffect(() => {

    //}, [meaNo]);

    //function handleMeaNoOnChange(meaNo) {
    //    setMeaNo(meaNo);
    //}

    //function handleSeriesIdOnChange(seriesGuid) {
    //    setSeriesId(seriesGuid);
    //}

    function setChartDisplayScale(min, max, onScaleSupplied) {
        let newChartOptions = chartOptions;

        if (max !== null || min !== null) {
            newChartOptions.scales.x = {
                ...newChartOptions.scales.x,
                min: min,
                max: max
            }
            if (onScaleSupplied) {
                onScaleSupplied(min, max);
            }
        } else {
            delete newChartOptions.scales.x.max;
            delete newChartOptions.scales.x.min;
        }
        const __tbLower = tbLowerScaleRef.current;
        const __tbUpper = tbUpperScaleRef.current;

        if (__tbUpper) {
            __tbUpper.value = max;
        }

        if (__tbLower) {
            __tbLower.value = min;
        }

        setChartOptions(newChartOptions);
    }

    async function handleSubmit(e) {
        if (e) {
            e.preventDefault();
        }

        if (!meaNoSelectRef?.current) {
            return;
        }

        const selectedMeaNoList = getSelectedMeaNoList();
        const selectedSeriesId = getSelectedSeriesId();

        if (selectedMeaNoList.length === 0) {
            Swal.fire({
                title: "操作錯誤",
                text: "請選擇日期與時間。",
                icon: "error",
                showConfirmButton: true,
            });
            return;
        }
        if (selectedSeriesId.length === 0) {
            Swal.fire({
                title: "操作錯誤",
                text: "請選擇串列。",
                icon: "error",
                showConfirmButton: true,
            });
            return;
        }

        setLoadingProp({
            isLoading: true,
            closeModal: false
        });
        const res = await geoNow.api.siteData.getDepthSerieMeaNoValueEntries(token, selectedProject.projectId, selectedSeriesId, selectedMeaNoList);
        setLoadingProp({
            isLoading: false,
            closeModal: true
        });

        const dsc = seriesDefinition.get(selectedSeriesId)?.seriesInfo;
        const max = dsc?.upperScale;
        const min = dsc?.lowerScale;
        
        setChartDisplayScale(min, max, (pMin, pMax) => {
            toast.info(`管理員已設 Scale: ${pMin ?? '?'}~${pMax ?? "?"}。`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        });

        if (res.status == 1) {
            setDsData(res.message);
            updateTabulatorDataAndColumns(res.message);
        }
        // geoNow.api.siteData.meaNoValueEntries(token, selectedProject.projectId, meaNo, )
    }

    function renderAvailableTimeOptions() {
        if (timeList) {
            // let arrYears;
            return timeList.map(item => {
                let date = moment(item.date);
                // arrYears.push(date.year());
                return (<option value={item.meaNo}>{date.format("YYYY-MM-DD HH:mm:ss")}</option>);
            });

        }
    }

    function renderAvailableSeriesOptions() {
        if (seriesDefinition) {
            // let arrYears;
            let entries = Array.from(seriesDefinition, ([k, v]) => (v));
            if (entries) {
                return entries.map(item => {
                    if (!item.points || item.points.length == 0) {
                        return;
                    }
                    // let pointIndicesConcat = item.points.map(x => x.pointIdx).join(",");
                    return (<option value={item.seriesInfo.id}>{item.seriesInfo.name}</option>);
                });
            }
        }
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>GeoNow</Breadcrumb.Item>
                        <Breadcrumb.Item active>深度測量</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>深度測量</h4>
                    {(selectedProject.projectDepthSeries_Title ?? "") !== "" && (
                        <h5>{selectedProject.projectDepthSeries_Title}</h5>
                    )}
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button variant="outline-primary" size="sm"
                            disabled={tabKey !== "table" || tabulatorData == null}
                            onClick={e => {
                                e.preventDefault();
                                if (!(tableRef?.current)) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `列印失敗。<br/>找不到表格控制項。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                tableRef.current.print();
                                // tableRef.current.print();
                            }}
                        ><FontAwesomeIcon icon={faPrint} /> 列印</Button>
                        <Button
                            disabled={tabKey !== "table" || tabulatorData == null}
                            onClick={e => {
                                e.preventDefault();
                                if (!(tableRef?.current)) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `下載失敗。<br/>找不到表格控制項。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                setTabKey("table");
                                let csvFilename = "[" + (lastName ?? "") + (firstName ?? "") + "|" + (selectedProject?.name ?? (selectedProject?.databaseName ?? "")) + "] depthSeries.xlsx";
                                tableRef.current.download("xlsx", csvFilename, { sheetName: "DepthSeries" });
                            }}
                            variant="outline-primary" size="sm"><FontAwesomeIcon icon={faFileExcel} /> 下載</Button>
                        <Button
                            
                            onClick={e => {
                                e.preventDefault();
                                const l_pid = selectedProject.projectId;
                                const l_sid = getSelectedSeriesId();
                                const larr_mid = getSelectedMeaNoList();
                                if (!l_sid) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `下載失敗。<br/>尚未選擇串列。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                if (!larr_mid || larr_mid.length == 0) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `下載失敗。<br/>尚未選擇時間點。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                geoNow.api.siteData.getDepthSeriesDownloadStandardExcel(token, l_pid, getSelectedSeriesId(), getSelectedMeaNoList(), (msg) => { alert(msg);  })
                            }}
                            variant="outline-primary" size="sm"><FontAwesomeIcon icon={faFileExcel} /> 下載橫式</Button>
                    </ButtonGroup>
                </div>
            </div>
            <div className="d-block">
                {timeList.length === 0 && (
                    <Alert variant="danger">
                        沒有時間可供選擇！建議切換專案。<br />可能遇到資料庫重置，或是 GeoAuto Para 配置錯誤。
                    </Alert>
                )}
                {availableSeriesOptions.length === 0 && (
                    <Alert variant="warning">
                        沒有串列可供選擇！建議切換專案。<br />可能是本專案沒有相關儀器，或是尚未設定串列配對。
                    </Alert>
                )}
            </div>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>可選時間</Form.Label>
                        {/*<Form.Select onChange={e => handleMeaNoOnChange(e.target.value)}>*/}
                        {/*    <option key="null" value="null" selected="true">--</option>*/}
                        {/*    {renderAvailableTimeOptions()}*/}
                        {/*</Form.Select>*/}
                        <ReactSelect
                            ref={meaNoSelectRef}
                            options={timeList.map(item => {
                                let date = moment(item.date);
                                // arrYears.push(date.year());
                                return { label: date.format("YYYY-MM-DD HH:mm:ss"), value: item.meaNo };
                            })}
                            isMulti
                            closeMenuOnSelect={false}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />

                    </Form.Group>
                    {/*<Form.Group as={Col}>*/}
                    {/*    <Form.Label>時間範圍</Form.Label><br />*/}
                    {/*    <Button className="me-1" variant="info" type="submit">半年</Button>*/}
                    {/*</Form.Group>*/}
                    <Form.Group as={Col} xs="12" sm="auto">
                        <Form.Label>串列選擇</Form.Label>
                        {/*<Form.Select onChange={e => handleSeriesIdOnChange(e.target.value)}>*/}
                        {/*    <option key="null" value="null" selected="true">--</option>*/}
                        {/*    {renderAvailableSeriesOptions()}*/}
                        {/*</Form.Select>*/}
                        <ReactSelect
                            ref={seriesSelectRef}
                            options={availableSeriesOptions}
                            closeMenuOnSelect={true}
                            className="basic-single"
                            classNamePrefix="select"
                        />
                    </Form.Group>
                    <Col xs="12" sm="auto">
                        <Form.Group>
                            <Form.Label>最終</Form.Label><br />
                            <Button className="me-1" variant="primary" type="submit">查詢</Button>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Tab.Container activeKey={tabKey} onSelect={k=> setTabKey(k)} /*defaultActiveKey="chart"*/>
                            <Nav fill variant="pills" className="flex-column flex-sm-row">
                                <Nav.Item>
                                    <Nav.Link eventKey="chart" className="mb-sm-3 mb-md-0">
                                        <FontAwesomeIcon icon={faChartLine} /> 圖形
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="table" className="mb-sm-3 mb-md-0">
                                        <FontAwesomeIcon icon={faTable} /> 表格
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="chart">
                                    {dsData && dsData !== null && (
                                        <>
                                            <Row className="mb-2">
                                                <Col xs="6" sm="auto">
                                                    <Form.Group>
                                                        <Form.Label>顯示下限</Form.Label>
                                                        <Form.Control ref={tbLowerScaleRef} type="numeric" placeholder="" defaultValue={chartOptions.scales?.x?.min ?? ""} />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="6" sm="auto">
                                                    <Form.Group>
                                                        <Form.Label>顯示上限</Form.Label>
                                                        <Form.Control ref={tbUpperScaleRef} type="numeric" placeholder="" defaultValue={chartOptions.scales?.x?.max ?? ""} />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs="12" sm="auto">
                                                    <Form.Group>
                                                        <Form.Label>顯示調整</Form.Label><br />
                                                        <Button variant="secondary"
                                                            className="me-1"
                                                            onClick={e => {
                                                                e.preventDefault();

                                                                /**@type Chart */
                                                                const chart = chartContainer?.current;
                                                                if (!chart) {
                                                                    alert("No chart!");
                                                                    return;
                                                                }

                                                                const __tbLower = tbLowerScaleRef.current;
                                                                const __tbUpper = tbUpperScaleRef.current;
                                                                let max = null;
                                                                let min = null;
                                                                if (__tbUpper) {
                                                                    max = __tbUpper.value.trim();
                                                                }
                                                                if (__tbLower) {
                                                                    min = __tbLower.value.trim();
                                                                }
                                                                if (max === "") max = null;
                                                                if (min === "") min = null;
                                                                if (min !== null) {
                                                                    min = Number(min);
                                                                }
                                                                if (max !== null) {
                                                                    max = Number(max);
                                                                }

                                                                if (min !== null && max !== null) {
                                                                    if (min >= max) {
                                                                        alert("MAX 必須大於 MIN");
                                                                        return;
                                                                    }
                                                                }
                                                                setChartDisplayScale(min, max, (pMin, pMax) => {
                                                                    toast.info(`已設暫時 Scale: ${pMin ?? '?'}~${pMax ?? "?"}。`, {
                                                                        position: "bottom-right",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                    });
                                                                });
                                                                chart.update();

                                                            }}>套用</Button>
                                                        <Button variant="light"
                                                            className="me-1"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                if (chartContainer?.current) {
                                                                    chartContainer.current.resetZoom();
                                                                }
                                                            }}>重設縮放</Button>
                                                        <Button variant="light"
                                                            className="me-1"
                                                            onClick={e => {
                                                                e.preventDefault();

                                                                if (!chartContainer?.current) {
                                                                    alert("error!");
                                                                    return;
                                                                }
                                                                /**@type {Chart}*/
                                                                const chart = chartContainer.current;


                                                                const finalState = !chartOptions.responsive;
                                                                if (!finalState) {
                                                                    chart.resize(220, 620);
                                                                    setChartOptions({
                                                                        ...chartOptions,
                                                                        responsive: finalState
                                                                    });
                                                                    chart.update();
                                                                } else {
                                                                    setChartOptions({
                                                                        ...chartOptions,
                                                                        responsive: finalState
                                                                    });
                                                                    chart.update();
                                                                }
                                                                //if (chartContainer?.current) {
                                                                //    /**@type {Chart}*/
                                                                //    const chart = chartContainer.current;

                                                                //}
                                                            }}>{chartOptions.responsive ? "縮小檢視" : "填滿頁面"}</Button>
                                                    </Form.Group>
                                                </Col>


                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Alert
                                                        className="bt-2"
                                                        variant="light"
                                                        show={shouldShowAlert("chartTip")}
                                                        onClose={() => onAlertClose("light")}>

                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <FontAwesomeIcon icon={faLightbulb} className="me-1" /><b>提示: </b>{tips[viewingTipIdx]}
                                                                <a href="javascript:void(0)" onClick={e => {
                                                                    if (viewingTipIdx >= tips.length - 1) {
                                                                        setViewingTipIdx(0);
                                                                        return;
                                                                    }
                                                                    setViewingTipIdx(viewingTipIdx + 1);
                                                                }}>看下一個提示</a>
                                                            </div>
                                                            <Button variant="close" size="xs" onClick={() => onAlertClose("chartTip")} />
                                                        </div>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Line options={chartOptions} data={dsData.chartJs} ref={chartContainer} height="220" />
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Tab.Pane>
                                <Tab.Pane eventKey="table">
                                    <Row>
                                        <Col>
                                            <div>
                                                <ReactTabulator
                                                    className="mt-3"
                                                    onRef={(r) => {
                                                        if (!r) {
                                                            return;
                                                        }
                                                        //tableRef = r;
                                                        tableRef.current = r.current;
                                                    }}
                                                    data={tabulatorData}
                                                    options={tabulatorOptions}
                                                    columns={tabulatorColumns}
                                                    layout={"fitData"}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
                
            </Form>

        </>
    );


}