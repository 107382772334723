
import React, { useState, useRef, useCallback, useEffect, memo } from "react";
import { useLocation } from 'react-router-dom';


import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, FormControl, Button, InputGroup, Alert, ListGroup } from '@themesberg/react-bootstrap';


import { ReactTabulator, reactFormatter } from 'react-tabulator';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.css';

import moment from "moment-timezone";
import 'moment/locale/zh-tw';

// import scssVars from "../scss/volt/_variables.scss";

import geoNow from '../utils/geonow';
import { Tabulator } from "react-tabulator/lib/types/TabulatorTypes";
import Identicon from 'react-identicons';
import { renderToString } from 'react-dom/server';

import Swal from 'sweetalert2';

export const InsEx = ({ token, selectedProject, preData, depthSeriesConfigs, pbItems, onSavedChanges }) => {

    const location = useLocation();
    const tableRef = React.useRef(null);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [data, setData] = useState(preData);
    const [editedData, setEditedData] = useState(new Map());
    const [addedData, setAddedData] = useState([]);

    const [userTickedExistedItemsGuid, setUserTickedExistedItemsGuid] = useState(new Set());
    const [userTickedAddingItemsId, setUserTickedAddingItemsId] = useState(new Set());
    const [userAddRowCount, setUserAddRowCount] = useState(0);

    //const [isUserTickAnyItems, setIsUserTickAnyItems] = useState(false);

    const canEdit = selectedProject.isProjectAdmin;

    const tabulatorOptions = {
        layout: "fitData",
        //movableRows: true,
        movableColumns: true,
        resizableColumns: true,
        pagination: "local",
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40, 50, true],
    };

    const TabulatorIdenticon = (props) => {
        const row = props.cell._cell.row;
        const rowData = row.data;
        const cellValue = props.cell._cell.value;
        const id = rowData.id;
        if (!id || id === "") {
            // row.getElement().style.backgroundColor = "yellow";
            return (<FontAwesomeIcon icon={faPlus} color={'red'} />);
        }
        return (<Identicon string={id} size={20} title={id}></Identicon>);
    };

    const TabulatorUserTickCheckbox = (props) => {
        const row = props.cell._cell.row;
        const rowData = row.data;
        const cellValue = props.cell._cell.value;
        const id = rowData.id;
        const localId = rowData.localId;

        if (!id || id === "") {
            //return (<></>);
            //return (<Button onClick={e => {
            //    e.preventDefault();
            //    alert(rowData.localId);
            //}}>X</Button>);
            //return (<FontAwesomeIcon icon={faPlus} color={'red'}/>);
            return (
                <Form.Check id={"cbAdding_" + localId} defaultChecked={userTickedAddingItemsId.has(localId)} onChange={e => {
                    let v = e.target.checked;
                    if (v) {
                        let set = userTickedAddingItemsId;
                        set.add(localId);
                        setUserTickedAddingItemsId(set);

                        console.log("O: " + localId);
                        console.log(set);
                    } else {
                        let set = userTickedAddingItemsId;
                        set.delete(localId);
                        setUserTickedAddingItemsId(set);

                        console.log("X: " + localId);
                        console.log(set);
                    }
                    // setIsUserTickAnyItems(userTickedExistedItemsGuid.size > 0 || set.size > 0);
                }} />
            );
        }
        return (
            <Form.Check id={"cbExisted_" + id} defaultChecked={userTickedExistedItemsGuid.has(id)} onChange={e => {
                let v = e.target.checked;
                let set = userTickedExistedItemsGuid;
                if (v) {
                    set.add(id);
                    setUserTickedExistedItemsGuid(set);

                    console.log("O: " + localId);
                    console.log(set);
                } else {
                    set.delete(id);
                    setUserTickedExistedItemsGuid(set);

                    console.log("X: " + id);
                    console.log(set);
                }
                // setIsUserTickAnyItems(userTickedAddingItemsId.size > 0 || set.size > 0);
            }} />
        );
    };

    const tabulatorColumns = [

        {
            title: "", visible: true && canEdit, field: "usertick", responsive: 0, sorter: null, hozAlign: "center", tooltip: false, formatter: reactFormatter(<TabulatorUserTickCheckbox />),
        },
        { title: "#", field: "id", responsive: 0, sorter: "string", hozAlign: "center", tooltip: true, formatter: reactFormatter(<TabulatorIdenticon />) },
        { title: "專案ID", visible: false, field: "projectId", responsive: 0, sorter: "string", hozAlign: "center", tooltip: true },
        { title: "點號", field: "pointIdx", responsive: 0, sorter: "number", editor: true && canEdit, validator: ["integer", "min:0"] },
        {
            title: "點名", responsive: 0, sorter: "string",
            /**
             * 
             * @param {Tabulator.CellComponent} cell
             * @param {Tabulator.FormatterParams} formatterParams
             * @param {Object} onRendered
             */
            formatter: (cell, formatterParams, onRendered) => {
                let el = cell.getElement();
                el.style.backgroundColor = "lightgrey";
                if (!pbItems || pbItems.length === 0) {
                    return "";
                }
                let rowData = cell.getRow().getData();
                let ptIdx = Number(rowData.pointIdx);
                if (!ptIdx) {
                    return "";
                }

                let name = pbItems.get(ptIdx)?.pointNo;
                if (!name || name === "") {
                    el.style.color = "red";
                    return "不存在";
                }
                el.style.color = null;
                return name;
            }
        },
        { title: "別名", field: "nickname", responsive: 0, tooltip: true, responsive: 0, sorter: "string", editor: true && canEdit },
        { title: "下限", field: "lowerScale", responsive: 0, sorter: "number", editor: true && canEdit, validator: ["numeric"] },
        { title: "上限", field: "upperScale", responsive: 0, sorter: "number", editor: true && canEdit, validator: ["numeric"] },

        { title: "鎖死狀態訊息", field: "statusStaticNote", responsive: 0, tooltip: true, responsive: 0, sorter: "string", editor: true && canEdit},
        { title: "隱藏", field: "hidden", responsive: 0, sorter: "boolean", hozAlign: "center", formatter: "tickCross", editor: true && canEdit },
        { title: "經度", field: "longtitude", responsive: 0, sorter: "number", editor: true && canEdit, validator: ["numeric"] },
        { title: "緯度", field: "latitude", responsive: 0, sorter: "number", editor: true && canEdit, validator: ["numeric"] },
        { title: "海拔", field: "height", responsive: 0, sorter: "number", editor: true && canEdit, validator: ["numeric"] },
        {
            title: "深度串列", field: "depthSeriesConfigId", responsive: 0, sorter: "string", hozAlign: "center", tooltip: true,
            formatter: (cell, formatterParams, onRendered) => {
                if (canEdit) {
                    cell.getElement().style.color = "blue";
                }
                let value = cell.getValue();
                if (!value) {
                    return "";
                }
                if (!depthSeriesConfigs || depthSeriesConfigs.length === 0) {
                    return value;
                }
                let config = depthSeriesConfigs.find(c => c.id === value);
                if (!config) {
                    return value;
                }
                return config.name;
            },
            /**
             * @type {Tabulator.CellEventCallback}
             * @param {UIEvent} e
             * @param {Tabulator.CellComponent} cell
             */
            cellClick: (e, cell) => {
                if (!canEdit) {
                    return;
                }
                let formattedCellText = cell.getElement().innerText;
                let v = cell.getValue();
                let hasValue = v && v !== null;

                /**@type Object[] */
                let dscs = depthSeriesConfigs;
                let opts = new Map(dscs.map(x => [x.id, x.name]));
                Swal.fire({
                    title: '請選擇深度串列',
                    input: 'select',
                    inputOptions: opts,
                    inputPlaceholder: hasValue ? formattedCellText ?? v : " ",
                    showCancelButton: true,
                    inputValidator: (value) => {
                        return new Promise((resolve) => {
                            if (!value) {
                                resolve('請選擇一項，否則請取消。');
                            } else {
                                resolve();
                            }
                        })
                    }
                }).then((result) => {
                    if (!result?.value) {
                        // Swal.fire(`選擇為 NULL，失敗!`);
                        return;
                    }
                    cell.setValue(result.value);
                    /*Swal.fire(`你選擇了: ${result.key}<span muted>${result.value}</span>`)*/
                })
            
            }
        },
    ];

    function resetStates() {
        setAddedData([]);
        setEditedData(new Map());

        setUserAddRowCount(0);
        setUserTickedAddingItemsId(new Set());
        setUserTickedExistedItemsGuid(new Set());
    }

    React.useEffect(() => {
        //console.log(scssVars);
        console.log(selectedProject);
        resetStates();
    }, [selectedProject]);

    React.useEffect(() => {
        setData(preData);
        if (tableRef?.current) {
            tableRef.current.setColumns(tabulatorColumns);
            tableRef.current.setData([...addedData, ...preData]);
        }
    }, [preData]);

    React.useEffect(() => {
        if (tableRef?.current) {
            tableRef.current.setColumns(tabulatorColumns);
            tableRef.current.setData([...addedData, ...data]);
        }
    }, [addedData]);

    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
                <Row>
                    <h5 className="mb-4">進階點位</h5>
                </Row>
                {canEdit &&
                    (<Row>
                        <Col xs={12} className="mb-1">
                            {false && (
                                <Button disabled={!tableRef?.current} onClick={e => {
                                    e.preventDefault();
                                    if (!tableRef?.current) {
                                        return;
                                    }
                                    console.log(tableRef?.current.getEditedCells());
                                    let rows = [];

                                    /**@type {Map<string, Object>} */
                                    let rowsMap = new Map();

                                    tableRef?.current.getEditedCells().forEach(cell => {
                                        const row = cell.getRow();
                                        const data = row.getData();

                                        rowsMap.set(data.id, data);

                                        //const data = row.getData();
                                        //data[cell.getColumn().getField()] = cell.getValue();
                                        //row.update(data);
                                    });
                                    // let rowsSet = new Set(rows); //<Tabulator.CellComponent>;
                                    console.log(rowsMap);
                                    console.log("State:");
                                    console.log(editedData);
                                    // rows = null;
                                    // console.log(rowsSet);
                                }}>
                                    View Edited Rows
                                </Button>
                            )}

                            <Row>
                                <Col>
                                    <Button disabled={!tableRef?.current} className="me-2" onClick={e => {
                                        e.preventDefault();
                                        let newData = {
                                            id: null,
                                            projectId: selectedProject.projectId,
                                            pointIdx: null,
                                            nickname: null,
                                            lowerScale: null,
                                            upperScale: null,
                                            statusStaticNote: null,
                                            hidden: false,
                                            longtitude: 0,
                                            latitude: 0,
                                            height: 0,
                                            depthSeriesConfigId: null,
                                            // for local use only
                                            localId: userAddRowCount
                                        };
                                        setAddedData([newData, ...addedData]);
                                        setUserAddRowCount(userAddRowCount + 1);
                                        //setData([newData, ...data]);

                                        //tableRef.current.addData();
                                    }}>
                                        <FontAwesomeIcon icon={faPlus} /> 新增
                                    </Button>
                                    <Button variant="secondary" className="me-2" onClick={async e => {
                                        e.preventDefault();
                                        //
                                        // asyncFunction's result as {}, and declare {}'s 'value' as 'answer'
                                        //
                                        const { value: answer } = await Swal.fire({
                                            title: '批號建立',
                                            showCancelButton: true,
                                            cancelButtonText: "取消",
                                            html:
                                                `${renderToString(<>
                                                    <Form className="text-left">
                                                        <Form.Group as={Row} className="mb-3 mx-0">
                                                            <Form.Label column sm={2}>點號</Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control id="tbBatchPointsAdd_PointIndices" required placeholder="例如 2,4,6,15-30" />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3 mx-0" controlId="formBatchPointsAdd_Nickname">
                                                            <Form.Label column sm={2}>別名</Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control id="tbBatchPointsAdd_Nickname" placeholder="可留空" />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3 mx-0" controlId="formBatchPointsAdd_ScaleLimit">
                                                            <Form.Label column sm={2}>下限</Form.Label>
                                                            <Col sm={3}>
                                                                <Form.Control id="tbBatchPointsAdd_Lowerscale" placeholder="可留空" />
                                                            </Col>
                                                            <Form.Label column sm={2}>上限</Form.Label>
                                                            <Col sm={3}>
                                                                <Form.Control id="tbBatchPointsAdd_Upperscale" placeholder="可留空" />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3 mx-0" controlId="formBatchPointsAdd_StaticStatusNote">
                                                            <Form.Label column sm={2} >鎖死狀態訊息</Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control id="taBatchPointsAdd_StaticStatusNote" placeholder="可留空" as="textarea" rows={3} />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3 mx-0" controlId="formBatchPointsAdd_Geolocation">
                                                            <Form.Label column sm={2} >經度</Form.Label>
                                                            <Col sm={3}>
                                                                <Form.Control id="tbBatchPointsAdd_Latitude" defaultValue="0" />
                                                            </Col>
                                                            <Form.Label column sm={2} >緯度</Form.Label>
                                                            <Col sm={3}>
                                                                <Form.Control id="tbBatchPointsAdd_Longitude" defaultValue="0" />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3 mx-0" controlId="formBatchPointsAdd_GeolocationSecondary">
                                                            <Form.Label column sm={2} >海拔</Form.Label>
                                                            <Col sm={3}>
                                                                <Form.Control id="tbBatchPointsAdd_Height" defaultValue="0" />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3 mx-0" controlId="formBatchPointsAdd_Hidden">
                                                            <Col>
                                                                <Form.Check>
                                                                    <Form.Check.Input id="chbBatchPointsAdd_Hidden" type="checkbox" checked={false} />
                                                                    <Form.Check.Label htmlFor="chbBatchPointsAdd_Hidden">
                                                                        隱藏
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3 mx-0" controlId="formBatchPointsAdd_Dsc">
                                                            <Form.Label column sm={2} >深度測量</Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control id="ddlBatchPointsAdd_DepthSeriesConfig" as="select">
                                                                    <option value="">預設 || 此批非深度測量</option>
                                                                    {
                                                                        depthSeriesConfigs.map(config => {
                                                                            return (<option key={config.id} value={config.id}>{config.name}</option>)
                                                                        })
                                                                    }
                                                                </Form.Control>
                                                            </Col>
                                                        </Form.Group>

                                                    </Form>
                                                </>)}`,
                                            //'<input id="song" class="swal2-input" placeholder="Song">' +
                                            //'<input id="listener" class="swal2-input" placeholder="Listener">',
                                            preConfirm: () => {
                                                let ans = {
                                                    pointIndicesRawString: document.getElementById('tbBatchPointsAdd_PointIndices').value,
                                                    pointIndicesList: [],
                                                    depthSeriesConfigId: document.getElementById('ddlBatchPointsAdd_DepthSeriesConfig').value,
                                                    nickname: document.getElementById('tbBatchPointsAdd_Nickname').value,
                                                    lowerscale: parseFloat(document.getElementById('tbBatchPointsAdd_Lowerscale').value),
                                                    upperscale: parseFloat(document.getElementById('tbBatchPointsAdd_Upperscale').value),
                                                    staticStatusNote: document.getElementById('taBatchPointsAdd_StaticStatusNote').value,
                                                    latitude: parseFloat(document.getElementById('tbBatchPointsAdd_Latitude').value),
                                                    longtitude: parseFloat(document.getElementById('tbBatchPointsAdd_Longitude').value),
                                                    height: parseFloat(document.getElementById('tbBatchPointsAdd_Height').value),
                                                    hidden: document.getElementById('chbBatchPointsAdd_Hidden').checked,
                                                    //listener: document.getElementById('listener').value
                                                };
                                                if (!ans.pointIndicesRawString) {
                                                    Swal.showValidationMessage('請輸入點號，不得留空。');
                                                    return false;
                                                }
                                                if (ans.lowerscale && isNaN(ans.lowerscale)) {
                                                    Swal.showValidationMessage('下限必須為有效數字或留空。');
                                                    return false;
                                                }
                                                if (ans.upperscale && isNaN(ans.upperscale)) {
                                                    Swal.showValidationMessage('上限必須為有效數字或留空。');
                                                    return false;
                                                }
                                                if (ans.latitude && isNaN(ans.latitude)) {
                                                    Swal.showValidationMessage('請輸入有效經度，預設為0。');
                                                    return false;
                                                }
                                                if (ans.longtitude && isNaN(ans.longtitude)) {
                                                    Swal.showValidationMessage('請輸入有效緯度，預設為0。');
                                                    return false;
                                                }
                                                if (ans.height && isNaN(ans.height)) {
                                                    Swal.showValidationMessage('請輸入有效海拔，預設為0。');
                                                    return false;
                                                }
                                                /**@type string[] */
                                                let pointIndicesStrArr = ans.pointIndicesRawString.split(',');
                                                for (let i = 0; i < pointIndicesStrArr.length; i++) {
                                                    let pointIndex = pointIndicesStrArr[i];
                                                    if (pointIndex.includes('-')) {
                                                        let range = pointIndex.split('-');
                                                        let start = parseInt(range[0]);
                                                        let end = parseInt(range[1]);

                                                        if (!isNaN(start) && !isNaN(end)) {
                                                            for (let j = start; j <= end; j++) {
                                                                ans.pointIndicesList.push(j);
                                                            }
                                                        }
                                                    } else {
                                                        let num = parseInt(pointIndex);
                                                        if (!isNaN(num)) {
                                                            ans.pointIndicesList.push(parseInt(pointIndex));
                                                        }
                                                    }
                                                }
                                                ans.pointIndicesList = [...new Set(ans.pointIndicesList)];
                                                return ans;
                                            }
                                        });

                                        if (!answer) {
                                            return;
                                        }

                                        let dscId = answer.depthSeriesConfigId;
                                        let dscLabel = (dscId === null || dscId === '') ? null : depthSeriesConfigs.find(config => config.id === dscId).name ?? dscId;

                                        //let nickname = answer.nickname;
                                        //let lowerscale = answer.lowerscale;
                                        //let upperscale = answer.upperscale;
                                        //let staticStatusNote = answer.staticStatusNote;
                                        //let latitude = answer.latitude;
                                        //let longitude = answer.longitude;
                                        //let height = answer.height;
                                        //let hidden = answer.hidden;                                    

                                        if (isNaN(answer.upperscale)) answer.upperscale = null;
                                        if (isNaN(answer.lowerscale)) answer.lowerscale = null;
                                        if (answer.nickname === "") answer.nickname = null;
                                        if (answer.staticStatusNote === "") answer.staticStatusNote = null;

                                        const { value: dblConfirm } = await Swal.fire({
                                            title: '確定要新增這些點位的進階設定嗎？',
                                            html: `${renderToString(
                                                <>
                                                    <Row className="mx-0">
                                                        <Col>
                                                            <ListGroup variant="flush">
                                                                <ListGroup.Item>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">點位</div>
                                                                        {answer.pointIndicesList.join(",")}
                                                                    </div>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">深度測量</div>
                                                                        {answer.depthSeriesConfigId ? dscLabel : "非深度測量"}
                                                                    </div>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">別名</div>
                                                                        {answer.nickname ?? "--"}
                                                                    </div>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">下限</div>
                                                                        {answer.lowerscale ?? "--"}
                                                                    </div>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">上限</div>
                                                                        {answer.upperscale ?? "--"}
                                                                    </div>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">鎖死狀態訊息</div>
                                                                        {answer.staticStatusNote ?? "--"}
                                                                    </div>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">經度</div>
                                                                        {answer.latitude ?? "--"}
                                                                    </div>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">緯度</div>
                                                                        {answer.longtitude ?? "--"}
                                                                    </div>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">海拔</div>
                                                                        {answer.height ?? "--"}
                                                                    </div>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item>
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">隱藏?</div>
                                                                        {answer.hidden ? "是" : "否"}
                                                                    </div>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        </Col>
                                                    </Row>
                                                </>)}`,
                                            icon: 'question',
                                            showCancelButton: true,
                                            confirmButtonText: '確定',
                                            cancelButtonText: '取消',
                                        });
                                        if (!dblConfirm) {
                                            Swal.fire({
                                                icon: 'info',
                                                title: '已取消',
                                                text: '您已取消新增進階設定。'
                                            });
                                            return;
                                        }
                                        // Agreed to batch add advanced settings

                                        let payload = answer;
                                        let pointIndices = answer.pointIndicesList;
                                        payload.pointIdx = -1;
                                        payload['projectId'] = selectedProject.projectId;
                                        delete payload.pointIndicesList;
                                        delete payload.pointIndicesRawString;
                                        let response = await geoNow.api.instrumentExtends.addWithBatchPointIndices(token, payload, pointIndices, false, err => {
                                            Swal.fire({
                                                icon: 'error',
                                                title: '未預期錯誤',
                                                text: err.message
                                            });
                                        });
                                        if (response.status === 1) {
                                            Swal.fire({
                                                icon: 'success',
                                                title: '成功',
                                                text: '已新增進階設定。'
                                            });
                                        }
                                        else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: '錯誤',
                                                text: response.message
                                            });
                                        }
                                    }}>
                                        批號建立
                                    </Button>
                                </Col>
                                <Col xs='auto'>
                                    <Form.Group as={Row} className="text-right align-items-center">
                                        <Col xs='auto' className="text-center">
                                            <>新增數: {addedData.length}，編輯數: {editedData.size}</>
                                        </Col>
                                        <Button xs='auto' as={Col} variant="secondary" className="me-2" onClick={e => {
                                            e.preventDefault();
                                            resetStates();
                                        }}>重整</Button>
                                        <Button xs='auto' as={Col} variant="danger" className="me-2" onClick={e => {
                                            e.preventDefault();
                                            if (userTickedAddingItemsId.size === 0 && userTickedExistedItemsGuid.size === 0) {
                                                Swal.fire({
                                                    text: "您尚未勾選任何項目。",
                                                    icon: 'info',
                                                    timer: 2000,
                                                    showConfirmButton: false,
                                                });
                                                return;
                                            }
                                            if (userTickedAddingItemsId.size > 0) {
                                                setAddedData((prev) => prev.filter(item => !userTickedAddingItemsId.has(item.localId)));
                                            }
                                            if (userTickedExistedItemsGuid.size > 0) {
                                                //setEditedData((prev) => prev.filter(item => !userTickedExistedItemsGuid.has(item.id)));

                                                async function requestDeletion() {

                                                    let answer = await Swal.fire({
                                                        title: '進階點位刪除',
                                                        text: "確定要刪除 " + userTickedExistedItemsGuid.size + " 個項目嗎?",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#d33',
                                                        //cancelButtonColor: '#3085d6',
                                                        cancelButtonText: '取消',
                                                        confirmButtonText: '確定刪除'
                                                    });

                                                    if (!answer.isConfirmed) {
                                                        await Swal.fire({
                                                            title: '進階點位刪除',
                                                            text: "刪除取消，沒有變更。",
                                                            icon: 'info',
                                                            timer: 2000,
                                                            showConfirmButton: false,
                                                        });
                                                        return;
                                                    }




                                                    let response = await geoNow.api.instrumentExtends.batchDelete(token, Array.from(userTickedExistedItemsGuid), err => {
                                                        Swal.fire({
                                                            title: '進階點位刪除錯誤',
                                                            text: "請求錯誤: " + err ?? "未知。",
                                                            icon: 'error',
                                                        });
                                                        return;
                                                    });
                                                    if (response?.status === 1) {

                                                        //let newUTE = userTickedExistedItemsGuid;
                                                        //userTickedExistedItemsGuid.forEach(guid => {
                                                        //    editedData.delete(guid);
                                                        //    newUTE.delete(guid);
                                                        //});
                                                        //setUserTickedExistedItemsGuid(newUTE);
                                                        resetStates();
                                                        onSavedChanges();

                                                        await Swal.fire({
                                                            title: '進階點位刪除',
                                                            text: "刪除成功。",
                                                            icon: 'success',
                                                            timer: 2000,
                                                            showConfirmButton: false,
                                                        });
                                                    } else {
                                                        await Swal.fire({
                                                            title: '進階點位刪除錯誤',
                                                            text: "後端回傳錯誤: " + response?.message ?? "未知。",
                                                            icon: 'error',
                                                        });
                                                    }
                                                }
                                                requestDeletion();



                                            }
                                        }}>
                                            刪除勾選項目
                                        </Button>
                                        <Button xs='auto' as={Col} disabled={!tableRef?.current || (editedData.size === 0 && addedData.length === 0)} variant="danger"
                                            onClick={e => {
                                                e.preventDefault();
                                                if (!tableRef?.current) {
                                                    return;
                                                }
                                                async function postNewDscItems() {

                                                    if (editedData.size === 0 && addedData.length === 0) {
                                                        await Swal.fire({
                                                            text: "您尚未新增或編輯任何項目。",
                                                            icon: 'info',
                                                            timer: 2000,
                                                            showConfirmButton: false,
                                                        });
                                                        return;
                                                    }

                                                    let answer = await Swal.fire({
                                                        title: '進階點位變更',
                                                        text: "確定要變更嗎?",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#d33',
                                                        //cancelButtonColor: '#3085d6',
                                                        cancelButtonText: '放棄變更',
                                                        confirmButtonText: '確定送出'
                                                    })

                                                    if (!answer.isConfirmed) {
                                                        await Swal.fire({
                                                            title: '進階點位變更',
                                                            text: "變更中止，沒有儲存。",
                                                            icon: 'info',
                                                            timer: 2000,
                                                            showConfirmButton: false,
                                                        });
                                                        return;
                                                    }


                                                    let response = await geoNow.api.instrumentExtends.batchUpdate(token, addedData, Array.from(editedData?.values()), err => {
                                                        Swal.fire({
                                                            title: '進階點位變更錯誤',
                                                            text: JSON.stringify(err),
                                                            icon: 'error',
                                                        })
                                                    });

                                                    if (response?.status === 1) {
                                                        setAddedData([]);
                                                        onSavedChanges();

                                                        await Swal.fire({
                                                            title: '進階點位變更',
                                                            text: "變更成功，已儲存。",
                                                            icon: 'success',
                                                            timer: 2000,
                                                            showConfirmButton: false,
                                                        });
                                                    } else {
                                                        await Swal.fire({
                                                            title: '進階點位變更錯誤',
                                                            text: "後端回傳錯誤: " + response?.message ?? "未知。",
                                                            icon: 'error',
                                                        });
                                                    }
                                                }
                                                postNewDscItems();
                                            }}>
                                            <FontAwesomeIcon icon={faSave} /> 儲存
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>)
                }
                {!canEdit &&
                    <Row>
                        <Col>非專案管理員，唯讀模式。</Col>
                    </Row>
                }
                <Row>
                    <Col>
                        <ReactTabulator
                            className="mt-3"
                            onRef={(r) => {
                                if (!r) {
                                    return;
                                }
                                tableRef.current = r.current;
                            }}
                            options={tabulatorOptions}
                            //data={[...addedData, ...data]}
                            //columns={tabulatorColumns}
                            columns={[]}
                            layout={"fitData"}
                            events={{
                                rowClick: (e, row) => {
                                    let rowData = row.getData();
                                    console.log(rowData);

                                }
                                ,
                                cellEdited: (cell) => {

                                    let rowData = cell.getRow().getData();
                                    if (rowData.id === undefined || rowData.id === null) {
                                        return;
                                    }

                                    //cell.getRow().getElement().style.backgroundColor = "orange";
                                    let map = new Map(editedData);
                                    if (!map.has(rowData.id)) {
                                        cell.getRow().getElement().style.backgroundColor = "orange";
                                    }
                                    map.set(rowData.id, rowData);
                                    setEditedData(map);
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
