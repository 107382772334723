//Projects
export * from './project';
export * from './pageview';

export const doIncrement = () => {
    return {
        type: 'INCREMENT'
    };
};
export const doDecrement = () => {
    return {
        type: 'DECREMENT'
    };
};

export const doSignIn = (user) => {
    //localStorage.setItem("token", user.token);
    //GeoNow.setToken(user.token);
    return {
        type: 'SIGN_IN',
        payload: user
    };
};

export const doUpdateUser = (user) => {
    return {
        type: 'UPDATE_USER',
        payload: user
    };
};

export const doSignOut = () => {
    //localStorage.removeItem("token");
    //GeoNow.setToken(null);
    return {
        type: 'SIGN_OUT'
    };
};

