import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Card } from '@themesberg/react-bootstrap';
import Uploady, { useItemStartListener, useUploady } from "@rpldy/uploady";
import UploadButton from '@rpldy/upload-button';
import geoNow from '../utils/geonow';
import Swal from 'sweetalert2';
import { ReactTabulator } from 'react-tabulator';




const KmlUploader = ({ projectId, onFileSelect }) => {
    useItemStartListener((item) => {
        onFileSelect(item.file);
    });

    return (
        <UploadButton className={"btn btn-secondary"} type={"button"} onClick={e => {
            e.preventDefault();
            return;
        }}>選擇 KML 檔案...</UploadButton>
    );
};

export const ProjectKmlForm = ({ projectId }) => {
    const [autoAdjustLineWidth, setAutoAdjustLineWidth] = useState(false);
    const [lineWidthMultiplier, setLineWidthMultiplier] = useState(0.01);
    const [file, setFile] = useState(null);
    const [filePath, setFilePath] = useState("");
    const [kmlData, setKmlData] = useState([]);

    const handleDownloadKml = (filename, isFilenameUrl) => {
        let url = null;
        if (isFilenameUrl) {
            url = filename;
        } else {
            url = `${process.env.REACT_APP_WEBAPI_HOST}/projectKmlFiles/${projectId}/${filename}`;
        }
        if (url !== null) {
            window.location.href = url;
        }
    };

    const truncateGuid = (text, maxLength = 30, displayLength = 40) => {
        if (text.length > maxLength) {
            const start = text.slice(0, displayLength / 2);
            const end = text.slice(-displayLength / 2);
            return `${start}-...-${end}`;
        }
        return text;
    };

    const truncateFilename = (filename) => {
        const maxLength = 30;
        const displayLength = 40;
        if (filename.length > maxLength) {
            const start = filename.slice(0, displayLength / 2);
            const end = filename.slice(-displayLength / 2);
            return `${start}...${end}`;
        }
        return filename;
    };

    const columns = [
        {
            title: "ID",
            field: "id",
            width: 200,
            formatter: (cell) => {
                const id = cell.getValue();
                const truncated = truncateGuid(id, 15, 20); // GUID specific truncation
                return `<span title="${id}" class="id-cell">${truncated}</span>`;
            },
            cellClick: (e, cell) => {
                const id = cell.getValue();
                Swal.fire({
                    title: '完整 ID',
                    text: id,
                    icon: 'info',
                    confirmButtonText: '確定'
                });
            }
        },
        {
            title: "檔名",
            minWidth: 200,
            field: "filename",
            formatter: (cell) => {
                const filename = cell.getValue();
                const truncated = truncateFilename(filename);
                return `<span title="${filename}" class="filename-cell">${truncated}</span>`;
            },
            cellClick: (e, cell) => {
                const filename = cell.getValue();
                Swal.fire({
                    title: '完整檔名',
                    text: filename,
                    icon: 'info',
                    confirmButtonText: '確定'
                });
            }
        },
        { title: "網址?", field: "isFilenameUrl", formatter: "tickCross" },
        {
            title: "操作", field: "operation", width: 150, formatter: (cell) => {
                return `
                    <button class='btn btn-danger btn-sm delete-btn' type='button'>刪除</button>
                    <button class='btn btn-primary btn-sm download-btn' type='button'>下載</button>
                `;
            }, cellClick: (e, cell) => {
                e.preventDefault();
                const rowData = cell.getRow().getData();
                if (e.target.classList.contains('delete-btn')) {
                    handleDeleteKml(rowData.id);
                } else if (e.target.classList.contains('download-btn')) {
                    handleDownloadKml(rowData.filename, rowData.isFilenameUrl);
                }
            }
        }
    ];

    const fetchKmlData = async () => {
        const token = geoNow.getToken();
        try {
            const response = await geoNow.api.projectKmls.listByProjectId(token, projectId);
            if (response.status === 1) {
                setKmlData(response.message);
            } else {
                console.error("獲取 KML 資料失敗", response.message);
            }
        } catch (error) {
            console.error("獲取 KML 資料失敗", error);
        }
    };

    useEffect(() => {
        fetchKmlData();
    }, [projectId]);

    const handleFileSelect = (file) => {
        setFile(file);
        setFilePath(file.name);
    };

    const handleUpload = async () => {
        if (!file) {
            Swal.fire({
                title: '錯誤',
                text: '請選擇一個 KML 文件',
                icon: 'error',
                confirmButtonText: '確定'
            });
            return;
        }

        const token = geoNow.getToken();
        const options = {
            customFilename: null,
            useUploadFilename: true,
            autoAddItem: true,
            replaceFirstMode: false,
            lineWidthMultiplier: autoAdjustLineWidth ? lineWidthMultiplier : 1
        };

        try {
            const response = await geoNow.api.projectKmls.upload(token, projectId, file, options);
            Swal.fire({
                title: '成功',
                text: '文件上傳成功',
                icon: 'success',
                confirmButtonText: '確定'
            });
            console.log("上傳成功", response);
            fetchKmlData(); // Reload KML data
        } catch (error) {
            Swal.fire({
                title: '錯誤',
                text: '文件上傳失敗',
                icon: 'error',
                confirmButtonText: '確定'
            });
            console.error("上傳失敗", error);
        }
    };

    const handleDeleteKml = async (id) => {
        const token = geoNow.getToken();
        Swal.fire({
            title: '確認刪除',
            text: '你確定要刪除此 KML 文件嗎？',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: '確定',
            cancelButtonText: '取消'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await geoNow.api.projectKmls.delete(token, id);
                    if (response.status == 1) {
                        Swal.fire({
                            title: '成功',
                            text: '文件刪除成功',
                            icon: 'success',
                            confirmButtonText: '確定'
                        });
                        fetchKmlData(); // Reload KML data
                        console.log("刪除成功", response);
                    } else {
                        Swal.fire({
                            title: '失敗',
                            text: response.message,
                            icon: 'error',
                            confirmButtonText: '確定'
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        title: '錯誤',
                        text: '文件刪除失敗。原因: \n' + error,
                        icon: 'error',
                        confirmButtonText: '確定'
                    });
                    console.error("刪除失敗", error);
                }
            }
        });
    };

    const handleDeleteAll = async () => {
        const token = geoNow.getToken();
        Swal.fire({
            title: '確認刪除',
            text: '你確定要刪除本專案所有 KML 文件嗎？',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: '確定',
            cancelButtonText: '取消'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await geoNow.api.projectKmls.deleteAll(token, projectId, false);
                    if (response.status == 1) {
                        Swal.fire({
                            title: '成功',
                            text: '文件刪除成功',
                            icon: 'success',
                            confirmButtonText: '確定'
                        });
                        console.log("刪除成功", response);
                        fetchKmlData(); // Reload KML data
                    } else {
                        Swal.fire({
                            title: '失敗',
                            text: response.message,
                            icon: 'error',
                            confirmButtonText: '確定'
                        });
                    }
                    
                } catch (error) {
                    Swal.fire({
                        title: '錯誤',
                        text: '文件刪除失敗。原因: \n' + error,
                        icon: 'error',
                        confirmButtonText: '確定'
                    });
                    console.error("刪除失敗", error);
                }
            }
        });
    };

    return (
        <Uploady
            
            destination={{
                url: geoNow.api.projectKmls.upload(projectId),
                headers: {
                    'Authorization': 'Bearer ' + geoNow.getToken()
                }
            }}
        >
            <Card border="light" className="bg-white shadow-sm mb-4">
                <Card.Body>
                    <h5 className="mb-4">KML 檔案管理</h5>
                    <Form>
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Group>
                                    <Form.Label>上傳 KML 檔案</Form.Label>
                                    <div>
                                        <KmlUploader projectId={projectId} onFileSelect={handleFileSelect} />
                                        {filePath && <p>已選擇的檔案: {filePath}</p>}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="autoAdjustLineWidth"
                                    label="自動調整線條粗細"
                                    checked={autoAdjustLineWidth}
                                    onChange={(e) => setAutoAdjustLineWidth(e.target.checked)}
                                />
                                {autoAdjustLineWidth && (
                                    <Form.Group>
                                        <Form.Label>線條粗細倍率</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={lineWidthMultiplier}
                                            onChange={(e) => setLineWidthMultiplier(parseFloat(e.target.value))}
                                        />
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Button variant="primary" onClick={handleUpload}>上傳</Button>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Button variant="danger" onClick={handleDeleteAll}>刪除本專案所有 KML</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="table-responsive">
                                    <ReactTabulator
                                        data={kmlData}
                                        columns={columns}
                                        layout={"fitData"}
                                        options={{ height: 250 }}
                                        />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Uploady>
    );
};
