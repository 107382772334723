import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

import { useSelector } from 'react-redux';
import { Form } from '@themesberg/react-bootstrap';

import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { doSetCurrentProjectId, doSignIn, doSetJoinedProjects } from './../actions';

import GeonowSVG from "../assets/img/icons/geonow.svg";

// pages
import DataOverview from "./projects/DataOverview";
import TimeHistory from "./projects/TimeHistory";
import DepthSeries from "./projects/DepthSeries";
import EarthquakeWatcher from "./projects/EarthquakeWatcher";
import StatusTable from "./projects/StatusTable";

import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./user/Signin";
import Signout from "./user/Signout";
import Signup from "./user/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts"; 
import geoNow from '../utils/geonow';

const RouteWithLoader = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
    );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
    //Hollen
    const rdx_counter = useSelector(state => state.counter);
    const rdx_user = useSelector(state => state.user);

    if (!rdx_user.token) {

    }

    

    // const rdx_currentProjectId = useSelector(state => state.currentProjectId);
    const rdx_project = useSelector(state => state.project);

    //
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') === 'false' ? false : true
    }

    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
    }

    const dispatch = useDispatch();

    let qs = queryString.parse(window.location.search);
    let qoHideSidebar = qs && qs.hideSidebar;
    let qoHideNavbar = qs && qs.hideNavbar;
    let qoHideFooter = qs && qs.hideFooter;
    let qoNoPadding = qs && qs.noPadding;
    let qoProjectId = qs && qs.iniProjectId;
    let qoToken = qs && qs.token;

    let mainStyle = {};
    if (qoHideSidebar) {
        mainStyle.marginLeft = '0';
    }
    if (qoNoPadding) {
        mainStyle.padding = '0';
    }

    useEffect(() => {
        if (qoToken) {
            geoNow.api.user.authToken(qoToken).then((res_auth) => {
                if (('token' in res_auth) === true) {
                    dispatch(doSignIn(res_auth))
                    geoNow.setToken(qoToken);


                    geoNow.api.project.getJoinedProjects(res_auth.token, res_auth.id).then((res_uip_list) => {
                        if (res_uip_list && res_uip_list.status && res_uip_list.status === 1) {
                            dispatch(doSetJoinedProjects(res_uip_list.message));
                            let defaultPid = geoNow.stateHelper.project.getDefaultProjectIdFromUipList(res_uip_list.message);
                            dispatch(doSetCurrentProjectId(defaultPid));
                        }
                    });


                } else {
                    debugger;
                }
            });
        }
    }, []);

    useEffect(() => {

        
        if (qoProjectId) {
            dispatch(doSetCurrentProjectId(qoProjectId.toLowerCase()));
        }
    }, [qoProjectId]);
    

    return (
        <Route {...rest} render={props => (
            <>
                <Preloader show={loaded ? false : true} />
                {qoHideSidebar ? null : <Sidebar />}
                { /* if hideSidebar is true, make <main> marginLeft 0 */}

                

                <main className="content" style={mainStyle}>
                    {qoHideNavbar ? null : <Navbar />}
                    {/*Test by Hollen*/}
                    {/*Counter: {rdx_counter}*/}
                    
                    <Component {...props} />
                    { qoHideFooter ? null : <Footer toggleSettings={toggleSettings} showSettings={showSettings} /> }
                </main>
            </>
        )}
        />
    );
};

export default () => (
    <Switch>
        <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />
        <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
        <RouteWithLoader exact path={Routes.Signout.path} component={Signout} />
        <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
        <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
        <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
        <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
        <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
        <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

        {/* pages */}
        <RouteWithSidebar exact path={Routes.DataOverview.path} component={DataOverview} />
        <RouteWithSidebar exact path={Routes.TimeHistory.path} component={TimeHistory} />
        <RouteWithSidebar exact path={Routes.DepthSeries.path} component={DepthSeries} />
        <RouteWithSidebar exact path={Routes.EarthquakeWatcher.path} component={EarthquakeWatcher} />
        <RouteWithSidebar exact path={Routes.StatusTable.path} component={StatusTable} />

        <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
        <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
        <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
        <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
        <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />

        {/* components */}
        <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
        <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
        <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
        <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
        <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
        <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
        <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
        <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
        <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
        <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
        <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
        <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
        <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
        <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
        <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
        <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

        {/* documentation */}
        <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
        <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
        <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
        <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
        <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
        <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
        <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />

        <Redirect to={Routes.NotFound.path} />
    </Switch>
);
