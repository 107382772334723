import counterReducer from './counter';
import userReducer from './user';
import projectReducer from './project';
import pageviewReducer from './pageview';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    counter: counterReducer,
    user: userReducer,
    project: projectReducer,
    pageview: pageviewReducer
});

export default allReducers;