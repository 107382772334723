import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Routes } from "../../routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen, faEyeSlash, faHome, faSearch, faExclamation, faExclamationTriangle, faGreaterThanEqual, faLessThanEqual, faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, ListGroup, InputGroup, Dropdown, Table, ProgressBar, Modal, ModalBody, ModalFooter, Container } from '@themesberg/react-bootstrap';

/*import { TransactionsTable } from "../../components/Tables";*/
import geoNow from '../../utils/geonow';
import cssvar from '../../utils/cssvar';
import { useSelector } from 'react-redux';

import { renderToString } from 'react-dom/server';

import ReactSelect from 'react-select';

import { ReactTabulator, reactFormatter } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.css';

import moment from "moment-timezone";
import 'moment/locale/zh-tw';

import { subscribe } from 'redux-subscriber';
import { useIntervalWhen } from "rooks";
import { Tabulator } from "react-tabulator/lib/types/TabulatorTypes";

import * as Luxon from "luxon";
import MultiSelectListBox from 'react-multiselect-listbox';

import Swal from 'sweetalert2';

import useSound from 'use-sound';
import beepOnceSfx from '../../assets/sounds/beep-once.wav';
import dangerSfx from '../../assets/sounds/danger.wav';


export default (props = {}) => {
    const tableRef = useRef(null);

    const projPointIndicesSelectRef = useRef(null);

    const projCombSelectRef = useRef(null);


    // const MySwal = withReactContent(Swal);
    //MySwal.fire({
    //    icon: 'success',
    //    title: <i>Test</i>,
    //    html: <i>Hello World</i>,
    //})
    const history = useHistory();

    const rdx_project = useSelector(state => state.project);
    const rdx_user = useSelector(state => state.user);
    const token = rdx_user.token;

    const [selectedProject, setSelectedProject] = React.useState(
        geoNow.stateHelper.project.getJoinedProjectItemByProjectId(rdx_project.currentProjectId, rdx_project.joinedProjects)
    );

    const [statusShowingIdOfProjects, setStatusShowingIdOfProjects] = React.useState([]);

    const unsubscribe_currentProjectId = subscribe('project.currentProjectId', state => {
        // do something
        let id = state.project.currentProjectId;
        let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(id, state.project.joinedProjects);
        setSelectedProject(proj);
    });

    //React.useEffect(() => {
    //    let arr = [selectedProject.projectId];
    //    setStatusShowingIdOfProjects(arr);

    //}, []);



    const [autoUpdatePer, setAutoUpdatePer] = React.useState(10000); // miliseconds
    const [countdown, setCountdown] = React.useState(autoUpdatePer);
    const [isAutoUpdate, setIsAutoUpdate] = React.useState(true);
    const [isCountdownPaused, setIsCountdownPaused] = React.useState(false);

    const [gageDefinitionsMap, setGageDefinitionsMap] = React.useState(null);
    const [areaList, setAreaList] = useState(null);

    const [projectCombineOptions, setProjectCombineOptions] = React.useState([]);


    const [tabulatorExtraParams, setTabulatorExtraParams] = React.useState(null);
    const [projPtIdxSelection, setProjPtIdxSelection] = React.useState([]);

    const [isShowAlertModal, setIsShowAlertModal] = React.useState(false);
    const [alertStatusTriggered, setAlertStatusTriggered] = React.useState(false);
    const [errorStatusTriggered, setErrorStatusTriggered] = React.useState(false);
    const [alertStatusMessages, setAlertStatusMessages] = React.useState();

    const [alarmLengthLimit, setAlarmLengthLimit] = React.useState(60); //default seconds
    
    // const [playBeepOnceSfx] = useSound(beepOnceSfx, { interrupt: true, loop: true });
    // const [playDangerSfx] = useSound(dangerSfx, { interrupt: true, onend: ()=> alert("END") });

    // const [projPtIdxSelectionMode, setProjPtIdxSelectionMode] = React.useState(false); //false = exclusion; true = inclusion

    const [tabulatorData, setTabulatorData] = React.useState([]);
    const [tabulatorTempAlert, setTabulatorTempAlert] = React.useState({
        upperAlert: new Map(),
        lowerAlert: new Map(),
        upperEquality: new Map(),
        lowerInequality: new Map(),
    });


    const [projPointIndicesItems, setProjPointIndicesItems] = React.useState();
    const [projPointIndicesSelectedItems, setProjPointIndicesSelectedItems] = React.useState();


    const [didProjectsCombinationApply, setDidProjectsCombinationApply] = React.useState(true);
    const [didPointIndicesSelectionApply, setDidPointIndicesSelectionApply] = React.useState(true);

    //function useInterval(callback, delay) {
    //    const savedCallback = useRef();

    //    // Remember the latest callback.
    //    useEffect(() => {
    //        savedCallback.current = callback;
    //    }, [callback]);

    //    // Set up the interval.
    //    useEffect(() => {
    //        function tick() {
    //            savedCallback.current();
    //        }
    //        if (delay !== null) {
    //            let id = setInterval(tick, delay);
    //            return () => clearInterval(id);
    //        }
    //    }, [delay]);
    //}
    //const getTabulatorAjaxURLGenerator = (localProjPtIdxExclusion) => {
    //    if (localProjPtIdxExclusion !==null)
    //        alert(JSON.stringify(localProjPtIdxExclusion));
    //    return 
    //};

    const [tabulatorOptions, setTabulatorOptions] = React.useState({
        pagination: true,
        paginationMode: 'remote',
        filterMode: 'remote',
        //ajaxURL: geoNow.api.siteData.getUri_getStatusTable([selectedProject.projectId]),
        ajaxConfig: {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        },
        //ajaxParams: () => {
        //    if (projPtIdxExclusion !== null && projPtIdxExclusion.length > 0) {
        //        return {
        //            selection: JSON.stringify(projPtIdxExclusion)
        //        };
        //    }
        //},
        ajaxURLGenerator: (url, config, params) => {
            //url - the url from the ajaxURL property or setData function
            //config - the request config object from the ajaxConfig property
            //params - the params object from the ajaxParams property, this will also include any pagination, filter and sorting properties based on table setup
            let customUrl = url + "?";
            if (params.page) {
                customUrl = customUrl + "page=" + params.page + "&";
            }
            if (params.size) {
                // 當 pagesize 選擇 All 時，Tabulator 參數就不是數字而是 True，後端只接受 -1。
                if ((params.size !== true))
                    customUrl = customUrl + "size=" + params.size + "&";
                else
                    customUrl = customUrl + "size=-1&";
            }
            if (params.filter && params.filter.length > 0) {
                customUrl = customUrl + "filter=" + params.filter + "&";
            }
            //if (localProjPtIdxExclusion !== null) {
            //    alert("NOT NULL OK !");
            //}
            if (params.selection && params.selection.length > 0) {
                customUrl = customUrl + "selection=" + params.selection;
            }
            return customUrl;
        },
        /*ajaxParams: { token: token },*/
        paginationSize: 50,
        paginationSizeSelector: [10, 50, 100, 250, true],
        responsiveLayout: true
    });
    const getGageDefMapByProjId = (pid) => {
        if (gageDefinitionsMap == null) return null;
        let gageDefMap = gageDefinitionsMap[pid];
        return gageDefMap;
    };

    /**
     * 這邊分開宣告，是因為 ReactTabulator 已經初始化後，gageDefinitionsMap 始終為 Null。所以為了手動重新配置 formatter，改每次 gageTypeMap 變動時就產生新的 formatter。
     * @param {Map<string, Map<int, Object>>} localGageDefinitionsMap
     */
    const getTabulatorValueFormatter = (localGageDefinitionsMap) => {
        const formatterFunc = (cell, formatterParams, onRendered) => {
            let value = cell.getValue();
            if (value === null) return "?";

            if (value === -99999) return "X";

            let rowData = cell.getRow().getData();

            // 從儀器名稱的大寫判斷是否為震度
            /**@type string */
            let pointNo = rowData.pointNo;
            let pointNoUppercase = pointNo?.toUpperCase();
            let isEQMag =
                pointNoUppercase.includes("MAGNITUDE") ||
                (pointNoUppercase.includes("地震") && pointNoUppercase.includes("級數"));
            if (isEQMag) {
                // let valNum = Number(value);
                let intP = Math.trunc(value);
                if (value >= 5) {

                    if (value - intP >= 0.5) {
                        return intP + " 強";
                    }
                    return intP + " 弱";
                }
                return intP + " 級";
            }

            let suffixUnit = "";
            let pid = rowData.pid;
            if (localGageDefinitionsMap && localGageDefinitionsMap !== null) {
                let gdMap = localGageDefinitionsMap.get(pid);
                if (gdMap && gdMap !== null) {
                    let gageTypeCode = rowData.gageTypeCode;
                    let gageDef = gdMap.get(gageTypeCode);
                    suffixUnit = " " + gageDef?.unitValue;
                }
            }



            return value.toFixed(2) + suffixUnit;
        };
        return formatterFunc;
    };
    const ExclusionList = (props) => {

        return projPtIdxSelection.map((subset, idx) => {
            const concatIndices = Array.from(subset).join(",") ?? "";
            return <li data-project-id={statusShowingIdOfProjects[idx]}
                data-point-indices={concatIndices}>
                {statusShowingIdOfProjects[idx] + ":" + concatIndices}
            </li>;
        });
    };

    //Function
    const updateSelectPointIndicesOptionsIfEmpty = () => {
        let items = tabulatorData.map(x => {
            let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(x.pid, rdx_project.joinedProjects);
            return {
                label: `.${Number(x.pointIdx).toLocaleString('en-US', { minimumIntegerDigits: 3, useGrouping: false })} #  ${x.pointNo}　@【${proj.projectName}】`, value: { projectId: x.pid, pointIdx: x.pointIdx }
            };
        });
        if (!projPointIndicesItems || projPointIndicesItems.length === 0) {
            setProjPointIndicesItems(items);
        }
    };

    class ExclusionButton extends React.Component {

        constructor(props) {
            super(props);
            this.cell = props.cell;
            this.projectId = props.cell._cell.row.data.pid;
            this.pointIdx = props.cell._cell.row.data.pointIdx;

            //this.projectIds = props.projectIds;

            this.handleHiddenButtonClick = (e) => {
                e.preventDefault();
                if (!projPointIndicesSelectRef.current) {
                    alert("ERROR");
                    return;
                }
                let sel = projPointIndicesSelectRef.current;

                let props = sel.props;
                /**@type {Object[]} */
                let selectedPoints = Array.from(props?.value ?? []);

                /**@type {Object[]} */
                let availablePoints = sel.props.options;
                let cand = availablePoints.filter(x => x.value.pointIdx === this.pointIdx && x.value.projectId === this.projectId)[0];
                if (!cand || cand === null) {
                    Swal.fire({
                        title: "隱藏按鈕故障",
                        text: "請重新整理網頁，遇到不知名錯誤。或是避免使用隱藏按鈕。",
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false,
                    });
                    return;
                }

                if (selectedPoints.some(x => {
                    let res = x.value.projectId === cand.value.projectId && x.value.pointIdx === cand.value.pointIdx;
                    return res;
                })) {
                    Swal.fire({
                        title: "已經在點號排除清單",
                        text: "本訊息關閉後請點選上方按鈕「套用」才會生效。",
                        icon: 'info',
                        timer: 2000,
                        showConfirmButton: false,
                    });
                    return;
                }

                selectedPoints = [...selectedPoints, cand];



                sel.setValue(selectedPoints);
                return;

                sel.setValue(availablePoints);
                alert(JSON.stringify(availablePoints));


                return;
                const row = this.cell._cell.row;
                const rowData = row.data;
                //const cellValue = props.cell._cell.value;
                const pointIdx = rowData.pointIdx;
                const projId = rowData.pid;

                /**@type {string[]} */
                //const projectIdsArr = ;//[selectedProject.projectId, ...statusShowingIdOfProjects];
                let arr = [];
                for (let i = 0; i < this.projectIds.length; i++) {
                    let pid = this.projectIds[i];

                    if (projId === pid) {
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        // projPtIdxExclusion[i].add(pointIdx);
                    }
                }
                // setProjPtIdxExclusion(projPtIdxExclusion);
                alert(JSON.stringify(this.projectIds));
                //alert(JSON.stringify(projId + ":" + projPtIdxExclusion));
                //alert(projId + " : " + rowData.pointIdx);
            }
        }

        componentDidMount() {
            console.log("mounted!")
        }

        render() {
            return (<Button size="xs" variant="info" onClick={this.handleHiddenButtonClick}><FontAwesomeIcon icon={faEyeSlash} /></Button>);
        }
    }

    //const ExclusionButton = (props) => {
    //    const row = props.cell._cell.row;
    //    const rowData = row.data;
    //    //const cellValue = props.cell._cell.value;
    //    const pointIdx = rowData.pointIdx;
    //    const projId = rowData.pid;
    //    return (<Button size="xs" onClick={handleHiddenButton}>隱藏</Button>);
    //};

    const tabulatorColumns = [
        /*{ title: "#", field: "pointIdx", responsive: 1 },*/

        { title: "#", field: "pointIdx", responsive: 1 },
        { title: "儀器名稱", field: "pointNo", responsive: 0 },
        {
            title: "錯誤狀態", field: "errorStatus", responsive: 0,
            /**
             * 
             * @param {Tabulator.CellComponent} cell
             * @param {any} formatterParams
             * @param {any} onRendered
             */
            formatter: (cell, formatterParams, onRendered) => {
                let value = cell.getValue();
                let el = cell.getElement();
                if (value !== null && value !== 0 && value !== '') {
                    el.style.backgroundColor = "yellow";
                }
                if (value == 0) {
                    return "正常";
                } else if (value == 1) {
                    return "異常";
                }
                return value;
            }
        },
        {
            title: "警戒狀態", field: "alertStatus", responsive: 0,
            /**
            * 
            * @param {Tabulator.CellComponent} cell
            * @param {any} formatterParams
            * @param {any} onRendered
            */
            formatter: (cell, formatterParams, onRendered) => {
                let value = cell.getValue();
                let el = cell.getElement();
                if (value !== null && value !== 0 && value !== '') {
                    el.style.backgroundColor = "red";
                }
                if (value == 0) {
                    return "正常";
                } else if (value == 1) {
                    return "異常";
                }
                return value;
            }
        },
        {
            title: "物理量", field: "value", responsive: 0, sorter: "number", formatter: (cell, formatterParams, onRendered) => getTabulatorValueFormatter(gageDefinitionsMap)(cell, formatterParams, onRendered)
        },
        //{ title: "儀器類別", field: "gageTypeCode", responsive: 1 },
        //{ title: "區域", field: "area", responsive: 1 },
        { title: "更新時間", field: "updateTime", responsive: 0 },
        {
            title: "專案", field: "pid", responsive: 1, formatter: (cell, formatterParams, onRendered) => {


                let projGuid = cell.getValue();
                let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(projGuid, rdx_project.joinedProjects);
                let projName = proj?.projectName;
                let dbName = cell.getRow().getData().Db;
                if (projName && dbName === null) return "?";

                return projName ?? dbName;


                //if (selectedProject.databaseName === dbName) {
                //    return selectedProject.projectName;
                //}

                //let matchDbNameProjs = rdx_project.joinedProjects.filter(x => x.databaseName === dbName);
                //if (!matchDbNameProjs || matchDbNameProjs.length === 0) {
                //    console.error("Cannot find project with dbName: " + dbName);
                //    return "[" + dbName + "]";
                //}

                //return matchDbNameProjs[0].projectName;
            }
        },
        {
            title: "隱藏", responsive: 0, formatter: reactFormatter(<ExclusionButton />)
        },
        {
            title: "臨時警戒下限", field: "tempLowerAlert", responsive: 0, editor: "input",
            formatter: (cell, formatterParams, onRendered) => {
                const value = cell.getValue();
                const el = cell.getElement();
                el.style.backgroundColor = cssvar.rootColors.controlLightColor;
                if (value == null || value === "") {
                    return renderToString(<div className="px-1"><FontAwesomeIcon icon={faPen} /> 編輯</div>);
                }
                return value;
            }
        },
        {
            title: "臨時警戒上限", field: "tempUpperAlert", responsive: 0, editor: "input",
            /**
             * 
             * @param {Tabulator.CellComponent} cell
             * @param {any} formatterParams
             * @param {any} onRendered
             */
            formatter: (cell, formatterParams, onRendered) => {
                const value = cell.getValue();
                const el = cell.getElement();
                el.style.backgroundColor = cssvar.rootColors.controlLightColor;
                if (value == null || value === "") {
                    return renderToString(<div className="px-1"><FontAwesomeIcon icon={faPen} /> 編輯</div>);
                }
                return value;

            }
        },
        
        {
            title: "臨時警戒等號", field: "tempAlertEquality", responsive: 0,
            formatter: (cell, formatterParams, onRendered) => {
                const el = cell.getElement();
                el.style.backgroundColor = cssvar.rootColors.controlLightColor;

                const cellValue = cell.getValue() ?? "";
                let lowerEqual = false;
                let upperEqual = false;
                lowerEqual = cellValue.includes(">=");
                if (cellValue == null || cellValue === "") {
                    lowerEqual = true;
                }
                upperEqual = cellValue.includes("<=");
                                    
                let finalString = "下限: ";
                if (lowerEqual) {
                    finalString += renderToString(<FontAwesomeIcon icon={faGreaterThanEqual} />);
                } else {
                    finalString += renderToString(<FontAwesomeIcon icon={faGreaterThan} />);
                }

                if (upperEqual) {
                    finalString += " | 上限: " + renderToString(<FontAwesomeIcon icon={faLessThanEqual} />);
                } else {
                    finalString += " | 上限: " + renderToString(<FontAwesomeIcon icon={faLessThan} />);
                }
                return finalString;
            },
            /**
             * 
             * @param {Tabulator.CellComponent} cell
             * @param {function} onRendered
             * @param {function} success
             * @param {function} cancel
             * @param {any} editorParams
             */
            editor: (cell, onRendered, success, cancel, editorParams) => {

                /**@type string */
                const cellValue = cell.getValue() ?? "";
                const rowData = cell.getData();
                const pointIdx = rowData.pointIdx;
                const projId = rowData.pid;
                const key = `${rowData.pid}#${pointIdx}`;

                let container = document.createElement("form");
                container.className = "form-inline";
                
                let divCheckbox1 = document.createElement("div");
                divCheckbox1.className = "form-check form-check-inline m-0 me-3 p-0";
                
                let checkbox1 = document.createElement("input");
                checkbox1.type = "checkbox";
                checkbox1.name = "tempLowerAlertEquality";
                checkbox1.id = "tempLowerAlertEquality_" + key;
                checkbox1.className = "form-check-input d-none";
                checkbox1.value = ">=";
                checkbox1.checked = cellValue.includes(">=");
                if (cellValue == null || cellValue === "") {
                    checkbox1.checked = true;
                }

                let label1 = document.createElement("label");
                label1.className = "form-check-label";
                label1.htmlFor = checkbox1.id;
                
                function label1_value_update() {
                    if (checkbox1.checked) {
                        label1.innerHTML = renderToString(<FontAwesomeIcon icon={faGreaterThanEqual} />);
                    } else {
                        label1.innerHTML = renderToString(<FontAwesomeIcon icon={faGreaterThan} />);
                    }
                }
                label1_value_update();
                
                divCheckbox1.appendChild(checkbox1);
                divCheckbox1.appendChild(label1);
                container.appendChild(divCheckbox1);

                let divCheckbox2 = document.createElement("div");
                divCheckbox2.className = "form-check form-check-inline m-0 me-3 p-0";
                let checkbox2 = document.createElement("input");
                checkbox2.type = "checkbox";
                checkbox2.id = "tempUpperAlertEquality_" + key;
                checkbox2.className = "form-check-input d-none";
                checkbox2.value = "<=";
                checkbox2.checked = cellValue.includes("<=");

                let label2 = document.createElement("label");
                label2.className = "form-check-label";
                label2.htmlFor = checkbox2.id;

                function label2_value_update() {
                    if (checkbox2.checked) {
                        label2.innerHTML = renderToString(<FontAwesomeIcon icon={faLessThanEqual} />);
                    } else {
                        label2.innerHTML = renderToString(<FontAwesomeIcon icon={faLessThan} />);
                    }
                }
                label2_value_update();

                divCheckbox2.appendChild(checkbox2);
                divCheckbox2.appendChild(label2);
                container.appendChild(divCheckbox2);

                let btnOk = document.createElement("button");
                btnOk.innerText = "確定";
                btnOk.className = "me-1 btn btn-sm btn-success";
                container.appendChild(btnOk);

                checkbox1.addEventListener("change", () => {
                    if (checkbox1.checked) {
                        label1.innerHTML = renderToString(<FontAwesomeIcon icon={faGreaterThanEqual} />);
                    } else {
                        label1.innerHTML = renderToString(<FontAwesomeIcon icon={faGreaterThan} />);
                    }
                });

                checkbox2.addEventListener("change", () => {
                    if (checkbox2.checked) {
                        label2.innerHTML = renderToString(<FontAwesomeIcon icon={faLessThanEqual} />);
                    } else {
                        label2.innerHTML = renderToString(<FontAwesomeIcon icon={faLessThan} />);
                    }
                });

                // check if checkbox1 and checkbox2 are blurred
                //let checkbox1Blur = false;
                //let checkbox2Blur = false;

                //checkbox1.addEventListener("focus", () => {
                //    checkbox1Blur = false;
                //    checkbox2Blur = true;
                //});

                //checkbox2.addEventListener("focus", () => {
                //    checkbox2Blur = false;
                //    checkbox1Blur = true;
                //});

                //checkbox1.addEventListener("blur", () => {
                //    checkbox1Blur = true;
                //    if (checkbox1Blur && checkbox2Blur) {
                //        successFunc();
                //    }
                //});

                

                //checkbox2.addEventListener("blur", () => {
                //    checkbox2Blur = true;
                //    if (checkbox1Blur && checkbox2Blur) {
                //        successFunc();
                //    }
                //});


                

                btnOk.addEventListener("click", (e) => {
                    e.preventDefault();
                    successFunc();
                });
                
                
                //let editor = document.createElement("input");
                ////editor.setAttribute("type", "number");

                ////create and style input
                //editor.style.padding = "3px";
                //editor.style.width = "100%";
                //editor.style.boxSizing = "border-box";

                ////Set value of editor to the current value of the cell
                //editor.value = cell.getValue();

                ////set focus on the select box when the editor is selected (timeout allows for editor to be added to DOM)
                //onRendered(function () {
                //    editor.focus();
                //    editor.style.css = "100%";
                //});

                ////when the value has been set, trigger the cell to update
                //function successFunc() {
                //    success(editor.value);
                //}

                //editor.addEventListener("change", successFunc);
                //editor.addEventListener("blur", successFunc);

                onRendered(function () {
                    container.style.width = "100%";
                    container.style.height = "100%";
                });

                //when the value has been set, trigger the cell to update
                function successFunc() {
                    let v01 = checkbox1.checked ? ">=" : ">";
                    let v02 = checkbox2.checked ? "<=" : "<";
                    let displayResult = `${v01}${v02}`;

                    /**@type Map<string, boolean> */
                    const lowerEqMap = tabulatorTempAlert.lowerInequality;
                    const upperEqMap = tabulatorTempAlert.upperEquality;
                    if (checkbox1.checked) {
                        // 下限和上限相反，是反過來紀錄誰不要等號。所以勾選就代表要等號，也就是預設值。
                        if (lowerEqMap.has(key)) {
                            lowerEqMap.delete(key);
                        }
                    } else {
                        //不要等號，所以要記錄。
                        lowerEqMap.set(key, true);
                        
                    }

                    if (checkbox2.checked) {
                        upperEqMap.set(key, true);
                    } else {
                        upperEqMap.delete(key);
                    }
                    
                    setTabulatorTempAlert({
                        ...tabulatorTempAlert,
                        lowerInequality: lowerEqMap,
                        upperEquality: upperEqMap
                    })

                    success(displayResult);
                }

                //return the editor element
                return container;                
            }
        }
    ];

    React.useEffect(() => {

        if (!selectedProject) return;

        if (!selectedProject.projectStatusTable_Enabled) {
            history.push({
                pathname: Routes.DataOverview.path,
            });
            return;
        }

        setStatusShowingIdOfProjects([selectedProject.projectId]);

        let otherProjects = rdx_project.joinedProjects.filter(x => x.projectStatusTable_Enabled && x.projectId !== selectedProject.projectId);
        let projCombOptions = otherProjects.map(item => {
            return {
                label: item.projectName,
                value: item.projectId,
            };
        });
        setProjectCombineOptions(projCombOptions);

        if (projCombSelectRef.current) {
            const sel = projCombSelectRef.current;
            sel.clearValue();
        }
    }, [selectedProject]);

    React.useEffect(() => {
        if (!statusShowingIdOfProjects || statusShowingIdOfProjects.length === 0) {
            return;
        }

        async function tryDoWork() {
            let resGageDef = await geoNow.api.siteData.getGageDefinition(token, statusShowingIdOfProjects, true);
            let projGagesMap = new Map();
            if (resGageDef?.items) {
                for (let i = 0; i < statusShowingIdOfProjects.length; i++) {
                    let projId = statusShowingIdOfProjects[i];
                    let gageDef = new Map(resGageDef.items[i].map(el => [el.code, el]));
                    projGagesMap.set(projId, gageDef);
                }
                setGageDefinitionsMap(projGagesMap);
                // console.log(projGagesMap);
            }
            // const mapGafeDef = new Map(resGageDef.items[0].map(el => [el.code, el]));

        }
        //async function tryFillingAreaList() {
        //    let resAreaList = await geoNow.api.siteData.getAreas(token, selectedProject.projectId);
        //    setAreaList(resAreaList);
        //}
        tryDoWork();

        let uri = geoNow.api.siteData.getUri_getStatusTable(statusShowingIdOfProjects);
        setTabulatorOptions({ ...tabulatorOptions, ajaxURL: uri });

        //// Prepare an empty jaggedArray for pointIdx exclusion purpose.
        //let projectsBasedJaggedArray = [];

        //for (let i = 0; i < statusShowingIdOfProjects.length; i++) {
        //    projectsBasedJaggedArray.push(new Set());
        //}
        //setProjPtIdxExclusion(jaggedArray);

        //setAvailableProjectsPointIndices(projectsBasedJaggedArray);

        if (projPointIndicesSelectRef.current) {
            const sel = projPointIndicesSelectRef.current;
            sel.clearValue();
        }

        setProjPointIndicesItems([]);
        updateSelectPointIndicesOptionsIfEmpty();

    }, [statusShowingIdOfProjects]);

    React.useEffect(() => {
        if (!tableRef?.current || gageDefinitionsMap === null) return;

        // alert("changed!");
        /**@type Tabulator */
        let table = tableRef?.current;
        let colValue = table.getColumns().filter(x => x.getField() === "value")[0];
        if (colValue) {
            console.log(gageDefinitionsMap);
            let preDef = colValue.getDefinition();
            colValue.updateDefinition({ ...preDef, formatter: getTabulatorValueFormatter(gageDefinitionsMap) });
        }

    }, [gageDefinitionsMap]);

    React.useEffect(() => {

        if (!tabulatorOptions.ajaxURL) {
            console.log("ajaxURL not ready.");
            return;
        }

        if (tableRef?.current) {
            // tableRef.current.ajaxURL = uri;



            //let vCol = table.getColumn("value");
            //let vCells = vCol.getCells();
            //for (let i = 0; i < vCells.length; i++) {
            //    let cell = vCells[i];
            //    let rowData = cell.getRow().getData();
            //    let pid = rowData.pid;
            //    let gageTypeCode = rowData.gageTypeCode;
            //    let gageDef = gageDefinitionsMap[pid][gageTypeCode];
            //    if (gageDef) {
            //        cell.setValue(cell.getValue()+ "$$");
            //    }
            //}


            if (!tableRef.current.initialized) {
                tableRef.current.on("tableBuilt", function () {
                    tableRef.current.setData(tabulatorOptions.ajaxURL, tabulatorExtraParams);
                    ///**@type Tabulator */
                    //let table = tableRef.current;

                });
            } else {
                tableRef.current.setData(tabulatorOptions.ajaxURL, tabulatorExtraParams);
            }
        }
    }, [tabulatorOptions])

    React.useEffect(() => {
        let extraParams = null;
        if (projPtIdxSelection !== null && projPtIdxSelection.length > 0) {
            extraParams = {
                selection: JSON.stringify(projPtIdxSelection)
            };
        }
        setTabulatorExtraParams(extraParams);
        if (tableRef?.current) {
            /**@type Tabulator */
            let tbl = tableRef?.current;
            tbl.setData(tabulatorOptions.ajaxURL, extraParams)
        }

    }, [projPtIdxSelection]);



    React.useEffect(() => {
        if (tabulatorData && tabulatorData !== null) {
            let isGoingToShowAlertModal = false;
            let newMessages = [];
            for (let i = 0; i < tabulatorData.length; i++) {
                let item = tabulatorData[i];
                let value = (item.value).toFixed(2);
                const key = `${item.pid}#${item.pointIdx}`;
                if (item.tempUpperAlert) {
                    if (value > item.tempUpperAlert ||
                        (!tabulatorTempAlert.upperEquality.has(key) && value === item.tempUpperAlert)
                    ) {
                        setAlertStatusTriggered(true);
                        isGoingToShowAlertModal = true;

                        newMessages.push(renderToString(<span style={{color: "red"}}><FontAwesomeIcon icon={faExclamationTriangle} /> </span>) +
                            `${item.updateTime ?? ""} <b>${item.pointNo}</b> 數值 <b>${Number(item.value).toFixed(2)}</b> 超過【臨時警戒上限: ${item.tempUpperAlert}】。`);
                    }
                    
                }
                if (item.tempLowerAlert) {
                    if (value < item.tempLowerAlert || 
                        (tabulatorTempAlert.lowerInequality.has(key) && value === item.tempLowerAlert)
                    ) {
                        setAlertStatusTriggered(true);
                        isGoingToShowAlertModal = true;

                        newMessages.push(renderToString(<span style={{ color: "red" }}><FontAwesomeIcon icon={faExclamationTriangle} /> </span>) +
                            `${item.updateTime ?? ""} <b>${item.pointNo}</b> 數值 <b>${Number(item.value).toFixed(2)}</b> 超過【臨時警戒下限: ${item.tempLowerAlert}】。`);
                    }
                }
                
                if (item.alertStatus && !alertStatusTriggered) {
                    setAlertStatusTriggered(true);
                    isGoingToShowAlertModal = true;

                    newMessages.push(renderToString(<span style={{ color: "red" }}><FontAwesomeIcon icon={faExclamationTriangle} /> </span>) +
                        `${item.updateTime ?? ""} <b>${item.pointNo}</b> 數值 <b>${Number(item.value).toFixed(2)}</b> 發生 GeoAuto 警戒狀態。`);
                }
                if (item.errorStatus && !errorStatusTriggered) {
                    setErrorStatusTriggered(true);
                    isGoingToShowAlertModal = true;

                    newMessages.push(renderToString(<span style={{ color: "yellow" }}><FontAwesomeIcon icon={faExclamationTriangle} /> </span>) +
                        `${item.updateTime ?? ""} <b>${item.pointNo}</b> 數值 <b>${item.value}</b> 發生 GeoAuto 錯誤狀態。`);
                }
            }

            if (isGoingToShowAlertModal) {
                setIsShowAlertModal(true);

                if (alertStatusMessages == null) {
                    setAlertStatusMessages(newMessages);
                } else {
                    setAlertStatusMessages(alertStatusMessages.unshift(newMessages));
                }
            }
        }


        //console.log(projPointIndicesSelectRef);
        //if (projPointIndicesSelectedItems && projPointIndicesSelectedItems !== null &&
        //    projPointIndicesSelectedItems.length > 0) {
        //    items = items.filter(x => {
        //        for (let i = 0; i < projPointIndicesSelectedItems.length; i++) {
        //            let item = projPointIndicesSelectedItems[i];
        //            if (x.value.projectId === item.value.projectId && x.value.pointIdx === item.value.pointIdx) {
        //                return false;
        //            }
        //        }
        //        return true;
        //    });
        //}

        updateSelectPointIndicesOptionsIfEmpty();

    }, [tabulatorData]);

    //React.useEffect(() => {
    //    if (alertStatusTriggered || errorStatusTriggered) {

    //    }
    //}, [alertStatusTriggered, errorStatusTriggered]);

    //function handleMagnitudeFilterOnChange(filterValue) {

    //    tableRef.current.setFilter("magnitude", ">=", filterValue);
    //}

    // Element Function
    //function MagnitudeDropdownOptions() {
    //    let mag = ["顯示所有震度", null, null, null, null, null, null, null]; //7 items
    //    return mag.map((item, idx) => {
    //        return (
    //            <option key={idx} value={idx} selected={idx === 0}>{item ?? "只顯示震度至少" + idx + "的資料"}</option>
    //        );
    //    });
    //}

    //React.useEffect(() => { alert(JSON.stringify(tabulatorData)); }, [tabulatorData]);

    useIntervalWhen(
        () => {
            setCountdown(countdown - 1000);
            if (countdown === 0) {
                console.log("updated!");
                if (tableRef?.current) {
                    tableRef.current.setData(tabulatorOptions.ajaxURL, tabulatorExtraParams);
                }
                setCountdown(autoUpdatePer);
            }
        },
        1000, // run callback every 1 second
        isAutoUpdate && (!isCountdownPaused), // start the timer when it's true
        false // no need to wait for the first interval
    );

    useIntervalWhen(
        () => {
            if (errorStatusTriggered) {
                //playBeepOnceSfx();
                document.getElementById("audioError").pause();
            }
            if (alertStatusTriggered) {
                //playDangerSfx();
                document.getElementById("audioAlert").pause();
            }
        },
        1000 * (alarmLengthLimit ?? 99),
        isShowAlertModal && alarmLengthLimit != null,
        false
    );


    const AlertModal = React.useCallback((props) => {
        return (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {alertStatusTriggered ? "警戒" : ""}{errorStatusTriggered ? "錯誤" : ""}狀態異常
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col>
                                <ListGroup>
                                    {alertStatusMessages?.map((item, idx) => {
                                        return (
                                            <ListGroup.Item key={"alertMsg_" + idx}>
                                                <div dangerouslySetInnerHTML={{ __html: item } }></div>
                                            </ListGroup.Item>
                                        );
                                    })}
                                </ListGroup>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <audio id="audioError" loop controls={errorStatusTriggered}>
                                    <source src={dangerSfx} type="audio/wav"  />
                                    {/*<source src={dangerSfx} type="audio/wav"/>*/}
                                    Your browser does not support the audio element.
                                </audio>
                                <audio id="audioAlert" loop controls={alertStatusTriggered}>
                                    <source src={beepOnceSfx} type="audio/wav" />
                                    {/*<source src={dangerSfx} type="audio/wav"/>*/}
                                    Your browser does not support the audio element.
                                </audio>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide} variant="danger">關閉警報</Button>
                </Modal.Footer>
            </Modal>
        );
    });

    return (
        <>

            <Form>
                <Row>
                    {/*<div style={{transition: .2 + 's'}}>*/}

                    {/*</div>*/}
                    <ProgressBar className="transition1slinear" animated now={countdown / autoUpdatePer * 100} variant="info" />
                </Row>
                <Row>
                    <Col>
                        <Form.Check type="switch" className="form-check">
                            <Form.Check.Input id="chbAutoUpdate" type="checkbox" checked={isAutoUpdate} onChange={() => {
                                setIsAutoUpdate(!isAutoUpdate);
                                if (isAutoUpdate) {
                                    setCountdown(autoUpdatePer);
                                }
                            }} />
                            <Form.Check.Label htmlFor="chbAutoUpdate" value="123">
                                自動更新 {isAutoUpdate ? 'ON: 剩餘 ' + countdown / 1000 + ' 秒。' : 'OFF'}
                            </Form.Check.Label>
                        </Form.Check>
                    </Col>
                    {/*<Col>*/}
                    {/*    <Form.Select onChange={e => handleMagnitudeFilterOnChange(e.target.value)}>*/}
                    {/*        <MagnitudeDropdownOptions />*/}
                    {/*    </Form.Select>*/}
                    {/*</Col>*/}
                </Row>
                <Row className="mt-3">
                    <Form.Group as={Col}>
                        <Form.Label>合併顯示候選<Form.Text muted>　一次呈現多專案數據，快速省時。</Form.Text></Form.Label>
                        <ReactSelect options={projectCombineOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedOptions) => {
                                //setStatusShowingIdOfProjects(selectedOptions.map(x => x.value));
                            }}

                            ref={projCombSelectRef}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs="auto">
                        <Form.Label>　</Form.Label><br />
                        <Button
                            className="me-2"
                            variant="primary"
                            onClick={e => {
                                e.preventDefault();
                                if (projCombSelectRef?.current) {
                                    let props = projCombSelectRef.current.props;

                                    if (props?.value === null) {
                                        setStatusShowingIdOfProjects([selectedProject.projectId]);
                                        return;
                                    }

                                    let arrOthers = props.value.map(x => x.value);
                                    arrOthers.unshift(selectedProject.projectId);
                                    //alert(arrOthers);
                                    setStatusShowingIdOfProjects(arrOthers);
                                }
                            }}>合併</Button>
                        <Button variant="light"
                            className="me-2"
                            onClick={e => {
                                e.preventDefault();
                                if (projCombSelectRef?.current) {
                                    // let props = projCombSelectRef.current.props;
                                    projCombSelectRef.current.clearValue();
                                    setStatusShowingIdOfProjects([selectedProject.projectId]);
                                }
                            }}>清除重整</Button>
                    </Form.Group>
                </Row>
                <Row className="d-none">
                    <ul>
                        <ExclusionList />
                    </ul>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>點號排除<Form.Text muted>　選擇後套用方可生效</Form.Text></Form.Label>
                        {tabulatorData && tabulatorData.length > 0 && (
                            <ReactSelect ref={projPointIndicesSelectRef}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                options={projPointIndicesItems}
                                onChange={opts => {
                                    let finalSelected = [];
                                    setProjPointIndicesSelectedItems(opts);
                                    return;


                                    //if (!projPointIndicesSelectedItems || projPointIndicesSelectedItems.length === 0) {
                                    //    setProjPointIndicesSelectedItems(opts);
                                    //}

                                    let props = projPointIndicesSelectRef.current.props;
                                    let v = Array.from(props?.value ?? []);

                                    for (let i = 0; i < opts.length; i++) {
                                        let isDup = false;

                                        let opt = opts[i];
                                        for (let j = 0; j < v.length; j++) {
                                            let jSelItem = v[j];
                                            if (jSelItem.value.projectId === opt.value.projectId &&
                                                jSelItem.value.pointIdx === opt.value.pointIdx) {
                                                isDup = true;
                                            } else {
                                                isDup = false;
                                            }
                                        }
                                        if (!isDup) {
                                            finalSelected.push(opt);
                                        }
                                        //if (!projPointIndicesSelectedItems.includes(x => x.value.projectId === opt.value.projectId &&
                                        //    x.value.pointIdx === opt.value.pointIdx)) {
                                        //    finalSelected.push(opt);
                                        //}
                                    }
                                    setProjPointIndicesSelectedItems(finalSelected);
                                }}
                                isMulti
                                className="basic-multi-select"
                                classNamePrefix="select" />
                        )}
                    </Form.Group>
                    <Form.Group as={Col} xs="auto">
                        <Form.Label>　</Form.Label><br />
                        {/*<Button*/}
                        {/*    className="me-2"*/}
                        {/*    variant="primary"*/}
                        {/*    onClick={e => {*/}
                        {/*        e.preventDefault();*/}


                        {/*    }}>限定</Button>*/}
                        <Button
                            className="me-2"
                            variant="primary"
                            onClick={e => {
                                e.preventDefault();
                                let selectRef = projPointIndicesSelectRef?.current;
                                if (!selectRef) {
                                    alert("projPointIndicesSelectRef is NULL.");
                                    return;
                                }
                                let props = projPointIndicesSelectRef.current.props;
                                let v = Array.from(props?.value ?? []);

                                /**@type {Object[]} */
                                let availablePoints = selectRef.props.options;

                                if (availablePoints.length === v.length) {
                                    Swal.fire({
                                        title: "不能全選",
                                        text: "全部點號都排除，將沒有任何資料會顯示。",
                                        icon: 'error',
                                        timer: 2000,
                                        showConfirmButton: false,
                                    });
                                    return;
                                }

                                /**@type Map<string, number[]> */
                                let projectToPointIndicesMap = null;
                                if (v || v.length > 0) {
                                    projectToPointIndicesMap = v.reduce((prev, curr) => {
                                        prev[curr.value.projectId] = prev[curr.value.projectId] || [];
                                        prev[curr.value.projectId].push(curr.value.pointIdx);
                                        return prev;
                                    }, Object.create(null));
                                }

                                let jaggedProjPointsArr = [];

                                for (let i = 0; i < statusShowingIdOfProjects.length; i++) {
                                    let pidInOrder = statusShowingIdOfProjects[i];
                                    let arrPointIndices = projectToPointIndicesMap[pidInOrder] ?? [];
                                    jaggedProjPointsArr.push(arrPointIndices);
                                    // alert(pidInOrder + ": " + JSON.stringify(arrPointIndices));
                                }
                                // alert(JSON.stringify(jaggedProjPointsArr));
                                setProjPtIdxSelection(jaggedProjPointsArr);



                                //if (v !== null) {
                                //alert(JSON.stringify(v));
                                //alert(JSON.stringify(projectToPointIndicesMap));
                                //}
                            }}>套用</Button>
                        <Button
                            className="me-2"
                            variant="light"
                            onClick={e => {
                                e.preventDefault();
                                let selectRef = projPointIndicesSelectRef?.current;
                                if (!selectRef) {
                                    alert("projPointIndicesSelectRef is NULL.");
                                    return;
                                }
                                projPointIndicesSelectRef.current.clearValue();
                            }}>清空</Button>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        <ReactTabulator
                            className="mt-3"
                            onRef={(r) => {
                                if (!r) {
                                    return;
                                }
                                tableRef.current = r.current;
                            }}
                            options={tabulatorOptions}

                            columns={tabulatorColumns}
                            layout={"fitData"}
                            events={{
                                //rowClick: (e, row) => {
                                //    let rowData = row.getData();
                                //    console.log(rowData);
                                //},
                                /**
                                 * @param {Object[]} data
                                 */
                                dataLoaded: (data) => {
                                    //tabulatorTempAlert.upperAlert.
                                    for (let i = 0; i < data.length; i++) {
                                        let row = data[i];
                                        const tempAlertKey = row.pid + "#" + row.pointIdx;
                                        let map = new Map();
                                        
                                        if (tabulatorTempAlert.upperAlert.has(tempAlertKey)) {
                                            data[i].tempUpperAlert = tabulatorTempAlert.upperAlert.get(tempAlertKey);
                                        }
                                        if (tabulatorTempAlert.lowerAlert.has(tempAlertKey)) {
                                            data[i].tempLowerAlert = tabulatorTempAlert.lowerAlert.get(tempAlertKey);
                                        }

                                        data[i].tempAlertEquality = "";
                                        if (tabulatorTempAlert.lowerInequality.has(tempAlertKey)) {
                                            data[i].tempAlertEquality += ">";
                                        } else {
                                            data[i].tempAlertEquality += ">="
                                        }

                                        if (tabulatorTempAlert.upperEquality.has(tempAlertKey)) {
                                            data[i].tempAlertEquality += "<=";
                                        } else {
                                            data[i].tempAlertEquality += "<";
                                        }
                                    }

                                    setTabulatorData(Array.from(data));
                                },
                                /**
                                 * 
                                 * @param {Tabulator.CellComponent} cell
                                 */
                                cellEdited: cell => {
                                    //cell - cell component
                                    console.log("EDITED");
                                    const field = cell.getField(); //get field name of the cell
                                    const value = cell.getValue(); //get value of cell
                                    const data = cell.getData();
                                    const pid = data.pid;
                                    const pointIdx = data.pointIdx;

                                    if (field === "tempUpperAlert" || field === "tempLowerAlert") {
                                        const key = pid + "#" + pointIdx;

                                        // check if the value is number
                                        if (isNaN(value)) {
                                            Swal.fire({
                                                title: "輸入錯誤",
                                                text: "請輸入數字",
                                                icon: 'error',
                                                timer: 1000,
                                                showConfirmButton: false,
                                            });
                                            cell.setValue(cell.getOldValue() ?? "");
                                            return;
                                        }

                                        if (field === "tempUpperAlert") {
                                            tabulatorTempAlert.upperAlert.set(key, value);

                                            setTabulatorTempAlert({
                                                ...tabulatorTempAlert,
                                                upperAlert: tabulatorTempAlert.upperAlert
                                            });
                                        } else if (field === "tempLowerAlert") {
                                            tabulatorTempAlert.lowerAlert.set(key, value);

                                            setTabulatorTempAlert({
                                                ...tabulatorTempAlert,
                                                lowerAlert: tabulatorTempAlert.lowerAlert
                                            });
                                        }
                                    }


                                    //alert(JSON.stringify(cell.getRow().getData()));
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button size="sm" variant="light" className="me-2" onClick={e => {
                            e.preventDefault();
                            const newMessages = ["錯誤警報測試", "ErrorAlarm Testing..."];
                            if (alertStatusMessages == null) {
                                setAlertStatusMessages(newMessages);
                            } else {
                                setAlertStatusMessages(alertStatusMessages.unshift(newMessages));
                            }

                            setErrorStatusTriggered(true);
                            setIsShowAlertModal(true);
                        }}>測試錯誤響鈴</Button>
                        <Button size="sm" variant="light" className="me-2" onClick={e => {
                            e.preventDefault();

                            const newMessages = ["警戒警報測試", "AlertAlarm Testing..."];
                            if (alertStatusMessages == null) {
                                setAlertStatusMessages(newMessages);
                            } else {
                                setAlertStatusMessages(alertStatusMessages.unshift(newMessages));
                            }
                            setAlertStatusTriggered(true);
                            setIsShowAlertModal(true);
                        }}>測試警戒響鈴</Button>
                    </Col>
                    <Col>
                        <Row>
                            <Form.Label column="sm" lg={3}>
                                警鈴秒數限制
                            </Form.Label>
                            <Col>
                                <Form.Control id="input_AlarmLengthLimit" size="sm" type="number" placeholder="留空代表不限制" defaultValue={alarmLengthLimit} onChange={
                                    e => {
                                        let v = Number(e.target.value);
                                        if (v <= 0 || v=== NaN) {
                                            v = null;
                                            /**@type HTMLInputElement */
                                            const el = document.getElementById("input_AlarmLengthLimit");
                                            el.value = "";
                                        }
                                        setAlarmLengthLimit(v);
                                        console.log("NEW LL: " + v);
                                    }
                                } />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                
            </Form>
            {isShowAlertModal && (
                <AlertModal show={isShowAlertModal}
                    onHide={() => {
                        setIsShowAlertModal(false);
                        setAlertStatusTriggered(false);
                        setErrorStatusTriggered(false);
                        setIsCountdownPaused(false);
                        
                        setAlertStatusMessages(null);
                    }}
                    onShow={() => {
                        console.log("MODAL ON SHOW!");
                        // setAlarmLengthCountdown(alarmLengthLimit);
                        setIsCountdownPaused(true);
                        if (errorStatusTriggered) {
                            //playBeepOnceSfx();
                            document.getElementById("audioError").play();
                        }
                        if (alertStatusTriggered) {
                            //playDangerSfx();
                            document.getElementById("audioAlert").play();
                        }
                    }} />)
            }

        </>
    );
}