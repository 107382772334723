
import React, { useState, useRef, useCallback, memo } from "react";
import { useLocation } from 'react-router-dom';


import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, Alert } from '@themesberg/react-bootstrap';
import { useEffect } from "react";

import { ReactTabulator, reactFormatter } from 'react-tabulator';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.css';

import moment from "moment-timezone";
import 'moment/locale/zh-tw';

// import scssVars from "../scss/volt/_variables.scss";

import geoNow from '../utils/geonow';
import { Tabulator } from "react-tabulator/lib/types/TabulatorTypes";
import Identicon from 'react-identicons';
import { renderToString } from 'react-dom/server';

import Swal from 'sweetalert2';

export const DscForm = ({ token, selectedProject, preData, onSavedChanges }) => {

    const location = useLocation();
    const tableRef = React.useRef(null);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [data, setData] = useState(preData);
    const [editedData, setEditedData] = useState(new Map());
    const [addedData, setAddedData] = useState([]);

    const [userTickedExistedItemsGuid, setUserTickedExistedItemsGuid] = useState(new Set());
    const [userTickedAddingItemsId, setUserTickedAddingItemsId] = useState(new Set());
    const [userAddRowCount, setUserAddRowCount] = useState(0);

    const canEdit = selectedProject.isProjectAdmin;

    //const [isUserTickAnyItems, setIsUserTickAnyItems] = useState(false);

    const tabulatorOptions = {
        layout: "fitData",
        //movableRows: true,
        movableColumns: true,
        resizableColumns: true,
        pagination: "local",
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40, 50, true],
    };

    const TabulatorIdenticon = (props) => {
        const row = props.cell._cell.row;
        const rowData = row.data;
        const cellValue = props.cell._cell.value;
        const id = rowData.id;
        if (!id || id === "") {
            // row.getElement().style.backgroundColor = "yellow";
            return (<FontAwesomeIcon icon={faPlus} color={'red'} />);
        }
        return (<Identicon string={id} size={20} title={id}></Identicon>);
    };

    const TabulatorUserTickCheckbox = (props) => {
        const row = props.cell._cell.row;
        const rowData = row.data;
        const cellValue = props.cell._cell.value;
        const id = rowData.id;
        const localId = rowData.localId;

        if (!id || id === "") {
            //return (<></>);
            //return (<Button onClick={e => {
            //    e.preventDefault();
            //    alert(rowData.localId);
            //}}>X</Button>);
            //return (<FontAwesomeIcon icon={faPlus} color={'red'}/>);
            return (
                <Form.Check id={"cbAdding_" + localId} defaultChecked={userTickedAddingItemsId.has(localId)} onChange={e => {
                    let v = e.target.checked;
                    if (v) {
                        let set = userTickedAddingItemsId;
                        set.add(localId);
                        setUserTickedAddingItemsId(set);

                        console.log("O: " + localId);
                        console.log(set);
                    } else {
                        let set = userTickedAddingItemsId;
                        set.delete(localId);
                        setUserTickedAddingItemsId(set);

                        console.log("X: " + localId);
                        console.log(set);
                    }
                    // setIsUserTickAnyItems(userTickedExistedItemsGuid.size > 0 || set.size > 0);
                }} />
            );
        }
        return (
            <Form.Check id={"cbExisted_" + id} defaultChecked={userTickedExistedItemsGuid.has(id)} onChange={e => {
                let v = e.target.checked;
                let set = userTickedExistedItemsGuid;
                if (v) {
                    set.add(id);
                    setUserTickedExistedItemsGuid(set);

                    console.log("O: " + localId);
                    console.log(set);
                } else {
                    set.delete(id);
                    setUserTickedExistedItemsGuid(set);

                    console.log("X: " + id);
                    console.log(set);
                }
                // setIsUserTickAnyItems(userTickedAddingItemsId.size > 0 || set.size > 0);
            }} />
        );
    };

    const tabulatorColumns = [

        {
            title: "", visible: true && canEdit, field: "usertick", responsive: 0, sorter: null, hozAlign: "center", tooltip: false, formatter: reactFormatter(<TabulatorUserTickCheckbox />),
            //cellClick: (e, cell) => {
            //let rowData = cell.getData();
            //let id = rowData.id;
            //let localId = rowData.localId;
            //if (!id || id === "") {
            //    let set = userTickedAddingItemsId;
            //    let isChecked = set.has(localId);
            //    if (isChecked) {
            //        set.delete(localId);
            //    } else {
            //        set.add(localId);
            //    }
            //    setUserTickedAddingItemsId(set);
            //    document.getElementById("cbAdding_" + localId).checked = !isChecked;
            //} else {
            //    let set = userTickedExistedItemsGuid;
            //    let isChecked = set.has(id);
            //    if (isChecked) {
            //        set.delete(id);
            //    } else {
            //        set.add(id);
            //    }
            //    setUserTickedExistedItemsGuid(set);
            //    document.getElementById("cbExisted_" + id).checked = !isChecked;
            //}
            //document.getElementById()
            //},
        },
        { title: "#", field: "id", responsive: 0, sorter: "string", hozAlign: "center", tooltip: true, formatter: reactFormatter(<TabulatorIdenticon />) },
        {
            title: "名稱", field: "name", responsive: 0, sorter: "string", editor: true && canEdit, cellEdited: cell => {
                //cell - cell component
                console.log(cell.getRow().getData());
            },
        },
        { title: "說明", field: "description", responsive: 1, tooltip: true, responsive: 0, sorter: "string", editor: true && canEdit},
        { title: "停用", field: "disabled", responsive: 0, sorter: "boolean", hozAlign: "center", formatter: "tickCross", editor: true && canEdit },
        { title: "反向", visible: false, field: "isReversed", responsive: 0, sorter: "boolean", hozAlign: "center", formatter: "tickCross", editor: true && canEdit},
        { title: "累積", visible: false, field: "accumulation", responsive: 0, sorter: "string", hozAlign: "center", formatter: "tickCross", editor: true && canEdit},
        { title: "位移", visible: false, field: "offset", responsive: 0, sorter: "number", editor: true && canEdit, validator: ["numeric"] },
        { title: "顯示上限", visible: true, field: "upperScale", responsive: 0, sorter: "number", editor: true && canEdit, validator: ["numeric"] },
        { title: "顯示下限", visible: true, field: "lowerScale", responsive: 0, sorter: "number", editor: true && canEdit, validator: ["numeric"] },
    ];

    function resetStates() {
        setAddedData([]);
        setEditedData(new Map());

        setUserAddRowCount(0);
        setUserTickedAddingItemsId(new Set());
        setUserTickedExistedItemsGuid(new Set());
    }

    React.useEffect(() => {
        //console.log(scssVars);
        console.log(selectedProject);
        resetStates();
    }, [selectedProject]);

    React.useEffect(() => {
        setData(preData);
        if (tableRef?.current) {
            tableRef.current.setColumns(tabulatorColumns);
            tableRef.current.setData([...addedData, ...preData]);
        }
    }, [preData]);

    React.useEffect(() => {
        if (tableRef?.current) {
            tableRef.current.setColumns(tabulatorColumns);
            tableRef.current.setData([...addedData, ...data]);
        }
    }, [addedData]);

    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
                <Row>
                    <h5 className="mb-4">深度串列</h5>
                </Row>
                {canEdit && (
                    <Row>
                        <Col xs={12} className="mb-1">
                            {false && (
                                <Button disabled={!tableRef?.current} onClick={e => {
                                    e.preventDefault();
                                    if (!tableRef?.current) {
                                        return;
                                    }
                                    console.log(tableRef?.current.getEditedCells());
                                    let rows = [];

                                    /**@type {Map<string, Object>} */
                                    let rowsMap = new Map();

                                    tableRef?.current.getEditedCells().forEach(cell => {
                                        const row = cell.getRow();
                                        const data = row.getData();

                                        rowsMap.set(data.id, data);

                                        //const data = row.getData();
                                        //data[cell.getColumn().getField()] = cell.getValue();
                                        //row.update(data);
                                    });
                                    // let rowsSet = new Set(rows); //<Tabulator.CellComponent>;
                                    console.log(rowsMap);
                                    console.log("State:");
                                    console.log(editedData);
                                    // rows = null;
                                    // console.log(rowsSet);
                                }}>
                                    View Edited Rows
                                </Button>
                            )}

                            <Row>
                                <Col>
                                    <Button disabled={!tableRef?.current} onClick={e => {
                                        e.preventDefault();
                                        let newData = {
                                            id: null,
                                            projectId: selectedProject.projectId,
                                            name: "",
                                            description: "",
                                            disabled: false,
                                            isReversed: false,
                                            accumulation: false,
                                            offset: null,
                                            upperScale: null,
                                            lowerScale: null,
                                            // for local use only
                                            localId: userAddRowCount
                                        };
                                        setAddedData([newData, ...addedData]);
                                        setUserAddRowCount(userAddRowCount + 1);
                                        //setData([newData, ...data]);

                                        //tableRef.current.addData();
                                    }}>
                                        <FontAwesomeIcon icon={faPlus} /> 新增
                                    </Button>
                                </Col>
                                <Col xs='auto'>
                                    <Form.Group as={Row} className="text-right align-items-center">
                                        <Col xs='auto' className="text-center">
                                            <>新增數: {addedData.length}，編輯數: {editedData.size}</>
                                        </Col>
                                        <Button xs='auto' as={Col} variant="secondary" className="me-2" onClick={e => {
                                            e.preventDefault();
                                            resetStates();
                                        }}>重整</Button>
                                        <Button xs='auto' as={Col} variant="danger" className="me-2" onClick={e => {
                                            e.preventDefault();
                                            if (userTickedAddingItemsId.size === 0 && userTickedExistedItemsGuid.size === 0) {
                                                Swal.fire({
                                                    text: "您尚未勾選任何項目。",
                                                    icon: 'info',
                                                    timer: 2000,
                                                    showConfirmButton: false,
                                                });
                                                return;
                                            }
                                            if (userTickedAddingItemsId.size > 0) {
                                                setAddedData((prev) => prev.filter(item => !userTickedAddingItemsId.has(item.localId)));
                                            }
                                            if (userTickedExistedItemsGuid.size > 0) {
                                                //setEditedData((prev) => prev.filter(item => !userTickedExistedItemsGuid.has(item.id)));

                                                async function requestDeletion() {

                                                    let answer = await Swal.fire({
                                                        title: '深度串列刪除',
                                                        text: "確定要刪除 " + userTickedExistedItemsGuid.size + " 個項目嗎?",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#d33',
                                                        //cancelButtonColor: '#3085d6',
                                                        cancelButtonText: '取消',
                                                        confirmButtonText: '確定刪除'
                                                    });

                                                    if (!answer.isConfirmed) {
                                                        await Swal.fire({
                                                            title: '深度串列刪除',
                                                            text: "刪除取消，沒有變更。",
                                                            icon: 'info',
                                                            timer: 2000,
                                                            showConfirmButton: false,
                                                        });
                                                        return;
                                                    }




                                                    let response = await geoNow.api.depthSeriesConfigs.delete(token, Array.from(userTickedExistedItemsGuid));
                                                    if (response?.status === 1) {

                                                        //let newUTE = userTickedExistedItemsGuid;
                                                        //userTickedExistedItemsGuid.forEach(guid => {
                                                        //    editedData.delete(guid);
                                                        //    newUTE.delete(guid);
                                                        //});
                                                        //setUserTickedExistedItemsGuid(newUTE);
                                                        resetStates();
                                                        onSavedChanges();

                                                        await Swal.fire({
                                                            title: '深度串列刪除',
                                                            text: "刪除成功。",
                                                            icon: 'success',
                                                            timer: 2000,
                                                            showConfirmButton: false,
                                                        });
                                                    } else {
                                                        await Swal.fire({
                                                            title: '深度串列刪除錯誤',
                                                            text: "後端回傳錯誤: " + response?.message ?? "未知。",
                                                            icon: 'error',
                                                        });
                                                    }
                                                }
                                                requestDeletion();



                                            }
                                        }}>
                                            刪除勾選項目
                                        </Button>
                                        <Button xs='auto' as={Col} disabled={!tableRef?.current || (editedData.size === 0 && addedData.length === 0)} variant="danger"
                                            onClick={e => {
                                                e.preventDefault();
                                                if (!tableRef?.current) {
                                                    return;
                                                }
                                                async function postNewDscItems() {

                                                    if (editedData.size === 0 && addedData.length === 0) {
                                                        await Swal.fire({
                                                            text: "您尚未新增或編輯任何項目。",
                                                            icon: 'info',
                                                            timer: 2000,
                                                            showConfirmButton: false,
                                                        });
                                                        return;
                                                    }

                                                    let answer = await Swal.fire({
                                                        title: '深度串列變更',
                                                        text: "確定要變更嗎?",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#d33',
                                                        //cancelButtonColor: '#3085d6',
                                                        cancelButtonText: '放棄變更',
                                                        confirmButtonText: '確定送出'
                                                    })

                                                    if (!answer.isConfirmed) {
                                                        await Swal.fire({
                                                            title: '深度串列變更',
                                                            text: "變更中止，沒有儲存。",
                                                            icon: 'info',
                                                            timer: 2000,
                                                            showConfirmButton: false,
                                                        });
                                                        return;
                                                    }

                                                    let response = await geoNow.api.depthSeriesConfigs.batchUpdate(token, addedData, Array.from(editedData?.values()), err => {
                                                        Swal.fire({
                                                            title: '深度串列變更錯誤',
                                                            text: JSON.stringify(err),
                                                            icon: 'error',
                                                        })
                                                    });

                                                    if (response?.status === 1) {
                                                        setAddedData([]);
                                                        onSavedChanges();

                                                        await Swal.fire({
                                                            title: '深度串列變更',
                                                            text: "變更成功，已儲存。",
                                                            icon: 'success',
                                                            timer: 2000,
                                                            showConfirmButton: false,
                                                        });
                                                    } else {
                                                        await Swal.fire({
                                                            title: '深度串列變更錯誤',
                                                            text: "後端回傳錯誤: " + response?.message ?? "未知。",
                                                            icon: 'error',
                                                        });
                                                    }
                                                }
                                                postNewDscItems();
                                            }}>
                                            <FontAwesomeIcon icon={faSave} /> 儲存
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
                {
                    !canEdit && (
                        <Row>
                            <Col>
                                非專案管理員，唯讀模式。
                            </Col>
                        </Row>
                    )
                }
                <Row>
                    <Col>
                        <ReactTabulator
                            className="mt-3"
                            onRef={(r) => {
                                if (!r) {
                                    return;
                                }
                                tableRef.current = r.current;
                            }}
                            options={tabulatorOptions}
                            //data={[...addedData, ...data]}
                            //columns={tabulatorColumns}
                            columns={[]}
                            layout={"fitData"}
                            events={{
                                "rowClick": (e, row) => {
                                    let rowData = row.getData();
                                    console.log(rowData);

                                }
                            }}
                            events={{
                                cellEdited: (cell) => {

                                    let rowData = cell.getRow().getData();
                                    //cell.getRow().getElement().style.backgroundColor = "orange";
                                    let map = new Map(editedData);
                                    if (!map.has(rowData.id)) {
                                        cell.getRow().getElement().style.backgroundColor = "orange";
                                    }
                                    map.set(rowData.id, rowData);
                                    setEditedData(map);
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
