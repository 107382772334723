
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";

import swal from 'sweetalert';

async function registerUser(credentials) {
    return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users/Register", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default () => {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [password2, setPasswordConfirm] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        console.log("Signup SUBMIT! :O");

        if (password !== password2) {
            swal("唉呀", "密碼確認不一致，請重新檢查！", "error", {
                showConfirmButton: true
            });
            return;
        }

        const response = await registerUser({
            username,
            password,
            firstname,
            lastname
        });
        console.log(response);
        if (response.status === 1) {
            swal("讚啦", "註冊成功!", "success", {
                buttons: false,
                timer: 2000
            });
        } else if (response.status === 0) {
            swal("讚啦", response.message.comment, "info", { button: true });
        } else if (response.status === -1) {
            swal("唉呀", response.message, "error", {
                showConfirmButton: true
            });
        }
    }

    return (
        <main>
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container>
                    <p className="text-center">
                        <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
                            <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> 回首頁
                        </Card.Link>
                    </p>
                    <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h3 className="mb-0">建立帳號</h3>
                                </div>
                                <Form className="mt-4" onSubmit={handleSubmit}>
                                    <Form.Group id="lastname" className="mb-4">
                                        <Form.Label>姓氏</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </InputGroup.Text>
                                            <Form.Control required type="text" placeholder="王" onChange={e => setLastName(e.target.value)} />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group id="firstname" className="mb-4">
                                        <Form.Label>名字</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </InputGroup.Text>
                                            <Form.Control required type="text" placeholder="小名" onChange={e => setFirstName(e.target.value)} />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group id="email" className="mb-4">
                                        <Form.Label>信箱</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </InputGroup.Text>
                                            <Form.Control autoFocus required type="email" placeholder="example@gmail.com" onChange={e => setUserName(e.target.value)}  />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group id="password" className="mb-4">
                                        <Form.Label>密碼</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faUnlockAlt} />
                                            </InputGroup.Text>
                                            <Form.Control required type="password" placeholder="密碼" onChange={e => setPassword(e.target.value)} />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group id="confirmPassword" className="mb-4">
                                        <Form.Label>密碼確認</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faUnlockAlt} />
                                            </InputGroup.Text>
                                            <Form.Control required type="password" placeholder="請重複輸入一次。" onChange={e => setPasswordConfirm(e.target.value)} />
                                        </InputGroup>
                                    </Form.Group>
                                    <FormCheck type="checkbox" className="d-flex mb-4">
                                        <FormCheck.Input required id="terms" className="me-2" />
                                        <FormCheck.Label htmlFor="terms">
                                            我接受 <Card.Link>使用規約</Card.Link>
                                        </FormCheck.Label>
                                    </FormCheck>

                                    <Button variant="primary" type="submit" className="w-100">
                                        註冊
                                    </Button>
                                </Form>

                                {/*<div className="mt-3 mb-4 text-center">*/}
                                {/*    <span className="fw-normal">or</span>*/}
                                {/*</div>*/}
                                {/*<div className="d-flex justify-content-center my-4">*/}
                                {/*    <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">*/}
                                {/*        <FontAwesomeIcon icon={faFacebookF} />*/}
                                {/*    </Button>*/}
                                {/*    <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">*/}
                                {/*        <FontAwesomeIcon icon={faTwitter} />*/}
                                {/*    </Button>*/}
                                {/*    <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">*/}
                                {/*        <FontAwesomeIcon icon={faGithub} />*/}
                                {/*    </Button>*/}
                                {/*</div>*/}
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <span className="fw-normal">
                                        已經有帳號了?
                                        <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                                            {` 登入 `}
                                        </Card.Link>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
