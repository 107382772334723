const pageviewReducer = (state = {
    0: {}
}, action) => {
    switch (action.type) {
        case 'PVUSER_SET_STATE':
            let s = state;
            if (!s[action.userId]) {
                s[action.userId] = {};
            }
            s[action.userId] = {
                ...state[action.userId],
                ...action.payload
            };
            return s;
        default:
            return state;
    }
};

export default pageviewReducer;