export const doSetJoinedProjects = (verboseJoinedProjects) => {
    return {
        type: 'SET_JOINED_PROJECTS',
        payload: verboseJoinedProjects
    };
};

export const doSetCurrentProjectId = (currentProjectId) => {
    return {
        type: 'SET_CURRENT_PROJECT_ID',
        payload: currentProjectId
    };
};

export const doUpdateProjectByIdFromJoinedProjects = (projectId, newProjectInfo) => {
    return {
        type: 'UPDATE_PROJECT_BY_ID_FROM_JOINED_PROJECTS',
        payload: {
            projectId: projectId,
            newProjectInfo: newProjectInfo
        }
    };
}


//export const doSetTimeHistoryQueryOptions = (opts) => {
//    return {
//        type: 'SET_TIME_HISTORY_QUERY_OPTIONS',
//        payload: opts
//    };
//};