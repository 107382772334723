import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Routes } from "../../routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from '@themesberg/react-bootstrap';

/*import { TransactionsTable } from "../../components/Tables";*/
import geoNow from '../../utils/geonow';
import { useSelector } from 'react-redux';

import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.css';

import Swal from 'sweetalert2';
import moment from "moment-timezone";
import 'moment/locale/zh-tw';

import * as XLSX from 'xlsx';

import { subscribe } from 'redux-subscriber';

import greenLight from '../../assets/img/status-lights/legacy-webchart/green.jpg';
import yellowLight from '../../assets/img/status-lights/legacy-webchart/yellow.jpg';
import redLight from '../../assets/img/status-lights/legacy-webchart/red.jpg';

import greenLight_XS from '../../assets/img/status-lights/legacy-webchart/green_xs_10.png';
import yellowLight_XS from '../../assets/img/status-lights/legacy-webchart/yellow_xs_10.png';
import redLight_XS from '../../assets/img/status-lights/legacy-webchart/red_xs_10.png';

import greenLight_S from '../../assets/img/status-lights/legacy-webchart/green_s_16.png';
import yellowLight_S from '../../assets/img/status-lights/legacy-webchart/yellow_s_16.png';
import redLight_S from '../../assets/img/status-lights/legacy-webchart/red_s_16.png';

import greenLight_M from '../../assets/img/status-lights/legacy-webchart/green_m_20.png';
import yellowLight_M from '../../assets/img/status-lights/legacy-webchart/yellow_m_20.png';
import redLight_M from '../../assets/img/status-lights/legacy-webchart/red_m_20.png';

import greenLight_L from '../../assets/img/status-lights/legacy-webchart/green_l_24.png';
import yellowLight_L from '../../assets/img/status-lights/legacy-webchart/yellow_l_24.png';
import redLight_L from '../../assets/img/status-lights/legacy-webchart/red_l_24.png';

import greenLight_XL from '../../assets/img/status-lights/legacy-webchart/green_xl_30.png';
import yellowLight_XL from '../../assets/img/status-lights/legacy-webchart/yellow_xl_30.png';
import redLight_XL from '../../assets/img/status-lights/legacy-webchart/red_xl_30.png';


export default (props = {}) => {

    const history = useHistory();

    const rdx_project = useSelector(state => state.project);
    const rdx_user = useSelector(state => state.user);
    const token = rdx_user.token;
    const lastName = rdx_user.lastName
    const firstName = rdx_user.firstName

    const tableRef = useRef(null);
    
    const [selectedProject, setSelectedProject] = useState(
        geoNow.stateHelper.project.getJoinedProjectItemByProjectId(rdx_project.currentProjectId, rdx_project.joinedProjects)
    );

    const unsubscribe_currentProjectId = subscribe('project.currentProjectId', state => {
        // do something
        let id = state.project.currentProjectId;
        let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(id, state.project.joinedProjects);
        setSelectedProject(proj);
    });
    
    let selectedDatabaseName = selectedProject.databaseName;

    const [statusData, setStatusData] = useState(null);
    const [filteredStatusDataEntries, setfilteredStatusDataEntries] = useState(null);

    const [filterOptions, setFilterOptions] = useState(null);

    const [gageDefinitionsMap, setGageDefinitionsMap] = useState(null);
    const [areaList, setAreaList] = useState(null);

    const [loadingProp, setLoadingProp] = useState({
        isLoading: false,
        closeModal: false
    });
    React.useEffect(() => {
        if (loadingProp.isLoading) {
            Swal.fire({
                title: "讀取中...",
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            });
        } else if (loadingProp.closeModal) {
            Swal.close();
            loadingProp.closeModal = false;
        }
    }, [loadingProp]);


    async function tryFillingStatusData() {
        let resStatusData = await geoNow.api.siteData.getLatestDataWithAlert(token, selectedProject.projectId);
        setStatusData(resStatusData);
    }
    async function tryFillingGageDefinition() {
        let resGageDef = await geoNow.api.siteData.getGageDefinition(token, [selectedProject.projectId], true);
        const mapGafeDef = new Map(resGageDef.items[0].map(el => [el.code, el]));
        setGageDefinitionsMap(mapGafeDef);
    }
    async function tryFillingAreaList() {
        let resAreaList = await geoNow.api.siteData.getAreas(token, selectedProject.projectId);
        setAreaList(resAreaList);
    }

    // ### USE EFFECT ## BEGIN ##]
    React.useEffect(() => {

        /**@type Promise[] */
        let promises = [];
        let promiseStatusData = geoNow.api.siteData.getLatestDataWithAlert(token, selectedProject.projectId);
        let promiseGageDefinition = geoNow.api.siteData.getGageDefinition(token, [selectedProject.projectId], true);
        let promiseAreaList = geoNow.api.siteData.getAreas(token, selectedProject.projectId);
        
        promises.push(promiseStatusData);
        promises.push(promiseGageDefinition);
        promises.push(promiseAreaList);

        setLoadingProp({
            isLoading: true,
            closeModal: false
        });
        Promise.all(promises).then(([resStatusData, resGageDef, resAreaList]) => {
            setStatusData(resStatusData);
            const mapGafeDef = new Map(resGageDef.items[0].map(el => [el.code, el]));
            setGageDefinitionsMap(mapGafeDef);
            setAreaList(resAreaList);
            setLoadingProp({
                isLoading: false,
                closeModal: true
            });
        });
        //return;
        let customDataFieldOrderString = selectedProject.projectCustomDataFieldOrder;
        let customDataFieldOrder = customDataFieldOrderString?.split(',');

         //adjust tabulatorColumns according to customDataFieldOrder
         //if customDataFieldOrder is null or empty, it means no need to adjust the order of the fields nor does it need to hide any field.
         //if customDataFieldOrder is not null, it means it needs to adjust the order of the fields and hide some fields, according to the customDataFieldOrder's keys and their order.
         //however, some keys may have different names, so it needs to be mapped to the actual field names, according to geoNow.stateHelper.project.customDataField.keys's members.
         //comments end here. start coding.
        
        if (customDataFieldOrder && customDataFieldOrder.length > 0) {

            // 建立一個映射表用於存放原始的 tabulatorColumns
            let originalTabulatorColumnsMap = {};
            for (let i = 0; i < tabulatorColumns.length; i++) {
                originalTabulatorColumnsMap[tabulatorColumns[i].field] = tabulatorColumns[i];
            }

            // 建立一個新陣列用於存放排序後的 columns
            let newTabulatorColumns = [];

            // 對每一組 key-value pair 做處理
            for (let i = 0; i < customDataFieldOrder.length; i++) {
                let key = customDataFieldOrder[i];


                // 根據 geoNow.stateHelper.project.customDataField.keys 的成員映射到實際欄位名稱
                switch (key) {
                    case geoNow.stateHelper.project.customDataField.keys.alert:
                        key = 'lAlert';
                        break;
                    case geoNow.stateHelper.project.customDataField.keys.alarm1:
                        key = 'lAlarm1';
                        break;
                    case geoNow.stateHelper.project.customDataField.keys.alarm2:
                        key = 'lAlarm2';
                        break;
                    case geoNow.stateHelper.project.customDataField.keys.action:
                        key = 'lAction';
                        break;
                    case geoNow.stateHelper.project.customDataField.keys.pointIdx:
                        key = 'pointIdx';
                        break;
                    case geoNow.stateHelper.project.customDataField.keys.light:
                        key = 'threeLight';
                        break;
                    default:
                        break;
                }

                // 如果此欄位存在於原始的 tabulatorColumns 中
                if (originalTabulatorColumnsMap[key]) {
                    // 設定此欄位的可見性
                    originalTabulatorColumnsMap[key].visible = true;

                    // 將此欄位插入到新陣列中
                    let item = originalTabulatorColumnsMap[key];

                    newTabulatorColumns.push(item);

                    // 從映射表中移除此欄位，以防止重複插入
                    delete originalTabulatorColumnsMap[key];
                }
            }

            // 將剩餘的欄位（不在 customDataFieldOrder 中的）添加到新陣列中，並設置為不可見
            for (let key in originalTabulatorColumnsMap) {
                originalTabulatorColumnsMap[key].visible = false;
                newTabulatorColumns.push(originalTabulatorColumnsMap[key]);
            }
            // re assign the column named 'ThreeLight's formatter with new project's display kind
            let threeLightColumn = newTabulatorColumns.find(item => item.field === 'threeLight');
            if (threeLightColumn) {
                threeLightColumn.formatter = (cell, formatterParams, onRendered) => {
                    return getTabulatorThreeLightFormatter(cell, formatterParams, onRendered, selectedProject);
                };
            }
            
            setTabuatorColumns(newTabulatorColumns);
            //if (tableRef && tableRef.current) {
            //    tableRef.current.setColumns(newTabulatorColumns);
            //}
            
        } else {
            setTabuatorColumns(defaultTabulatorColumns);
            //if (tableRef && tableRef.current) {
            //    tableRef.current.setColumns(defaultTabulatorColumns);
            //}
            
        }
        if (tableRef && tableRef.current) {
            // refresh the table
            tableRef.current.redraw(true);
        }


        //tryFillingStatusData();
        //tryFillingGageDefinition();
        //tryFillingAreaList();
    }, [selectedProject]);

    //Update Data Presents WHENEVER data or filterOption changes    
    React.useEffect(() => {
        if (statusData === null) {
            return; //; however, if data is NULL, do nothing to prevent NULL error from happening.
        }


        if (filterOptions === null) {
            setfilteredStatusDataEntries(statusData.entries);
        } else {
            let fData = statusData.entries;

            if (filterOptions.lightFilter) {
                switch (filterOptions.lightFilter) {
                    default:
                    case '0':
                        // fData = statusData.entries;
                        break;
                    case '1':
                        fData = statusData.entries.filter(item => item.threeLight === 0);
                        break;
                    case '2':
                        fData = statusData.entries.filter(item => item.threeLight === 1);
                        break;
                    case '3':
                        fData = statusData.entries.filter(item => item.threeLight === -1);
                        break;
                    case '4':
                        fData = statusData.entries.filter(item => item.threeLight === 1 || item.threeLight === -1);
                        break;
                }
            }

            if (filterOptions.gageFilter) {
                let g = filterOptions.gageFilter;
                if (g !== null && g !== "null") {
                    fData = fData.filter(item => {
                        let is = item.gageTypeCode == g;
                        // console.log(item);
                        return is;
                    });
                }
            }

            if (filterOptions.areaFilter) {
                let ar = filterOptions.areaFilter;
                if (ar !== null && ar !== "null") {
                    fData = fData.filter(item => {
                        let is = item.area == ar;
                        // console.log(item);
                        return is;
                    });
                }
            }

            setfilteredStatusDataEntries(fData);
        }
    }, [statusData, filterOptions]);
    // ### USE EFFECT ## END ##]

    const tabluatorOptions = {
        pagination: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 50, 100, true],
        responsiveLayout: true
    };

    const colorFilterOptions = [
        { caption: "全部燈號", value: 0, default: true },
        { caption: "綠", value: 1 },
        { caption: "紅", value: 2 },
        { caption: "黃", value: 3 },
        { caption: "紅、黃", value: 4 },
    ];

    function renderAreaDropdownOptions() {
        if (areaList && areaList.items) {
            return areaList.items.map(item => {
                return (<option key={item} value={item}>{item}</option>);
            });
        }
    }

    function renderGageDropdownOptions() {
        if (gageDefinitionsMap) {
            let entries = Array.from(gageDefinitionsMap, ([k, v]) => (v));
            if (entries) {
                return entries.map(item => {
                    return (<option key={item.code} value={item.code}>{item.gageDescription}</option>);
                });
            }
        }
    }

    function handleAreaFilterOnChange(filterValue) {
        setFilterOptions({
            ...filterOptions,
            areaFilter: filterValue
        })
    }

    function handleGageFilterOnChange(filterValue) {
        setFilterOptions({
            ...filterOptions,
            gageFilter: filterValue
        })
    }

    function handleLightFilterOnChange(filterValue) {
        setFilterOptions({
            ...filterOptions,
            lightFilter: filterValue
        });
    }
    const getTabulatorThreeLightFormatter = (cell, formatterParams, onRendered, project) => {
        /**@type {number} */
        let value = cell.getValue();

        /**@type {number} */
        let visualType = project.projectStatusDisplayKind || 0; // Use the provided visual type, if it's not there use 0 as default
        let visualTypeP = visualType;
        if (visualType === 0) {
            console.warn("visualType is 0, which is not supported. Using 1 as default.");
            visualTypeP = 1;
        }
        /**@type Element */
        let ele = cell.getElement();

        // if this field is not visible, then return empty string
        if (ele.style.display === "none") {
            return "";
        }

        if (visualTypeP === 1) {
            if (value === 0) {
                ele.style.cssText = "background-color: lightgreen";
                return "綠";
            } else if (value === -1) {
                ele.style.cssText = "background-color: yellow";
                return "黃";
            } else if (value === 1) {
                ele.style.cssText = "background-color: red";
                return "紅";
            }
        } else if (visualTypeP === 2) {
            if (value === 0) {
                ele.style.cssText = `background-image: url(${greenLight_M}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            } else if (value === -1) {
                ele.style.cssText = `background-image: url(${yellowLight_M}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            } else if (value === 1) {
                ele.style.cssText = `background-image: url(${redLight_M}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            }
        } else if (visualTypeP === 7) {
            if (value === 0) {
                ele.style.cssText = `background-image: url(${greenLight_XS}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            } else if (value === -1) {
                ele.style.cssText = `background-image: url(${yellowLight_XS}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            } else if (value === 1) {
                ele.style.cssText = `background-image: url(${redLight_XS}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            }
        } else if (visualTypeP === 8) {
            if (value === 0) {
                ele.style.cssText = `background-image: url(${greenLight_S}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            } else if (value === -1) {
                ele.style.cssText = `background-image: url(${yellowLight_S}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            } else if (value === 1) {
                ele.style.cssText = `background-image: url(${redLight_S}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            }
        } else if (visualTypeP === 9) {
            if (value === 0) {
                ele.style.cssText = `background-image: url(${greenLight_L}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            } else if (value === -1) {
                ele.style.cssText = `background-image: url(${yellowLight_L}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            } else if (value === 1) {
                ele.style.cssText = `background-image: url(${redLight_L}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            }
        } else if (visualTypeP === 10) {
            if (value === 0) {
                ele.style.cssText = `background-image: url(${greenLight_XL}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            } else if (value === -1) {
                ele.style.cssText = `background-image: url(${yellowLight_XL}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            } else if (value === 1) {
                ele.style.cssText = `background-image: url(${redLight_XL}); background-repeat: no-repeat; background-position: center; background-size: auto;`;
                return "";
            }
        } else if (visualTypeP === 3) {
            if (value === 0) {
                return "😊";
            } else if (value === -1) {
                return "😮";
            } else if (value === 1) {
                return "😨";
            }
        } else if (visualTypeP === 4) {
            if (value === 0) {
                return "😁";
            } else if (value === -1) {
                return "😕";
            } else if (value === 1) {
                return "😡";
            }
        } else if (visualTypeP === 5) {
            if (value === 0) {
                return "✅";
            } else if (value === -1) {
                return "⚠️";
            } else if (value === 1) {
                return "🛑";
            }
        } else if (visualTypeP === 6) {
            if (value === 0) {
                return "🆗";
            } else if (value === -1) {
                return "❓";
            } else if (value === 1) {
                return "‼️";
            }
        }
    };
    const defaultTabulatorColumns = [
        {
            field: "threeLight",
            visible: true,
            width: 30,
            minWidth: 30,
            maxWidth: 30,
            resizable: false,
            download: false,
            /**
             * @param {any} cell The cell component
             * @param {any} formatterParams Parameters set for the column
             * @param {any} onRendered Function to call when the formatter has been rendered
             */
            formatter: (cell, formatterParams, onRendered) => {
                return getTabulatorThreeLightFormatter(cell, formatterParams, onRendered, selectedProject);
            },
            download: true,
            titleDownload: "燈號",
            accessorDownload: (value, data, type, params, column) => {
                if (value === 0) {
                    return "綠";
                } else if (value === -1) {
                    return "黃";
                } else if (value === 1) {
                    return "紅";
                }
            }
        },
        { title: "#", field: "pointIdx", responsive: 1 },
        { title: "儀器名稱", field: "pointNo", responsive: 0 },
        {
            title: "物理量", field: "value", responsive: 0,
            formatter: (cell, formatterParams, onRendered) => {
                let unitCaption = "";

                /**@type {number} */
                let gageTypeCode = cell.getData().gageTypeCode;

                if (gageDefinitionsMap) {
                    let gageDef = gageDefinitionsMap.get(gageTypeCode);
                    if (gageDef) {
                        unitCaption = gageDef.unitValue;
                    }
                }

                const nullDisplay = "?";
                let valueString = cell.getValue() ?? nullDisplay;
                if (valueString !== nullDisplay) {
                    valueString = Number(valueString).toFixed(2);
                }

                return valueString + " " + unitCaption;
            },
            accessorDownload: (value, data, type, params, column) => {
                let unitCaption = "";

                /**@type {number} */
                let gageTypeCode = data.gageTypeCode;

                if (gageDefinitionsMap) {
                    let gageDef = gageDefinitionsMap.get(gageTypeCode);
                    if (gageDef) {
                        unitCaption = gageDef.unitValue;
                    }
                }

                const nullDisplay = "?";
                let valueString = value ?? nullDisplay;
                if (valueString !== nullDisplay) {
                    valueString = Number(valueString).toFixed(2);
                }

                return valueString + " " + unitCaption;
            }
        },
        {
            title: "初值", field: "iniR", responsive: 1, formatter: (cell, formatterParams, onRendered) => {
                const nullDisplay = "";
                let value = cell.getValue();
                if (value === null) {
                    return nullDisplay;
                }
                let numberValue = Number(value);
                if (isNaN(numberValue)) {
                    return value;
                }
                return numberValue.toFixed(2);
            }
        },
        //{
        //    title: "單位",
        //    field: "gageTypeCode",
        //    formatter: (cell, formatterParams, onRendered) => {
        //        /**@type {number} */
        //        let value = cell.getValue();
        //        let gageDef = gageDefinition.get(value);
        //        if (gageDef) {
        //            return gageDef.unitValue;
        //        }
        //    }
        //},
        {
            title: "警戒值", field: "lAlert", width: 100, responsive: 999,
            formatter: (cell, formatterParams, onRendered) => {
                /**@type {number} */
                let rowData = cell.getData();
                let w0 = rowData.lAlert;
                let w1 = rowData.uAlert;
                if (!w0 && !w1) {
                    return "";
                }
                if (w0 === w1) {
                    return w0;
                }
                return (w0 ?? "_") + "\\" + (w1 ?? "_")
            }
        },
        {
            title: "警示1", field: "lAlarm1", width: 100, responsive: 999,
            formatter: (cell, formatterParams, onRendered) => {
                /**@type {number} */
                let rowData = cell.getData();
                let w0 = rowData.lAlarm1;
                let w1 = rowData.uAlarm1;
                if (!w0 && !w1) {
                    return "";
                }
                if (w0 === w1) {
                    return w0;
                }
                return (w0 ?? "_") + "\\" + (w1 ?? "_")
            }
        },
        {
            title: "警示2", field: "lAlarm2", width: 100, responsive: 999,
            formatter: (cell, formatterParams, onRendered) => {
                /**@type {number} */
                let rowData = cell.getData();
                let w0 = rowData.lAlarm2;
                let w1 = rowData.uAlarm2;
                if (!w0 && !w1) {
                    return "";
                }
                if (w0 === w1) {
                    return w0;
                }
                return (w0 ?? "_") + "\\" + (w1 ?? "_")
            }
        },
        {
            title: "行動值", field: "lAction", width: 100, responsive: 999,
            formatter: (cell, formatterParams, onRendered) => {
                /**@type {number} */
                let rowData = cell.getData();
                let w0 = rowData.lAction;
                let w1 = rowData.uAction;
                if (!w0 && !w1) {
                    return "";
                }
                if (w0 === w1) {
                    return w0;
                }
                return (w0 ?? "_") + "\\" + (w1 ?? "_")
            }
        },
        {
            title: "備註", responsive: 1000,
            field: "note"
        }
    ];
    const [tabulatorColumns, setTabuatorColumns ] = React.useState(defaultTabulatorColumns);

    // Run once    
    React.useEffect(() => {
        window.XLSX = XLSX;
    }, []);

    //geoNow.api.project.getStatusTable(token, selectedDatabaseName).then(statusDataResponse => {
    //    console.log(statusDataResponse);
    //    console.log('done');

    //    return (
    //        <>
    //            <div className="table-settings mb-4">
    //                HELLOW
    //            </div>
    //        </>
    //    );
    //});

    //let statusDataResponse = geoNow.api.project.getStatusTable(token, selectedDatabaseName);
    //console.log(statusDataResponse);
    // console.log("currentProjectId: " + rdx_project.currentProjectId);
    // console.log("joinedProjects: " + rdx_project.joinedProjects);
    // console.log(selectedProject.databaseName);

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item>GeoNow</Breadcrumb.Item>
                        <Breadcrumb.Item active>資料一覽</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>資料一覽</h4>
                    <p className="mb-0">{statusData === null ? "Loading..." : statusData.meaTime}</p>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <ButtonGroup>
                        <Button disabled variant="outline-primary" size="sm">附件</Button>
                        <Button variant="outline-primary" size="sm"
                            disabled={!filteredStatusDataEntries || filteredStatusDataEntries.length === 0}
                            onClick={e => {
                                e.preventDefault();
                                if (!(tableRef?.current)) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `列印失敗。<br/>找不到表格控制項。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                tableRef.current.print();
                            }}
                        >列印</Button>
                        <Button
                            disabled={!filteredStatusDataEntries || filteredStatusDataEntries.length === 0}
                            onClick={e => {
                                e.preventDefault();
                                if (!(tableRef?.current)) {
                                    Swal.fire({
                                        title: "錯誤",
                                        html: `下載失敗。<br/>找不到表格控制項。`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                    });
                                    return;
                                }
                                let csvFilename = "[" + (lastName ?? "") + (firstName ?? "") + "|" + (selectedProject?.name ?? (selectedProject?.databaseName ?? "")) + "] dataOverview@" + statusData.meaTime + ".xlsx";
                                tableRef.current.download("xlsx", csvFilename, { sheetName: "DataOverview" });
                            }}
                            variant="outline-primary" size="sm">下載</Button>
                    </ButtonGroup>
                </div>
            </div>
            <Form>
                <Row>
                    <Col>
                        <Form.Select disabled={!filteredStatusDataEntries} onChange={e => handleGageFilterOnChange(e.target.value)}>
                            <option key="null" value="null" selected="true">全部儀器</option>
                            {renderGageDropdownOptions()}
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Select disabled={!filteredStatusDataEntries} onChange={e => handleAreaFilterOnChange(e.target.value)}>
                            <option key="null" value="null" selected="true">全部區域</option>
                            {renderAreaDropdownOptions()}
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Select disabled={!filteredStatusDataEntries} onChange={e => handleLightFilterOnChange(e.target.value)}>
                            {colorFilterOptions.map(item => {
                                let setSelected = item.default && item.default === true;
                                return (<option key={item.value} value={item.value} selected={setSelected}>{item.caption}</option>);
                            })}
                        </Form.Select>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        
                        <ReactTabulator
                            onRef={(r) => {
                                if (!r) {
                                    return;
                                }
                                tableRef.current = r.current;
                            }}
                            options={tabluatorOptions}
                            data={filteredStatusDataEntries}
                            columns={tabulatorColumns}
                            layout={"fitData"}
                            events={{
                                "rowClick": (e, row) => {
                                    let rowData = row.getData();
                                    console.log(rowData);
                                    let pointIdx = Number(rowData.pointIdx);
                                    
                                    let tempDtRight = moment(statusData.meaTime).add(30, "minutes");
                                    let tempDtLeft = moment(statusData.meaTime).add(-1, "days");
                                    // let meaTimeStr = meaTime.format("YYYY-MM-DD HH:mm:ss");
                                    
                                    console.log(tempDtLeft.format("YYYY-MM-DD HH:mm:ss"));
                                    console.log(tempDtRight.format("YYYY-MM-DD HH:mm:ss"));

                                    history.push({
                                        pathname: Routes.TimeHistory.path,
                                        state: {

                                            

                                            dtLeft: tempDtLeft,
                                            dtRight: tempDtRight,
                                            pointIndices: Array.of(pointIdx)
                                        }
                                    });
                                }
                            }}                    
                        />
                    </Col>
                </Row>

            </Form>
            
            {/*
            <Table responsive className="table-centered rounded">
                <thead>
                    <tr>
                        <th>燈號</th>
                        <th>儀器名稱</th>
                        <th>物理量</th>
                    </tr>
                </thead>
                <tbody>

                </tbody>
            </Table>
            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4}>
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control type="text" placeholder="Search" />
                        </InputGroup>
                    </Col>
                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm icon-gray">
                                    <FontAwesomeIcon icon={faCog} />
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                <Dropdown.Item className="d-flex fw-bold">
                                    10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>
            */}


        </>
    );
};
