
import React, { useState } from "react";

import { Routes } from "../../routes";
import { Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { doSignIn, doSignOut } from './../../actions';
import geoNow from '../../utils/geonow'
/*import { UserContext } from './contexts/UserContext'*/


//async function loginUser(credentials) {
//    return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users/Authenticate", {
//        method: 'POST',
//        headers: {
//            'Content-Type': 'application/json'
//        },
//        body: JSON.stringify(credentials)
//    })
//        .then(data => data.json())
//}

export default () => {

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const signout = () => dispatch(doSignOut());
    
    geoNow.setToken(null);
    signout();

    return (
        <>
            <b>正在登出... Logging you off...</b>
            <Redirect to={Routes.Signin.path} />
        </>

    );
};
