//import { act } from "@testing-library/react";

//import GeoNow from "./../api/geonow";

const userReducer = (state = {
    id: null,
    token: null,
    username: null,
    firstName: null,
    lastName: null,
    isSuperUser: false,
    joinedUtcTime: null,
    passwordLastChangedUtcTime: null,
    avatarUrl: null,
    avatarUrlSmall: null
}, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return {
                id: action.payload.id,
                token: action.payload.token,
                username: action.payload.username,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                isSuperUser: action.payload.isSuperUser,
                joinedUtcTime: action.payload.joinedUtcTime,
                passwordLastChangedUtcTime: action.payload.passwordLastChangedUtcTime,
                avatarUrl: action.payload.avatarUrl,
                avatarUrlSmall: action.payload.avatarUrlSmall
            };
        case 'UPDATE_USER':
            return {
                ...state,
                id: action.payload.id,
                username: action.payload.username,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                passwordLastChangedUtcTime: action.payload.passwordLastChangedUtcTime,
                //avatarUrl: action.payload.avatarUrl,
                //avatarUrlSmall: action.payload.avatarUrlSmall
            };
        case 'SIGN_OUT':
            return {
                id: null,
                token: null,
                username: null,
                firstName: null,
                lastName: null,
                isSuperUser: false,
                joinedUtcTime: null,
                passwordLastChangedUtcTime: null,
                avatarUrl: null,
                avatarUrlSmall: null
            };
        default:
            //let previousToken = GeoNow.getToken();
            //if (previousToken) {
            //    let me = await GeoNow.getUserMe(previousToken);
                
            //    if (me && me.status === 1) {
            //        return {
            //            id: me.message.id,
            //            token: previousToken,
            //            username: me.message.username,
            //            firstName: me.message.firstName,
            //            lastName: me.message.lastName
            //        }
            //    }
            //}
            return state;
    }
};

export default userReducer;