//async function loginUser(credentials) {
//    return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users/Authenticate", {
//        method: 'POST',
//        headers: {
//            'Content-Type': 'application/json'
//        },
//        body: JSON.stringify(credentials)
//    })
//        .then(data => data.json())
//}

const cssvar = {
    rootColors: {
        primary: getComputedStyle(document.body).getPropertyValue('--primary'),
        secondary: getComputedStyle(document.body).getPropertyValue('--secondary'),
        tertiary: getComputedStyle(document.body).getPropertyValue('--tertiary'),
        quaternary: getComputedStyle(document.body).getPropertyValue('--quaternary'),
        controlLightColor: "#edf0f4"
    },
    extendColors: {
        
    }
    ,
    adjustHexColorOpacity: (hexC, opacity) => {
        let hex = hexC.trim();
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        let r = parseInt(result[1], 16);
        let g = parseInt(result[2], 16);
        let b = parseInt(result[3], 16);
        let final = "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
        return final;
    }
};

export default cssvar;